import React from 'react';
import PropTypes from 'prop-types';
import { openModal } from '../../slices/modals';
import { useDispatch } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const TESTS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];

const ExamTests = ({ id, open, handleClose, data }) => {
  const dispatch = useDispatch();
  return (
    <Dialog
      open={open}
      onClose={() => handleClose(id)}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="ta-modal add-video-modal"
    >
      <DialogTitle id="alert-dialog-title">
        <span className="label">{`Devoir ${data?.name}`}</span>
        <span className="close-btn" onClick={() => handleClose(id)}>
          Fermer
        </span>
      </DialogTitle>
      <DialogContent className="ta-modal-content-scroll tests-container">
        {data?.users &&
          data?.users.length > 0 &&
          data?.users.map((user, index) => (
            <Button
              className={`ta-btn btn-rounded btn-lg text-ellipsis ${
                data.homeworkCorrections.filter((homework) => homework.file.user.id === user[0].id)
                  .length > 0
                  ? 'success'
                  : 'dark'
              }`}
              onClick={() => {
                handleClose(id);
                dispatch(
                  openModal('exam-test-modal', {
                    name: data.name,
                    users: data.users,
                    homeworks: data.homeworks,
                    homeworkCorrections: data.homeworkCorrections,
                    modal_id: id,
                    modal_name: data?.name,
                    user: {
                      ...user[0],
                      homeworks:
                        data?.homeworks?.length > 0
                          ? data?.homeworks?.filter(
                              (homework) => homework.file.user.id === user[0].id
                            )
                          : [],
                      corrections:
                        data?.homeworkCorrections?.length > 0
                          ? data?.homeworkCorrections?.filter(
                              (correction) => correction.file.user.id === user[0].id
                            )
                          : [],
                    },
                    examId: data?.examId,
                  })
                );
              }}
              key={index}
            >{`${user[0].name} ${user[0].last_name}`}</Button>
          ))}
      </DialogContent>
    </Dialog>
  );
};

ExamTests.propTypes = {};

export default ExamTests;
