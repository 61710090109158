import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { addNewChapterSection, editChapterSection } from '../../slices/sectionChapter';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import {
  TextField,
  Box,
  FormHelperText,
  Typography,
  Switch,
  Select,
  MenuItem,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import constants from "../../utilities/constants";

const AddChapterSectionModal = ({ id, open, handleClose, data, ...rest }) => {
  const isMountedRef = useIsMountedRef();
  const dispatch = useDispatch();
  const { chapters } = useSelector((state) => state.chapters);
  console.log('here chapters section', chapters)
  const chapterTypes = constants.CHAPTER_TYPES;
  return (
    <Dialog
      open={open}
      onClose={() => handleClose(id)}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="ta-modal add-video-modal"
    >
      <DialogTitle id="alert-dialog-title">
        <span className="label">
          {!data?.edit ? 'Ajouter une nouvelle section' : 'Modifier la section'}
        </span>
        <span className="close-btn" onClick={() => handleClose(id)}>
          Fermer
        </span>
      </DialogTitle>
      <DialogContent className="ta-modal-content-scroll">
        <Formik
          initialValues={{
            name: !data?.edit ? '' : data?.chapter?.name,
            position: !data?.edit ? 0 : data?.chapter?.position,
            type: !data?.edit ? '': data?.chapter?.type,
            subject: data?.subject_id,
            submit: null,
            isDraft: !data?.edit ? false : data?.chapter?.is_draft,
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().max(255).required('Title is required'),
            type: Yup.string().max(255).required('Type is required'),
            isDraft: Yup.bool(),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            console.log('data', data)
            try {
              values.isDraft = values?.isDraft === true ? 1 : 0;
              if (!data?.edit) {
                dispatch(
                    addNewChapterSection({
                      subjectSlug: data?.subject_slug,
                      values: { ...values, submit: undefined },
                    })
                );
              } else {
                dispatch(
                    editChapterSection({
                      section_id: data?.section_id,
                      values: {...values, submit: undefined,},
                      subjectSlug: data?.subject_slug,
                    })
                );
              }

              if (isMountedRef.current) {
                setStatus({ success: true });
                setSubmitting(false);
              }
            } catch (err) {
              console.error(err);
              if (isMountedRef.current) {
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            resetForm,
            setFieldValue,
            setTouched,
          }) => (
            <form noValidate onSubmit={handleSubmit} className="ta-form" {...rest}>
              <Typography variant="body2" color="textSecondary" className="label">
                Titre*
              </Typography>
              <TextField
                className="input"
                error={Boolean(touched.name && errors.name)}
                fullWidth
                autoFocus
                helperText={touched.name && errors.name}
                margin="normal"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.name}
                variant="outlined"
                size="small"
              />
              <Typography variant="body2" color="textSecondary">
                Type*
              </Typography>
              <Select
                  className={touched.type && errors.type ? 'input size-sm' : 'input size-sm mb'}
                  error={Boolean(touched.type && errors.type)}
                  fullWidth
                  margin="normal"
                  name="type"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.type}
                  variant="outlined"
              >
                {
                  chapterTypes.map((item, index) => (
                        <MenuItem key={index} value={item.value}>
                          {item.name}
                        </MenuItem>
                    ))}
              </Select>
              {touched.type && errors.type && (
                  <span className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense">
                  {errors.type}
                </span>
              )}
              <Typography variant="body2" color="textSecondary">
                Brouillon
              </Typography>
              <Switch
                  checked={values.isDraft}
                  color="primary"
                  edge="start"
                  name="isDraft"
                  onChange={(e, value)=>{
                    setFieldValue("isDraft",value)
                  }}
                  value={values.isDraft}
              />
              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
              <Box mt={2} className="ta-btns-group">
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  className="ta-btn primary btn-rounded btn-lg"
                >
                  {!data?.edit ? 'Ajouter' : 'Modifier'}
                </Button>
                <Button
                  disabled={isSubmitting}
                  type="reset"
                  className="ta-btn btn-rounded btn-lg"
                  onClick={resetForm}
                >
                  Annuler
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddChapterSectionModal;
