import * as dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
dayjs.extend(customParseFormat);
dayjs.extend(duration);

const getLessonDuration = (duration) => {
  let DMs = 0;
  if (duration) {
    let h = dayjs(duration, 'HH:mm:ss').hour() * 60 * 60;
    DMs =
      (h + dayjs(duration, 'HH:mm:ss').minute() * 60 + dayjs(duration, 'HH:mm:ss').second()) * 1000;

/*    return h > 0
      ? `${Math.trunc(dayjs.duration(DMs).asHours())}:${dayjs
          .duration(DMs)
          .minutes()}:${dayjs.duration(DMs).seconds()}`
      : `${dayjs.duration(DMs).minutes()}:${dayjs.duration(DMs).seconds()}`;*/
    return h > 0
        ? `${dayjs.duration(DMs).format('HH')}:${dayjs
            .duration(DMs)
            .format('mm')}:${dayjs.duration(DMs).format('ss')}`
        : `${dayjs.duration(DMs).format('mm')}:${dayjs.duration(DMs).format('ss')}`;
  }
};
export default getLessonDuration;
