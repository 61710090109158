import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../utilities/axios';
import { enqueueSnackbar } from './notifier';
import Slide from '@material-ui/core/Slide';

const initialState = {
  codes: [],
  status: 'idle',
  error: null,
  benefitsHistory:[]
};

export const generateCode = createAsyncThunk('codeCoupon/generateCode', async (thunkAPI) => {
  let data;
  try {
    const response = await axios.post(`/instructor/generate-discount-code`);
    data = await response.data;
    if (response.status === 200) {
      // thunkAPI.dispatch(
      //   enqueueSnackbar({
      //     message: data.message,
      //     options: {
      //       key: new Date().getTime() + Math.random(),
      //       variant: 'success',
      //       anchorOrigin: {
      //         vertical: 'bottom',
      //         horizontal: 'center',
      //       },
      //       TransitionComponent: Slide,
      //     },
      //   })
      // );
      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const getCodes = createAsyncThunk('codeCoupon/getCodes', async () => {
  let data;
  try {
    const response = await axios.get(`/instructor/discount-code`);
    data = await response.data;
    if (response.status === 200) {
      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const getBenefitsHistory = createAsyncThunk('codeCoupon/getBenefitsHistory', async ({id}) => {
  let data;
  try {
    const response = await axios.get(`/instructor/discount-code-histories-profit/${id}`);
    data = await response.data;
    if (response.status === 200) {
      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

const slice = createSlice({
  name: 'codeCoupon',
  initialState,
  reducers: {},
  extraReducers: {
    [generateCode.pending]: (state, action) => {
      state.status = 'loading';
    },
    [generateCode.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.codes.push(action.payload);
    },
    [generateCode.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    [getCodes.pending]: (state, action) => {
      state.status = 'loading';
    },
    [getCodes.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.codes = action.payload;
    },
    [getCodes.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
     [getBenefitsHistory.pending]: (state, action) => {
      state.status = 'loading';
    },
    [getBenefitsHistory.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.benefitsHistory = action.payload;
    },
    [getBenefitsHistory.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export default slice;
