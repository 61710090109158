import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../utilities/axios';

const initialState = {
    customContents: {
        data: [],
        status: 'idle',
        error: null,
    },
    recordings: {
        data: [],
        status: 'idle',
        error: null,
    },
};


export const fetchCustomContents = createAsyncThunk(
    'customContents/fetchCustomContents',
    async (chapterSlug) => {
        let data;
        try {
            const response = await axios.get(`/instructor/${chapterSlug}/content-player`);
            data = await response.data;
            if ((response.status = 200)) {
                return data.payload;
            }
            throw new Error(response.statusText);
        } catch (err) {
            console.log(err);
            return Promise.reject(err.message ? err.message : data?.message);
        }
    }
);


export const getRecordingContents = createAsyncThunk(
    'customContents/getRecordingContents',
    async (chapterSlug) => {
        let data;
        try {
            const response = await axios.get(`/instructor/${chapterSlug}/chapters-recording`);
            data = await response.data;
            if ((response.status = 200)) {
                return data.payload;
            }
            throw new Error(response.statusText);
        } catch (err) {
            console.log(err);
            return Promise.reject(err.message ? err.message : data?.message);
        }
    }
);




const slice = createSlice({
    name: 'customContents',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchCustomContents.pending]: (state, action) => {
            state.customContents.status = 'loading';
        },
        [fetchCustomContents.fulfilled]: (state, action) => {
            state.customContents.status = 'succeeded';

            const newContents = action.payload.contents.map((content) => {
                return {
                    id: content.contentId,
                    name: content.contentName,
                    tab_id: content.tabId === null ? '1' : content.tabId,
                    section_id: content.sections,
                    position: parseInt(content?.content?.position),
                    type: 'content',
                    level: content.sections === null ? 1 : 2,
                    data: content.content,
                };
            });

            const newSections = action.payload.sections.map((section) => {
                return {
                    id: section?.sectionId,
                    name: section?.sectionName,
                    tab_id: section?.tabId,
                    position: parseInt(section?.sectionPosition, 10),
                    type: 'section',
                    level: 1,
                };
            });

            state.customContents.data = {
                subject: action.payload.subject,
                division: action.payload.division,
                chapter: action.payload.chapter,
                tabs: action.payload.tabs,
                contents: newContents
                    .concat(newSections)
                    .slice()
                //.sort((a, b) => (a.name < b.name ? 1 : -1))
                //.sort((a, b) => (a.position > b.position ? 1 : -1)),
            };
        },
        [fetchCustomContents.rejected]: (state, action) => {
            state.customContents.status = 'failed';
            state.customContents.error = action.payload;
        },

        [getRecordingContents.pending]: (state, action) => {
            state.recordings.status = 'loading';
        },
        [getRecordingContents.fulfilled]: (state, action) => {
            state.recordings.status = 'succeeded';

            const newContents = action.payload.contents.map((content) => {
                return {
                    id: content.contentId,
                    name: content.contentName,
                    tab_id: content.tabId === null ? '1' : content.tabId,
                    section_id: content.sections,
                    position: parseInt(content?.content?.position),
                    type: 'content',
                    level: content.sections === null ? 1 : 2,
                    data: content.content,
                };
            });

            const newSections = action.payload.sections.map((section) => {
                return {
                    id: section?.sectionId,
                    name: section?.sectionName,
                    tab_id: section?.tabId,
                    position: parseInt(section?.sectionPosition, 10),
                    type: 'section',
                    level: 1,
                };
            });

            state.recordings.data = {
                subject: action.payload.subject,
                division: action.payload.division,
                chapter: action.payload.chapter,
                tabs: action.payload.tabs,
                contents: newContents
                    .concat(newSections)
                    .slice()
            };
        },
        [getRecordingContents.rejected]: (state, action) => {
            state.recordings.status = 'failed';
            state.recordings.error = action.payload;
        },
    },
});

export const reducer = slice.reducer;

export default slice;
