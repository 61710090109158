import React from 'react';
import Im_Profile from '../assets/img/icons/user.svg';
import Im_Matieres from '../assets/img/icons/book.svg';
import Im_Direct from '../assets/img/icons/cast.svg';
import Im_Forum from '../assets/img/icons/chat.svg';
import Im_Facebook from '../assets/img/icons/facebook.svg';
import Im_Instagram from '../assets/img/icons/instagram.svg';
import Im_Youtube from '../assets/img/icons/youtube.svg';
import Im_Videos from '../assets/img/icons/video.svg';
import Im_Magazines from '../assets/img/icons/magazines.svg';
import Im_Qcm from '../assets/img/icons/rule_black_48dp.svg';
import Im_Taches from '../assets/img/icons/tasks.svg';
import Im_Examens2 from '../assets/img/icons/exams.svg';
import Im_Switch from '../assets/img/icons/switch.svg';
import Im_Coupon from '../assets/img/icons/coupon.svg';
import Im_Revenu from '../assets/img/icons/revenu.svg';

export default {
  HEADERS_API: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
  DRAWER_WIDTH: 280,
  CHAPTER_TYPES:[
      {
        name: 'Cours',
        value: 'course',
      },
      {
        name: 'Enregistrement',
        value: 'recording',
      },
      {
        name: 'Examen',
        value: 'exam',
      },
      {
        name: 'Divers',
        value: 'divers',
      }
],
  SIDEBAR_LIST: [
    {
      id: 2,
      icon: Im_Profile,
      name: 'Mon profil',
      url: '/mon-profil',
      type: 'route',
      permission: false,
    },
    {
      id: 3,
      icon: Im_Matieres,
      name: 'Matières',
      // url: '/matieres',
      url: '/',
      type: 'route',
      permission: false,
    },
    {
      id: 4,
      icon: Im_Videos,
      name: 'Vidéos',
      url: '/videos',
      type: 'route',
      permission: false,
    },
    {
      id: 5,
      icon: Im_Magazines,
      name: 'Magazines',
      url: '/magazines',
      type: 'route',
      permission: false,
    },
    {
      id: 6,
      icon: Im_Qcm,
      name: 'QCM',
      url: '/qcm',
      type: 'route',
      permission: false,
    },
    {
      id: 7,
      icon: Im_Direct,
      name: 'Mes séances en direct',
      url: '/seances-en-direct',
      type: 'route',
      permission: false,
    },
    {
      id: 8,
      icon: Im_Taches,
      name: 'Mes tâches',
      url: '/taches',
      type: 'route',
      permission: false,
    },
    {
      id: 9,
      icon: Im_Examens2,
      name: 'Examens et correction',
      url: '/examens',
      type: 'route',
      permission: false,
    },
    {
      id: 10,
      icon: Im_Switch,
      name: "Voir en tant qu'étudiant",
      url: 'switch-classes-modal',
      type: 'modal',
      permission: true,
    },
    {
      id: 11,
      icon: Im_Forum,
      name: 'Forum',
      url: 'https://forum.takiacademy.com',
      type: 'link',
      permission: false,
    },
    {
      id: 14,
      icon: Im_Coupon,
      name: 'Code coupon',
      url: '/code-coupon',
      type: 'route',
      permission: false,
    },
    {
      id: 15,
      icon: Im_Revenu,
      name: 'Revenu',
      url: '/revenu',
      type: 'route',
      permission: false,
    },
  ],
  SOCIALS_LIST: [
    {
      name: 'Facebook',
      img: Im_Facebook,
      url: 'https://www.facebook.com/takiacademy',
    },
    {
      name: 'Instagram',
      img: Im_Instagram,
      url: 'https://www.instagram.com/takiacademy',
    },
    {
      name: 'Youtube',
      img: Im_Youtube,
      url: 'https://www.youtube.com/takiacademy',
    },
  ],
  SUBJECTS: [
    {
      id: 1,
      name: 'Physique',
      classes: [
        {
          id: 1,
          name: '1ère Secondaire',
        },
        {
          id: 2,
          name: '2ème Scientifique Secondaire',
        },
        {
          id: 3,
          name: '2ème Informatique Secondaire',
        },
        {
          id: 4,
          name: '3ème Mathématiques Secondaire',
        },
        {
          id: 5,
          name: '3ème Sciences Exp Secondaire',
        },
        {
          id: 6,
          name: '3ème Informatique Secondaire',
        },
        {
          id: 7,
          name: '3ème Technique Secondaire',
        },
        {
          id: 8,
          name: 'Bac Mathématiques',
        },
        {
          id: 9,
          name: 'Bac Sciences Exp',
        },
        {
          id: 10,
          name: 'Bac Informatique',
        },
        {
          id: 11,
          name: 'Bac Technique',
        },
      ],
    },
    {
      id: 2,
      name: 'Chimie',
      classes: [
        {
          id: 1,
          name: 'Bac Mathématiques',
        },
        {
          id: 2,
          name: 'Bac Sciences Exp',
        },
        {
          id: 3,
          name: 'Bac Informatique',
        },
        {
          id: 4,
          name: 'Bac Technique',
        },
      ],
    },
  ],
};

export const calenderTimes = [
  { time: '' },
  { time: '09:00' },
  { time: '10:00' },
  { time: '11:00' },
  { time: '12:00' },
  { time: '13:00' },
  { time: '14:00' },
  { time: '15:00' },
  { time: '16:00' },
  { time: '17:00' },
  { time: '18:00' },
  { time: '19:00' },
  { time: '20:00' },
  { time: '21:00' },
  { time: '22:00' },
  { time: '23:00' },
];
export const days = [
  { index: 0 },
  { index: 1 },
  { index: 2 },
  { index: 3 },
  { index: 4 },
  { index: 5 },
  { index: 6 },
  { index: 7 },
];

export const months = [
  { name: 'Juillet', id: 'July', monthNumber: 7 },
  { name: 'Août', id: 'August', monthNumber: 8 },
  { name: 'Septembre', id: 'September', monthNumber: 9 },
  { name: 'Octobre', id: 'October', monthNumber: 10 },
  { name: 'Novembre', id: 'November', monthNumber: 11 },
  { name: 'Décembre', id: 'December', monthNumber: 12 },
  { name: 'Janvier', id: 'January', monthNumber: 1 },
  { name: 'Février', id: 'February', monthNumber: 2 },
  { name: 'Mars', id: 'March', monthNumber: 3 },
  { name: 'Avril', id: 'April', monthNumber: 4 },
  { name: 'Mai', id: 'May', monthNumber: 5 },
  { name: 'Juin', id: 'June', monthNumber: 6 },
];

export const initialNumbersCurrentMonth = [
  {
    title: 'Séances en direct',
    'id': 'liveSessions',
    'value': 0,
    'unit': '',
  },
  {
    title: 'Videos Silver',
    'id': 'videoSilver',
    'value': '0/mn',
    'unit': '',
  },
  {
    title: 'Magazines',
    'id': 'magazine',
    'value': 0,
    'unit': '',
  },
  {
    title: 'Qcm',
    'id': 'qcm',
    'value': 0,
    'unit': '',
  },
  {
    title: 'Youtube',
    'id': 'youtube',
    'value': 0,
    'unit': '',
  },
  {
    title: 'Forum',
    'id': 'forum',
    'value': 0,
    'unit': '',
  },
];

export const cardDefaultHour = 1.6;

export const calenderMobileWidth = 599;
export const offerCardMaxWidth = 463.25;
export const offerCardMaxHeight = 850;
export const recGeneralRec = 'Général';

export const headersRowIncomes = [
  { title: 'Mois', align: 'left' },
  { title: 'Solde', align: 'left' },
  { title: 'Défaut', align: 'left' },
  { title: 'Solde Final', align: 'left' },
  { title: '', align: 'right' },
];

export const headersRowMonthIncomes = [
  { title: 'Article', align: 'left' },
  { title: 'Quantité', align: 'left' },
  { title: 'Prix unitaire', align: 'left' },
  { title: 'Défaut', align: 'left' },
  { title: 'Prix total', align: 'left' },
  { title: '', align: 'right' },
];
export const headersRowMonthDetailsDirect = [
  { title: 'Date', align: 'left' },
  { title: 'Titre', align: 'left' },
  { title: 'Groupes', align: 'left' },
  { title: 'Défaut', align: 'left' },
  { title: 'Prix unitaire', align: 'left' },
  { title: 'Prix total', align: 'left' },
  { title: '', align: 'right' },
];

export const headersRowMonthDetailsDefaults = [
  [
    { title: 'Pénalité', align: 'left' },
    { title: 'Nombre(minute)', align: 'left' },
    { title: 'Points Réduits', align: 'right' },
  ],
  [
    { title: 'Pénalité', align: 'left' },
    { title: 'Nombre(jour)', align: 'left' },
    { title: 'Points Réduits', align: 'right' },
  ],
  [
    { title: 'Pénalité', align: 'left' },
    { title: 'Points Réduits', align: 'right' },
  ],
  [
    { title: 'Pénalité', align: 'left' },
    { title: 'Points Réduits', align: 'right' },
  ],
  [
    { title: 'Pénalité', align: 'left' },
    { title: 'Points Réduits', align: 'right' },
  ],
  [
    { title: 'Pénalité', align: 'left' },
    { title: 'Nombre(jour)', align: 'left' },
    { title: 'Points Réduits', align: 'right' },
  ],
];
