import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import Pdf from '../../../assets/img/icons/pdf.svg';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';

const Index = (props) => {
  const { lesson, coursesNumber, magNumber, duration } = props;
  const attachments = lesson && lesson.attachments;
  const locked = lesson && lesson.locked;
  return (
    <div className="video-information" style={{ minHeight: 84 }}>
      <Grid container spacing={2} className="container">
        <Grid item xs className="box">
          <Typography className="title">À propos du Chapitre</Typography>
          <br />
          <Typography variant="h5" component="h2" className="description">
            Durée: {duration} <br />
            Vue générale : {coursesNumber} Cours - {magNumber} Magazines
          </Typography>
        </Grid>
        <Grid item xs className="box">
          <Typography className="title">Pièces jointes</Typography>
          <br />
          {attachments && attachments.length > 0 && locked === false ? (
            <div>
              {attachments.map((attachement) => {
                return (
                  <div>
                    <ButtonGroup className="group">
                      <Tooltip title={attachement.name} aria-label="add">
                        <Button className="buttonUpload">
                          <Icon>
                            <img src={Pdf} />
                          </Icon>
                          <Typography className="textUpload">{attachement.name}</Typography>
                        </Button>
                      </Tooltip>
                      <Button
                        className="buttonDownload"
                        component={'a'}
                        startIcon={<GetAppRoundedIcon className="iconDownload" />}
                        href={attachement.url}
                        target="_blank"
                        download={attachement.url}
                      >
                        Télécharger
                      </Button>
                    </ButtonGroup>
                  </div>
                );
              })}
            </div>
          ) : (
            <p>Aucune pièce jointe dans cette leçon</p>
          )}
        </Grid> 
      </Grid>
    </div>
  );
};

export default Index;
