import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { serialize } from 'object-to-formdata';
import Button from '@material-ui/core/Button';
import {
  TextField,
  Box,
  FormHelperText,
  Typography,
  Switch,
  Select,
  MenuItem,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import {
  fetchAllInstructors,
  fetchAllContentScopes,
  fetchChaptersByInstructor,
  fetchOnglets,
} from '../../slices/diverse';
import { addNewQcm, editQcm } from '../../slices/qcm';
import CircularProgress from '@material-ui/core/CircularProgress';


const AddQcm = ({ id, open, handleClose, data, ...rest }) => {
  const { allInstructors, allContentScopes, chaptersByInstructor, onglets } = useSelector(
    (state) => state.diverse
  );

  const { status } = useSelector(
    (state) => state.qcm
  );

  const dispatch = useDispatch();
  const isMountedRef = useIsMountedRef();

  useEffect(() => {
    if (allInstructors.status === 'idle' && open === true) {
      dispatch(fetchAllInstructors());
    }
    if (allContentScopes.status === 'idle' && open === true) {
      dispatch(fetchAllContentScopes());
    }
    if (onglets.status === 'idle' && open === true) {
      dispatch(fetchOnglets());
    }
  }, [open, allInstructors.status, allContentScopes.status, onglets.status, dispatch]);

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(id)}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="ta-modal add-video-modal"
    >
      <DialogTitle id="alert-dialog-title">
        <span className="label">{!data?.edit ? 'Ajouter un nouveau qcm' : 'Modifier le qcm'}</span>
        <span className="close-btn" onClick={() => handleClose(id)}>
          Annuler
        </span>
      </DialogTitle>
      <DialogContent className="ta-modal-content-scroll">
        <Formik
          initialValues={{
            title: !data?.edit ? '' : data?.qcm?.title,
            isPublic: !data?.edit ? false : data?.qcm?.is_active,
            isDraft: !data?.edit ? false : data?.qcm?.is_draft,
            description: !data?.edit ? '' : data?.qcm?.description || '',
            scopes: !data?.edit ? null : data?.qcm?.scope,
            teachers: !data?.edit
              ? null
              : {
                  ...data?.qcm?.teacher,
                  fullName: `${data?.qcm?.teacher?.name} ${data?.qcm?.teacher?.last_name}`,
                },
            chapters: !data?.edit
              ? []
              : data?.qcm?.chapters.map((item) => {
                  return {
                    id: item?.chapter?.id,
                    name: `${item?.chapter?.subject?.name}|${item?.chapter?.subject?.division?.name}|${item?.chapter?.name}`,
                    tab: item?.tab,
                    section: item?.section,
                    slug: item?.chapter?.slug,
                    position: item?.position,
                  };
                }),
            onglet: !data?.edit ? '' : data?.qcm?.tab?.id || '',
            qcmId: !data?.edit ? '' : data?.qcm?.qcm_id || '',
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            title: Yup.string().max(255).required('Title is required'),
            isPublic: Yup.bool(),
            isDraft: Yup.bool(),
            description: Yup.string().max(255),
            scopes: Yup.object()
              .shape({ id: Yup.number() })
              .nullable()
              .required('Scope is required.'),
            teachers: Yup.object()
              .shape({ id: Yup.number() })
              .nullable()
              .required('Teacher is required.'),
            chapters: Yup.array(),
            onglet: Yup.string().max(255).required('Onglet is required'),
            qcmId: Yup.string().max(255).required('Qcm id is required'),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              let dataToSend = {
                name: values.title,
                description: values.description,
                active: values.isPublic,
                isDraft: values.isDraft,
                type: values.type,
                scope: values.scopes.id,
                instructor: values.teachers.id,
                position: 0,
                contentQcmId: values.qcmId,
                isEventTitleApproved: false,
                chapterContents:
                  values.chapters.length > 0
                    ? values.chapters.map((item, index) => {
                        return {
                          position:
                            data?.qcm?.chapters.find((chapter) => chapter.chapter.id === item.id)
                              .position || 0,
                          chapter: item?.id,
                          tab: values.onglet,
                        };
                      })
                    : values.chapters,
              };
              if (!data?.edit) {
                const formData = serialize(dataToSend, { indices: true });
                dispatch(addNewQcm({ values: formData }));
              } else {
                dataToSend = {
                  ...dataToSend,
                  position: undefined,
                };
                const formData = serialize(dataToSend, { indices: true });
                dispatch(
                  editQcm({
                    values: formData,
                    qcmId: data?.qcm?.id,
                    chapterSlug: data?.chapterId,
                    chapterId: data?.chapterIdd,
                    external: data?.external,
                  })
                );
              }

              if (isMountedRef.current) {
                setStatus({ success: true });
                setSubmitting(false);
              }
            } catch (err) {
              console.error(err);
              if (isMountedRef.current) {
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            resetForm,
            setFieldValue,
            setTouched,
          }) => (
            <form noValidate onSubmit={handleSubmit} className="ta-form" {...rest}>
              <Typography variant="body2" color="textSecondary" className="label">
                Titre*
              </Typography>
              <TextField
                className="input"
                error={Boolean(touched.title && errors.title)}
                fullWidth
                autoFocus
                helperText={touched.title && errors.title}
                margin="normal"
                name="title"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.title}
                variant="outlined"
                size="small"
              />
              <Typography variant="body2" color="textSecondary">
                Publié
              </Typography>
              <Switch
                checked={values.isPublic}
                color="primary"
                edge="start"
                name="isPublic"
                onChange={handleChange}
                value={values.isPublic}
              />
              <Typography variant="body2" color="textSecondary">
                Brouillon
              </Typography>
              <Switch
                checked={values.isDraft}
                color="primary"
                edge="start"
                name="isDraft"
                onChange={(e, value) => {
                  setFieldValue('isDraft', value);
                  if (value === true) {
                    setFieldValue('isPublic', !value);
                  }
                }}
                value={values.isDraft}
              />
              <Typography variant="body2" color="textSecondary">
                Description
              </Typography>
              <TextField
                className="input"
                error={Boolean(touched.description && errors.description)}
                fullWidth
                multiline
                rows={4}
                helperText={touched.description && errors.description}
                margin="normal"
                name="description"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.description}
                variant="outlined"
                size="small"
              />
              <Typography variant="body2" color="textSecondary">
                Scope*
              </Typography>
              <Autocomplete
                className={touched.scopes && errors.scopes ? 'input' : 'input mb'}
                name="scopes"
                onBlur={() => setTouched({ scopes: true })}
                fullWidth
                size="small"
                onChange={(e, value) => setFieldValue('scopes', value)}
                getOptionSelected={(option, value) => option.name === value.name}
                options={allContentScopes.data}
                value={values.scopes}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    helperText={touched.scopes && errors.scopes}
                    error={Boolean(touched.scopes && errors.scopes)}
                  />
                )}
              />
              <Typography variant="body2" color="textSecondary">
                Liste des enseignants*
              </Typography>
              <Autocomplete
                className={touched.teachers && errors.teachers ? 'input' : 'input mb'}
                name="teachers"
                onBlur={() => setTouched({ teachers: true })}
                disabled={data?.external != null}
                fullWidth
                size="small"
                onChange={(e, value) => {
                  setFieldValue('teachers', value);
                  if (value !== null) {
                    dispatch(fetchChaptersByInstructor({ id: value.id }));
                  } else {
                    setFieldValue('chapters', []);
                  }
                }}
                getOptionSelected={(option, value) => option.fullName === value.fullName}
                options={allInstructors.data}
                value={values.teachers}
                getOptionLabel={(option) => option.fullName}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    helperText={touched.teachers && errors.teachers}
                    error={Boolean(touched.teachers && errors.teachers)}
                  />
                )}
              />
              <Typography variant="body2" color="textSecondary">
                Chapitres sélectionnés
              </Typography>
              <Autocomplete
                className={touched.chapters && errors.chapters ? 'input' : 'input mb'}
                name="chapters"
                onBlur={() => setTouched({ chapters: true })}
                multiple
                fullWidth
                size="small"
                onFocus={() => {
                  if (data?.edit && chaptersByInstructor.status === 'idle') {
                    dispatch(fetchChaptersByInstructor({ id: values?.teachers?.id }));
                  }
                }}
                onChange={(e, value) => setFieldValue('chapters', value)}
                getOptionSelected={(option, value) => option.name === value.name}
                options={chaptersByInstructor.data}
                value={values.chapters}
                getOptionLabel={(option) => option.name}
                disabled={values.teachers === null || data?.external != null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    helperText={touched.chapters && errors.chapters}
                    error={Boolean(touched.chapters && errors.chapters)}
                  />
                )}
              />
              <Typography variant="body2" color="textSecondary">
                Choisir un onglet*
              </Typography>
              <Select
                className={touched.onglet && errors.onglet ? 'input size-sm' : 'input size-sm mb'}
                error={Boolean(touched.onglet && errors.onglet)}
                fullWidth
                margin="normal"
                name="onglet"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.onglet}
                variant="outlined"
              >
                {onglets.data &&
                  onglets.data.length > 0 &&
                  onglets.data.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
              {touched.onglet && errors.onglet && (
                <span className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense">
                  {errors.onglet}
                </span>
              )}
              <Typography variant="body2" color="textSecondary" className="label">
                Qcm id*
              </Typography>
              <TextField
                className="input"
                error={Boolean(touched.qcmId && errors.qcmId)}
                fullWidth
                helperText={touched.qcmId && errors.qcmId}
                margin="normal"
                name="qcmId"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.qcmId}
                variant="outlined"
                size="small"
              />

              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
              <Box mt={2} className="ta-btns-group">
                <Button
                  disabled={isSubmitting || status === 'loading'}
                  type="submit"
                  className="ta-btn primary btn-rounded btn-lg"
                >
                  {status === 'loading' && <CircularProgress size={20} style={{ margin: '0 auto' }} />}
                  {!data?.edit ? 'Ajouter' : 'Modifier'}
                </Button>
                <Button
                  disabled={isSubmitting}
                  type="reset"
                  className="ta-btn btn-rounded btn-lg"
                  onClick={resetForm}
                >
                  Vider les champs
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

AddQcm.propTypes = {};

export default AddQcm;
