import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ifvisible from 'ifvisible.js';

const PaidPlayer = (props) => {
  const { currentLesson, data } = props;
  const otp = currentLesson && currentLesson?.otp;
  const playbackInfo = currentLesson && currentLesson?.playbackInfo;


  useEffect(() => {
    if (data && playbackInfo) {
      const video = new window.VdoPlayer({
        otp: otp,
        playbackInfo: playbackInfo,
        theme: '9ae8bbe8dd964ddc9bdb932cca1cb59a',
        container: document.querySelector('#embedBox'),
        plugins: [{ name: 'keyboard', options: { preset: 'default' } }],
      });
      video.addEventListener('load', () => {
        const duration = video.duration;
        let completed = 0;
        let completedSaved = false;
        let oldTotalPlayed = 0;
        const saveInterval = setInterval(() => {
          if ((video.totalCovered * 100) / duration >= 80) {
            completed = 1;
          }

          if (completed && !completedSaved) {
            completedSaved = true;
            clearInterval(saveInterval);
          }
          if (video.status === 1) {
            if (video.totalPlayed >= oldTotalPlayed + 30) {
              oldTotalPlayed += 20;
            }
          }
        }, 1000);

        ifvisible.on('blur', () => {
          if (video.hasOwnProperty('status') && video.status === 1) {
            video.pause();
          }
        });
        ifvisible.on('wakeup', () => {
          if (video.hasOwnProperty('status') && video.status === 2) {
            video.play();
          }
        });
      });
    }
  }, [data, otp, playbackInfo]);
  return (
    <div>
      {data && (
        <div className="rounded">
          <div id="embedBox" className="embed-responsive-item"></div>
        </div>
      )}
    </div>
  );
};

PaidPlayer.propTypes = {
  data: PropTypes.object,
  otp: PropTypes.any,
  playbackInfo: PropTypes.any,
  update: PropTypes.bool,
};

export default PaidPlayer;
