import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Im_cover from '../../assets/img/teacher/book.png';

const Suggestion = ({ id, open, handleClose, data }) => {
  return (
    <Dialog
      open={open}
      onClose={() => handleClose(id)}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="ta-modal add-video-modal"
    >
      <DialogTitle id="alert-dialog-title">
        <span className="label"></span>
        <span className="close-btn" onClick={() => handleClose(id)}>
          Fermer
        </span>
      </DialogTitle>
      <DialogContent className="ta-modal-content-scroll suggestion-modal-container">
        <div className="avatar">
          <Avatar alt="img" />
          <div className="texts">
            <span className="name">{data?.name}</span>
            <span className="date">03/09/2021 - 15:40</span>
          </div>
        </div>
        <span className="label">Réclamation</span>
        <img alt="image" src={Im_cover} />
        <span className="title">La video de cours 1 physique est un peu floue</span>
        <span className="description">
          Contrairement à une opinion répandue, le Lorem Ipsum n'est pas simplement du texte
          aléatoire. Il trouve ses racines dans une oeuvre de la littérature latine classique datant
          de 45 av. J.-C., le rendant vieux de 2000 ans. Un professeur du Hampden-Sydney College, en
          Virginie, s'est intéressé à un des mots latins les plus obscurs, consectetur, extrait d'un
          passage du Lorem Ipsum, et en étudiant tous les usages de ce mot dans la littérature
          classique, découvrit la source incontestable du Lorem Ipsum.
        </span>
        <Button className="ta-btn primary btn-rounded btn-lg">Répondre</Button>
      </DialogContent>
    </Dialog>
  );
};

Suggestion.propTypes = {};

export default Suggestion;
