import React from 'react';

const Badge = ({ text, color, className }) => {
  return (
    <div className={`badge ${color} ${className ? className : ''}`}>
      <span>{text}</span>
    </div>
  );
};

export default Badge;
