import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Paper, Grid, Icon, TableRow, TableCell, Typography, Tooltip } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Alert from '@material-ui/lab/Alert';
import Badge from '../Badge';
import ViewSubtitle from '../ViewSubtitle';
import { openModal } from '../../slices/modals';
import Chevron from '../../assets/img/icons/ArrowChevronForward.svg';
import TableParams from '../TableParams';
import useCell from '../TableParams/style';
import LoadingScreen from '../LoadingScreen';
import { fetchMonthDetailsDefaults } from '../../slices/incomes';
import { classBadge } from './MonthIncomes';
import { headersRowMonthDetailsDirect as headersRow } from '../../utilities/constants';
import useStyles from './ModalStyles/MonthDetailsDirectStyle';
import DialogTitleModal from '../DialogTitleModal';

const MonthDetailsDirect = ({ id, open, handleClose }) => {
  const classes = useStyles();
  const cellClasses = useCell();
  const dispatch = useDispatch();

  const { data: dataAPI, status } = useSelector((state) => state.incomes.monthDetailsDirect);

  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={(e, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          handleClose(id);
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.dialog}
    >
      {status === 'loading' && <LoadingScreen />}
      {status === 'failed' && (
        <>
          <DialogTitleModal
            title="Séances en direct"
            closeHandler={() => handleClose(id)}
            undoHandler={() => {
              dispatch(openModal('month-incomes-modal', null));
              handleClose(id);
            }}
            undo
          />
          <DialogContent className={classes.dialogContent}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              className={classes.dialogContentHeader}
            >
              <Paper elevation={0}>
                <Alert severity="error">
                  Un error est servenu: Merci de contacter administrateur
                </Alert>
              </Paper>
            </Grid>
          </DialogContent>
        </>
      )}
      {status === 'idle' && <div>Pas de contenu </div>}

      {status === 'succeeded' && (
        <>
          <DialogTitleModal
            title="Séances en direct"
            closeHandler={() => handleClose(id)}
            undoHandler={() => {
              dispatch(openModal('month-incomes-modal', null));
              handleClose(id);
            }}
            undo
          />

          <DialogContent className={classes.dialogContent}>
            {dataAPI && dataAPI?.data?.liveSessionsDetails?.length > 0 ? (
              <>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                  className={classes.dialogContentHeader}
                >
                  <Paper elevation={0}>
                    <ViewSubtitle title="Les défauts" className="no-space" />
                  </Paper>
                  <Paper elevation={0}>
                    <Badge
                      text={`${-dataAPI?.data?.totalDefault} pts`}
                      color={dataAPI?.data?.totalDefault > 0 ? 'fault' : 'disable'}
                    />
                  </Paper>
                </Grid>
                <TableParams headersRow={headersRow} minWidth="840px" fitContent>
                  {dataAPI?.data?.liveSessionsDetails.map((row) => (
                    <TableRow key={row.eventId}>
                      <TableCell align="left" className={cellClasses.cell}>
                        {row.date.split('-').reverse().join('-')}
                      </TableCell>

                      <TableCell align="left" className={cellClasses.cell}>
                        {row.title.length > 10 ? (
                          <Tooltip
                            title={<h3>{row.title} </h3>}
                            placement="right"
                            style={{
                              padding: 0,
                              margin: 0,
                              cursor: 'pointer',
                              display: 'inline-block',
                            }}
                          >
                            <p>{row.title.slice(0, 10)} ...</p>
                          </Tooltip>
                        ) : (
                          row.title
                        )}
                      </TableCell>

                      <TableCell align="left" className={cellClasses.cell}>
                        {row.offer.length > 10 ? (
                          <Tooltip
                            title={<h3>{row.offer} </h3>}
                            placement="right"
                            style={{
                              padding: 0,
                              margin: 0,
                              cursor: 'pointer',
                              display: 'inline-block',
                            }}
                          >
                            <p>{row.offer.slice(0, 10)} ...</p>
                          </Tooltip>
                        ) : (
                          row.offer
                        )}
                      </TableCell>

                      <TableCell
                        align="left"
                        className={`${cellClasses.cell} ${cellClasses.redCell}`}
                      >
                        {`${row.default} pts`}
                      </TableCell>

                      <TableCell align="left" className={cellClasses.cell}>
                        {`${row.unitPrice} pts`}
                      </TableCell>

                      <TableCell align="left" className={cellClasses.cell}>
                        {`${row.totalPrice} pts`}
                      </TableCell>

                      <TableCell
                        align="right"
                        className={cellClasses.link}
                        onClick={() => {
                          dispatch(
                            fetchMonthDetailsDefaults({ event: row.eventId, title: row.title })
                          );
                          dispatch(openModal('month-details-defaults-modal'));
                          handleClose(id);
                        }}
                      >
                        Voir détails
                        <Icon className={cellClasses.icon}>
                          <img src={Chevron} alt="Chevron" />
                        </Icon>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableParams>
              </>
            ) : (
              <Typography variant="h6" className={classes.infoAPI} gutterBottom>
                Pas de données à afficher
              </Typography>
            )}
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

export default MonthDetailsDirect;
