import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import DescriptionIcon from '@material-ui/icons/Description';
import Im_timer from '../../assets/img/teacher/timer.png';

const ExamAddFile = ({ id, open, handleClose }) => {
  return (
    <Dialog
      open={open}
      onClose={() => handleClose(id)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="ta-modal add-video-modal"
    >
      <DialogContent className="ta-modal-content-scroll timer-modal-container">
        <img alt="timer-image" src={Im_timer} />
        <span className="label">Temps écoulé !</span>
        <span className="sub-label">Voulez-vous ajouter une correction ?</span>
        <Button
          className="ta-btn primary btn-rounded btn-lg"
          startIcon={<DescriptionIcon fontSize="small" />}
        >
          Ajouter un fichier
        </Button>
      </DialogContent>
    </Dialog>
  );
};

ExamAddFile.propTypes = {};

export default ExamAddFile;
