import React, { useState, useEffect, createRef } from 'react';
import PropTypes from 'prop-types';
import { openModal } from '../../slices/modals';
import { useSelector, useDispatch } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { fetchClasses, fetchUser } from '../../slices/switch';

const linkRef = createRef();

const openLink = () => {
  if (linkRef.current) {
    linkRef.current.click();
  }
};

const SwitchClasses = ({ id, open, handleClose, data }) => {
  const { classes, user } = useSelector((state) => state.switch);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (classes.status === 'idle' && open === true) {
      dispatch(fetchClasses());
    }
  }, [open, classes.status, dispatch]);

  useEffect(() => {
    if (user.status === 'succeeded') {
      setIsLoading(false);
      openLink();
    }
  }, [user.data, user.status, dispatch]);

  let content;

  if (classes.status === 'loading') {
    content = <div>Loading...</div>;
  } else if (classes.status === 'succeeded') {
    if (classes.data.length > 0) {
      content = (
        <>
          {classes.data
            .slice()
            // .sort((a, b) => (a.created_at > b.created_at ? -1 : 1))
            .map((item, index) => (
              <div
                className="switch-classe-item"
                key={index}
                onClick={() => {
                  if (item.sections.length === 1) {
                    setIsLoading(true);
                    dispatch(fetchUser(item.sections[0].userId));
                    return;
                  }
                  handleClose(id);
                  dispatch(openModal('switch-users-modal', { class: item }));
                }}
              >
                <div className="texts">
                  <div className="group">
                    <span className="number">{item.class}</span>
                    <span className="sup">{item.key}</span>
                  </div>
                  <span className="label">{item.slug}</span>
                </div>
                <div className="box"></div>
              </div>
            ))}
        </>
      );
    } else {
      content = <div>Empty...</div>;
    }
  } else if (classes.status === 'error') {
    content = <div>{classes.error}</div>;
  }

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(id)}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="ta-modal add-video-modal"
    >
      <DialogTitle id="alert-dialog-title">
        <span className="label">Voir en tant qu'étudiant</span>
        <span className="close-btn" onClick={() => handleClose(id)}>
          Fermer
        </span>
      </DialogTitle>
      <DialogContent
        className={`ta-modal-content-scroll switch-classes-container${
          isLoading ? ' progress' : ''
        }`}
      >
        <span className="title">Veuillez choisir une classe</span>
        <div className="switch-classes-group">{content}</div>
        <a
          href={`${process.env.REACT_APP_STUDENT_URL}/switch?token=${user.data?.token}&userId=${user.data?.userId}&adminId=${user.data?.instructorUser}`}
          target="_blank"
          rel="noreferrer noopener"
          ref={linkRef}
          style={{ display: 'none' }}
        >
          hidden link
        </a>
      </DialogContent>
    </Dialog>
  );
};

SwitchClasses.propTypes = {};

export default SwitchClasses;
