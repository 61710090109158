import React from 'react';
import LockRoundedIcon from '@material-ui/icons/LockRounded';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import PlayCircleOutlineRoundedIcon from '@material-ui/icons/PlayCircleOutlineRounded';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import getLessonDuration from '../../../utilities/getLessonDuration';
import { sliceLongStrings } from '../../../utilities/methods';
import LessonContent from "../LessonContent";

const Index = (props) => {
  const { tab, courses, subjectSlug, chapterSlug, lessonSlug } = props;
  const lessons = (courses?.contents || []).filter((lesson) => lesson?.type === 'content');
  const sections = (courses?.contents || []).filter((lesson) => lesson?.type !== 'content');
  const type = courses?.chapter?.type;
    return (
      <div className="content-table">
        <div className="li-section">
          {(lessons || [])
              .filter((lesson) => {
                return  (parseInt(lesson?.tab_id) === parseInt(tab?.id )|| parseInt(lesson?.tab_id) === parseInt(tab?.id))  && (lesson.section_id === undefined || !lesson.section_id)})
              // .filter((lesson) =>(lesson.section_id === undefined || !lesson.section_id) && lesson.tab_id === tab?.id)
              //   .sort((a,b)=> (a?.data?.created_at < b?.data?.created_at) ? 1 : -1)
              // .sort((a, b) => (a?.position > b?.position ? 1 : -1))
              .map((lesson) => {
                return (
                    <NavLink
                        to={
                            '/matieres/' +
                            subjectSlug +
                            '/chapitres/' +
                            chapterSlug +
                            '/cours/' +
                            lesson?.data?.slug +
                            '/contenu'
                        }
                        key={lesson.id}
                        id={'lesson-' + lesson.id}
                    >
                      <div key={lesson.id} className="liStyle">
                  <span className="li-icon">
                    {lesson?.lockedVideo ? (
                        <LockRoundedIcon className="iconStyle" />
                    ) : (
                        <>
                          {lesson?.data?.slug === lessonSlug && (
                              <PlayCircleOutlineRoundedIcon className="play" />
                          )}
                          {lesson?.data?.slug !== lessonSlug && (
                              <LockOpenIcon className="iconStyle" />
                          )}
                        </>
                    )}
                  </span>
                        <div
                            className="li-texts"
                            style={
                              lesson?.data?.slug !== lessonSlug
                                  ? { color: '#ffffff' }
                                  : { color: '#2ba7df', fontWeight: 'bold' }
                            }
                        >
                    <span title={lesson.name}>
                      {/* {sliceLongStrings(lesson.name, 32)} */}
                      {sliceLongStrings(lesson.name, 56)}
                    </span>
                          <span>
                      {lesson?.data?.type.id == '1' || lesson?.data?.type.id == '2' || lesson?.data?.type.id == '6'
                          ? getLessonDuration(lesson?.data?.content_videos[0]?.duration)
                          : null}
                    </span>
                        </div>
                      </div>
                    </NavLink>
                );
              })}
        </div>
        {(sections || [])
            .filter((section) => parseInt(section?.tab_id) === parseInt(tab.id))
            .sort((a, b) => (a.name < b.name ? 1 : -1))
            .sort((a, b) => (a.position > b.position ? 1 : -1))
            // .filter((section) => section.tab_id === tab.id)
            .map((section) => (
                <div key={`section-${section.id}`} className="li-section">
          <span key={section.id} className="header">
              {section.name}
            </span>
                  {(type !== "recording" && lessons || [])
                      .filter((lesson) => parseInt(lesson.section_id ) === parseInt(section.id))
                     // .sort((a, b) => (a?.data?.name > b?.data?.name ? 1 : -1))
                     // .sort((a,b)=> (b?.data?.created_at < a?.data?.created_at) ? 1 : -1)

                      .map((lesson, index) => {
                        const lessonKey = `item-${section?.sectionId}-${lesson}`;

                        return (
                            <LessonContent
                                lesson={lesson}
                                lessonKey={lessonKey}
                                type={type}
                                subjectSlug={subjectSlug}
                                chapterSlug={chapterSlug}
                                lessonSlug={lessonSlug}
                            />
                        );
                      })}
                  {(type === "recording" &&
                      lessons || []).filter((lesson) =>
                      parseInt(lesson.section_id ) === parseInt(section.id))
                      .sort((a,b)=> (a?.data?.created_at < b?.data?.created_at) ? 1 : -1)
                      .map((lesson, index) => {
                        const lessonKey = `item-${section?.id}-${lesson}`;
                        return (
                            <LessonContent
                                lesson={lesson}
                                lessonKey={lessonKey}
                                type={type}
                                subjectSlug={subjectSlug}
                                chapterSlug={chapterSlug}
                                lessonSlug={lessonSlug}
                            />
                        );
                      })}
                </div>
            ))}
      </div>
  );
};

Index.propTypes = {
  tab: PropTypes.object,
  courses: PropTypes.object,
  subjectSlug: PropTypes.any,
  chapterSlug: PropTypes.any,
  lessonSlug: PropTypes.any,
};

export default Index;

