import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../utilities/axios';
import { enqueueSnackbar } from './notifier';
import { closeModal } from './modals';
import { fetchContents, addSection, removeSection } from './contents';
import Slide from '@material-ui/core/Slide';
import { toggleLoadingTrue, toggleLoadingFalse } from './deleteLoading';

const initialState = {
  sections: [],
  status: 'idle',
  error: null,
};

export const addNewSection = createAsyncThunk('sections/addNewSection', async (query, thunkAPI) => {
  const { values, chapterSlug } = query;
  let data;
  try {
    const response = await axios.post(`/instructor/content-sections`, values);
    data = await response.data;
    if ((response.status = 200)) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: data.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      // thunkAPI.dispatch(fetchContents(chapterSlug));
      thunkAPI.dispatch(addSection(data.payload, false));
      thunkAPI.dispatch(closeModal('add-section-modal'));
      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const editSection = createAsyncThunk('sections/editSection', async (query, thunkAPI) => {
  const { values, section_id } = query;
  let data;
  try {
    const response = await axios.put(`/instructor/content-sections/${section_id}`, values);
    data = await response.data;
    if ((response.status = 200)) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: data.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      thunkAPI.dispatch(addSection(data.payload, true));
      thunkAPI.dispatch(closeModal('add-section-modal'));
      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const deleteSection = createAsyncThunk('sections/deleteSection', async (query, thunkAPI) => {
  const { chapterId, sectionId, sectionPosition, chapterSlug } = query;
  let data;
  try {
    thunkAPI.dispatch(toggleLoadingTrue());
    const response = await axios.put(`/instructor/chapter/${chapterId}/section/${sectionId}`);
    data = await response.data;
    if (response.status === 200) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: data.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      thunkAPI.dispatch(removeSection(sectionId, sectionPosition));
      thunkAPI.dispatch(closeModal('delete-modal'));
      thunkAPI.dispatch(toggleLoadingFalse());
      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    thunkAPI.dispatch(toggleLoadingFalse());
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

const slice = createSlice({
  name: 'sections',
  initialState,
  reducers: {},
  extraReducers: {
    // [addNewSection.fulfilled]: (state, action) => {
    //   state.chapters.chapters.push({
    //     ...action.payload,
    //     type: action.payload.type === '' ? 'course' : action.payload.type,
    //     description: action.payload.description === null ? '' : action.payload.description,
    //   });
    // },
    // [editSection.fulfilled]: (state, action) => {
    //   console.log('5245465465464654654654654654654');
    //   const index = state.contents.contents.findIndex((item) => item.id === action.payload.id);
    //   console.log(index);
    //   console.log(state.contents.contents);
    //   const newArray = state.contents.contents
    //     .filter((item) => item.id !== action.payload.id)
    //     .push({
    //       id: action.payload.id,
    //       name: action.payload.name,
    //       tab_id: action.payload.tab.id.toString(),
    //       position: action.payload.position,
    //       type: 'section',
    //       level: 1,
    //     });
    //   state.contents.contents = newArray;
    // },
    [deleteSection.fulfilled]: (state, action) => {
      const data = action.payload;   
    },
    [deleteSection.pending]: (state, action) => {
      state.status = 'loading';  
    },
    [deleteSection.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export default slice;
