import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
};

const slice = createSlice({
  name: 'deleteLoading',
  initialState,
  reducers: {
    toggleLoadingTrue: (state, action) => state = true ,

    toggleLoadingFalse: (state, action) => state = false ,
  },
});

export const toggleLoadingTrue= () => (dispatch) => {
  dispatch(slice.actions.toggleLoadingTrue());
};

export const toggleLoadingFalse = () => (dispatch) => {
  dispatch(slice.actions.toggleLoadingFalse());
};

export const reducer = slice.reducer;

export default slice;