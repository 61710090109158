import React, { useEffect, createRef } from 'react';
import { serialize } from 'object-to-formdata';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import * as dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import Button from '@material-ui/core/Button';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import {
  TextField,
  Box,
  FormHelperText,
  Typography,
  Switch,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  fetchAllInstructors,
  fetchAllContentScopes,
  fetchInstructorSubjects,
} from '../../slices/diverse';
import { addNewExam } from '../../slices/exams';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import bytesToSize from '../../utilities/bytesToSize';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc)

const examFilesInputRef = createRef();
const openExamFilesInput = () => {
  if (examFilesInputRef.current) {
    examFilesInputRef.current.click();
  }
};

const correctionFilesInputRef = createRef();
const openCorrectionFilesInput = () => {
  if (correctionFilesInputRef.current) {
    correctionFilesInputRef.current.click();
  }
};

const AddExam = ({ id, open, handleClose, ...rest }) => {
  const { allInstructors, allContentScopes, instructorSubjects } = useSelector(
    (state) => state.diverse
  );
  const dispatch = useDispatch();
  const isMountedRef = useIsMountedRef();
  dayjs.extend(customParseFormat);

  useEffect(() => {
    if (allInstructors.status === 'idle' && open === true) {
      dispatch(fetchAllInstructors());
    }
    if (allContentScopes.status === 'idle' && open === true) {
      dispatch(fetchAllContentScopes());
    }
    if (instructorSubjects.status === 'idle' && open === true) {
      dispatch(fetchInstructorSubjects());
    }
  }, [open, allInstructors.status, allContentScopes.status, instructorSubjects.status, dispatch]);

  const extractFiles = (exams, corrections, teacherId) => {
    let ex = exams;
    let co = corrections;
    if (exams?.length > 0) {
      ex = exams.map((exam, index) => {
        return {
          position: index,
          file: {
            name: exam.name,
            file: exam,
            attachment: 0,
            homework: 1,
            solution: 0,
            document: 0,
            marks: 0,
            teacher: teacherId,
            user: null,
          },
        };
      });
    }
    if (corrections?.length > 0) {
      co = corrections.map((correction, index) => {
        return {
          position: exams?.length + index,
          file: {
            name: correction.name,
            file: correction,
            attachment: 0,
            homework: 0,
            document: 0,
            solution: 1,
            marks: 0,
            teacher: teacherId,
            user: null,
          },
        };
      });
    }
    return [...ex, ...co];
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(id)}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="ta-modal add-video-modal"
    >
      <DialogTitle id="alert-dialog-title">
        <span className="label">Ajouter un nouveau examen</span>
        <span className="close-btn" onClick={() => handleClose(id)}>
          Fermer
        </span>
      </DialogTitle>
      <DialogContent className="ta-modal-content-scroll">
        <Formik
          initialValues={{
            title: '',
            isPublic: false,
            isDraft: false,
            description: '',
            date_apparition: dayjs().format('YYYY-MM-DDTHH:mm').toString(),
            date_limite: dayjs().format('YYYY-MM-DDTHH:mm').toString(),
            scope: null,
            teacher: null,
            subjects: [],
            exams: [],
            corrections: [],
            duration: "02:00",
            submit: null,

          }}
          validationSchema={Yup.object().shape({
            title: Yup.string().max(255).required('Title is required'),
            isPublic: Yup.bool(),
            isDraft: Yup.bool(),
            description: Yup.string().max(255),
            date_apparition: Yup.date().required("Date d'apparition is required").nullable(),
            date_limite: Yup.date()
              .min(
                Yup.ref('date_apparition'),
                ({ min }) =>
                  `La date limite doit être antérieure au ${dayjs(min)
                    .format('DD-MM-YYYY HH:mm')
                    .toString()}`
              )
              .required('Date limite is required')
              .nullable(),
            scope: Yup.object()
              .shape({ id: Yup.number() })
              .nullable()
              .required('Scope is required.'),
            subjects: Yup.array().min(1, 'Subjects is required'),
            teacher: Yup.object()
              .shape({ id: Yup.number() })
              .nullable()
              .required('Teacher is required.'),
            exams: Yup.array().min(1, 'A file is required'),
            corrections: Yup.array(),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              const totalMinutes = dayjs(values?.duration, 'HH:mm').hour() * 60 + dayjs.duration(values?.duration, 'HH:mm').minutes();

              let dataToSend = {
                appearanceDate: values.date_apparition,
                dueDate: values.date_limite,
                content: {
                  name: values.title,
                  description: values.description,
                  scope: values.scope.id,
                  instructor: values.teacher.id,
                  active: values.isPublic,
                  isDraft: values.isDraft,
                  contentFiles: extractFiles(values.exams, values.corrections, values?.teacher?.id),
                },
                duration: totalMinutes,
                subjectExams:
                  values.subjects.length > 0
                    ? values.subjects.map((subject) => {
                        return { subject: subject.id };
                      })
                    : values.subjects,
              };
              const formData = serialize(dataToSend, { indices: true });
              dispatch(addNewExam({ values: formData }));

              if (isMountedRef.current) {
                setStatus({ success: true });
                setSubmitting(false);
              }
            } catch (err) {
              if (isMountedRef.current) {
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            resetForm,
            setFieldValue,
            setTouched,
            setFieldTouched,
          }) => (
            <form noValidate onSubmit={handleSubmit} className="ta-form" {...rest}>
              <Typography variant="body2" color="textSecondary" className="label">
                Titre*
              </Typography>
              <TextField
                className="input"
                error={Boolean(touched.title && errors.title)}
                fullWidth
                autoFocus
                helperText={touched.title && errors.title}
                // label="Title"
                margin="normal"
                name="title"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.title}
                variant="outlined"
                size="small"
              />
              <Typography variant="body2" color="textSecondary">
                Publié
              </Typography>
              <Switch
                checked={values.isPublic}
                color="primary"
                edge="start"
                name="isPublic"
                onChange={handleChange}
                value={values.isPublic}
              />
              <Typography variant="body2" color="textSecondary">
                Brouillon
              </Typography>
              <Switch
                checked={values.isDraft}
                color="primary"
                edge="start"
                name="isDraft"
                onChange={handleChange}
                value={values.isDraft}
              />
              <Typography variant="body2" color="textSecondary">
                Description
              </Typography>
              <TextField
                className="input"
                error={Boolean(touched.description && errors.description)}
                fullWidth
                multiline
                rows={4}
                helperText={touched.description && errors.description}
                margin="normal"
                name="description"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.description}
                variant="outlined"
                size="small"
              />
              <Typography variant="body2" color="textSecondary" className="label">
                Date d'apparition de l'énoncé
              </Typography>
              <TextField
                className="input"
                error={Boolean(touched.date_apparition && errors.date_apparition)}
                fullWidth
                helperText={touched.date_apparition && errors.date_apparition}
                margin="normal"
                name="date_apparition"
                onBlur={handleBlur}
                onChange={handleChange}
                type="datetime-local"
                value={values.date_apparition}
                variant="outlined"
                size="small"
              />
              <Typography variant="body2" color="textSecondary" className="label">
                Date limite
              </Typography>
              <TextField
                className="input"
                error={Boolean(touched.date_limite && errors.date_limite)}
                fullWidth
                helperText={touched.date_limite && errors.date_limite}
                margin="normal"
                name="date_limite"
                onBlur={handleBlur}
                onChange={handleChange}
                type="datetime-local"
                value={values.date_limite}
                variant="outlined"
                size="small"
              />
              <Typography variant="body2" color="textSecondary" className="label">
                Duration
              </Typography>
              <TextField
                  className="input"
                  error={Boolean(touched.duration && errors.duration)}
                  fullWidth
                  autoFocus
                  helperText={touched.duration && errors.duration}
                  margin="normal"
                  name="duration"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.duration}
                  variant="outlined"
                  size="small"
              />
              <Typography variant="body2" color="textSecondary">
                Enseignant*
              </Typography>
              <Autocomplete
                className={touched.teacher && errors.teacher ? 'input' : 'input mb'}
                name="teachers"
                onBlur={() => setTouched({ teacher: true })}
                // multiple
                fullWidth
                size="small"
                onChange={(e, value) => setFieldValue('teacher', value)}
                getOptionSelected={(option, value) => option.fullName === value.fullName}
                options={allInstructors.data}
                value={values.teacher}
                getOptionLabel={(option) => option.fullName}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    helperText={touched.teacher && errors.teacher}
                    error={Boolean(touched.teacher && errors.teacher)}
                  />
                )}
              />
              <Typography variant="body2" color="textSecondary">
                Scope*
              </Typography>
              <Autocomplete
                className={touched.scope && errors.scope ? 'input' : 'input mb'}
                name="teachers"
                onBlur={() => setTouched({ scope: true })}
                // multiple
                fullWidth
                size="small"
                onChange={(e, value) => setFieldValue('scope', value)}
                getOptionSelected={(option, value) => option.name === value.name}
                options={allContentScopes.data}
                value={values.scope}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    helperText={touched.scope && errors.scope}
                    error={Boolean(touched.scope && errors.scope)}
                  />
                )}
              />
              <Typography variant="body2" color="textSecondary">
                Matière*
              </Typography>
              <Autocomplete
                className={touched.subjects && errors.subjects ? 'input' : 'input mb'}
                name="subjects"
                onBlur={() => setTouched({ subjects: true })}
                multiple
                fullWidth
                size="small"
                onChange={(e, value) => setFieldValue('subjects', value)}
                getOptionSelected={(option, value) => option.name === value.name}
                options={instructorSubjects.data}
                value={values.subjects}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    helperText={touched.subjects && errors.subjects}
                    error={Boolean(touched.subjects && errors.subjects)}
                  />
                )}
              />
              <Typography variant="body2" color="textSecondary">
                Fichiers d'examen
              </Typography>
              <section className={touched.exams && errors.exams ? 'dropzone error' : 'dropzone'}>
                <Button
                  className="ta-btn dark btn-rounded "
                  startIcon={<AttachFileIcon />}
                  type="button"
                  onClick={openExamFilesInput}
                >
                  Choisir des fichiers
                </Button>
                <div className="files-list">
                  {values.exams && values.exams.length > 0
                    ? `${values.exams.length} ${
                        values.exams.length > 1 ? `fichiers sélectionnés` : `fichier sélectionné`
                      } - ${bytesToSize(
                        values.exams.reduce((accumulator, currentValue) => {
                          return accumulator + currentValue.size;
                        }, 0)
                      )}`
                    : `aucun fichier sélectionné`}
                </div>
                <input
                  type="file"
                  ref={examFilesInputRef}
                  style={{ display: 'none' }}
                  name="exams"
                  onClick={() => {
                    setFieldTouched('exams', true);
                  }}
                  onChange={(e) => {
                    setFieldValue('exams', [...e.target.files]);
                  }}
                  accept="application/pdf"
                  multiple
                />
              </section>
              {touched.exams && errors.exams && (
                <span className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense">
                  {errors.exams}
                </span>
              )}
              <Typography variant="body2" color="textSecondary">
                Fichiers de correction
              </Typography>
              <section
                className={
                  touched.corrections && errors.corrections ? 'dropzone error' : 'dropzone'
                }
              >
                <Button
                  className="ta-btn dark btn-rounded "
                  startIcon={<AttachFileIcon />}
                  type="button"
                  onClick={openCorrectionFilesInput}
                >
                  Choisir des fichiers
                </Button>
                <div className="files-list">
                  {values.corrections && values.corrections.length > 0
                    ? `${values.corrections.length} ${
                        values.corrections.length > 1
                          ? `fichiers sélectionnés`
                          : `fichier sélectionné`
                      } - ${bytesToSize(
                        values.corrections.reduce((accumulator, currentValue) => {
                          return accumulator + currentValue.size;
                        }, 0)
                      )}`
                    : `aucun fichier sélectionné`}
                </div>
                <input
                  type="file"
                  ref={correctionFilesInputRef}
                  style={{ display: 'none' }}
                  name="corrections"
                  onClick={() => {
                    setFieldTouched('corrections', true);
                  }}
                  onChange={(e) => {
                    setFieldValue('corrections', [...e.target.files]);
                  }}
                  accept="application/pdf"
                  multiple
                />
              </section>
              {touched.corrections && errors.corrections && (
                <span className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense">
                  {errors.corrections}
                </span>
              )}

              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
              <Box mt={2} className="ta-btns-group">
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  className="ta-btn primary btn-rounded btn-lg"
                >
                  Ajouter
                </Button>
                <Button
                  disabled={isSubmitting}
                  type="reset"
                  className="ta-btn btn-rounded btn-lg"
                  onClick={resetForm}
                >
                  Annuler
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

AddExam.propTypes = {};

export default AddExam;
