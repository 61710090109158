import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const Task = ({ id, open, handleClose, data }) => {
  let content;

  if (data) {
    const { task } = data;
    content = (
      <>
        <span className="title">{task?.name}</span>
        <p className="description">
          {task?.description} It is a long established fact that a reader will be distracted by the
          readable content of a page when looking at its layout
        </p>
      </>
    );
  }
  return (
    <Dialog
      open={open}
      onClose={() => handleClose(id)}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="ta-modal add-video-modal"
    >
      <DialogTitle id="alert-dialog-title">
        <span className="label">Détails de tâche</span>
        <span className="close-btn" onClick={() => handleClose(id)}>
          Fermer
        </span>
      </DialogTitle>
      <DialogContent className="ta-modal-content-scroll task-modal-container">
        {content}
      </DialogContent>
    </Dialog>
  );
};

Task.propTypes = {};

export default Task;
