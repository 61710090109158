import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { addNewChapter, editChapter } from '../../slices/chapters';
import { addNewSection, editSection } from '../../slices/sections';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import {
  TextField,
  Box,
  FormHelperText,
  Typography,
  Switch,
  Select,
  MenuItem,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import { fetchOnglets } from '../../slices/diverse';

const AddSection = ({ id, open, handleClose, data, ...rest }) => {
  const isMountedRef = useIsMountedRef();
  const dispatch = useDispatch();
  const { onglets } = useSelector((state) => state.diverse);

  useEffect(() => {
    if (onglets.status === 'idle' && open === true) {
      dispatch(fetchOnglets());
    }
  }, [open, onglets.status, dispatch]);

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(id)}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="ta-modal add-video-modal"
    >
      <DialogTitle id="alert-dialog-title">
        <span className="label">
          {!data?.edit ? 'Ajouter une nouvelle section' : 'Modifier la section'}
        </span>
        <span className="close-btn" onClick={() => handleClose(id)}>
          Fermer
        </span>
      </DialogTitle>
      <DialogContent className="ta-modal-content-scroll">
        <Formik
          initialValues={{
            name: !data?.edit ? '' : data?.content?.title,
            position: !data?.edit ? 0 : data?.content?.position,
            tab: !data?.edit ? data?.selected_tab?.id : data?.content?.tab?.id,
            // subject: !data?.edit ? data?.subject_id : data?.chapter?.subject?.id,
            chapterContents: [{ position: 0, chapter: data?.chapter_id }],
            submit: null,
            isDraft: !data?.edit ? false : data?.content?.is_draft,
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().max(255).required('Title is required'),
            tab: Yup.string().max(255).required('Onglet is required'),
            isDraft: Yup.bool(),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              values.isDraft = values?.isDraft === true ? 1 : 0;
              if (!data?.edit) {
                dispatch(
                  addNewSection({
                    chapterSlug: data?.chapter_slug,
                    values: { ...values, submit: undefined },
                  })
                );
                // console.log(values, data);
              } else {
                dispatch(
                  editSection({
                    section_id: data?.section_id,
                    values: {
                      ...values,
                      position: undefined,
                      chapterContents: undefined,
                      submit: undefined,
                    },
                  })
                );
                // console.log(values, data);
              }

              if (isMountedRef.current) {
                setStatus({ success: true });
                setSubmitting(false);
              }
            } catch (err) {
              console.error(err);
              if (isMountedRef.current) {
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            resetForm,
            setFieldValue,
            setTouched,
          }) => (
            <form noValidate onSubmit={handleSubmit} className="ta-form" {...rest}>
              <Typography variant="body2" color="textSecondary" className="label">
                Titre*
              </Typography>
              <TextField
                className="input"
                error={Boolean(touched.name && errors.name)}
                fullWidth
                autoFocus
                helperText={touched.name && errors.name}
                // label="Title"
                margin="normal"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.name}
                variant="outlined"
                size="small"
              />
              <Typography variant="body2" color="textSecondary">
                Choisir un onglet*
              </Typography>
              <Select
                className={touched.tab && errors.tab ? 'input size-sm' : 'input size-sm mb'}
                error={Boolean(touched.tab && errors.tab)}
                fullWidth
                margin="normal"
                name="tab"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.tab}
                variant="outlined"
              >
                {onglets.data &&
                  onglets.data.length > 0 &&
                  onglets.data.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
              {touched.tab && errors.tab && (
                <span className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense">
                  {errors.tab}
                </span>
              )}
              <Typography variant="body2" color="textSecondary">
                Brouillon
              </Typography>
              <Switch
                  checked={values.isDraft}
                  color="primary"
                  edge="start"
                  name="isDraft"
                  onChange={(e, value)=>{
                    setFieldValue("isDraft",value)
                  }}
                  value={values.isDraft}
              />
              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
              <Box mt={2} className="ta-btns-group">
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  className="ta-btn primary btn-rounded btn-lg"
                >
                  {!data?.edit ? 'Ajouter' : 'Modifier'}
                </Button>
                <Button
                  disabled={isSubmitting}
                  type="reset"
                  className="ta-btn btn-rounded btn-lg"
                  onClick={resetForm}
                >
                  Annuler
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

AddSection.propTypes = {};

export default AddSection;
