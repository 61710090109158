import React from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import PropTypes from 'prop-types';

const Index = (props) => {
  const { subject, lesson, subjectSlug } = props;
  return (
    <div className="video-path">
      <Breadcrumbs separator="›" className="separator" aria-label="breadcrumb">
        <Link classeName="title" href={'/matieres/' + subjectSlug + '/chapitres'}>
          <Typography className="title">{subject ? subject.name : ''}</Typography>
        </Link>
        <Link classeName="title" href={'/matieres/' + subjectSlug + '/chapitres/' + lesson?.slug + '/contenu'}>
          <Typography className="subTitle">{lesson ? lesson.name : ''}</Typography>
        </Link>
      </Breadcrumbs>
    </div>
  );
};

Index.propTypes = {
  subject: PropTypes.object,
  lesson: PropTypes.object,
  subjectSlug: PropTypes.any,
};

export default Index;
