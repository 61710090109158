import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUser } from '../../../slices/user';
import ContainerItems from './ContainerItems';
import constants from '../../../utilities/constants';

const list = constants.SIDEBAR_LIST;

const Container = ({ handleDrawerToggle, mobileOpen }) => {
  const dispatch = useDispatch();
  const { user, status } = useSelector((state) => state.user);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchUser());
    }
  }, [status]);

  const isAllowed = () => {
    if (user?.is_admin_teacher === true || user?.external_teacher === false) {
      return true;
    }
    if (user?.is_admin_teacher == null || user?.external_teacher === null) {
      return false;
    }
  };

  // const permissions = null;
  // const informations = null;
  // const { permissions } = useSelector((state) => state.user);
  // const { informations } = useSelector((state) => state.user);
  // const userPermissions = [];

  // if (permissions) {
  //   if (permissions.forum === true) {
  //     userPermissions.push('HAS_FORUM');
  //   }
  //   if (permissions.exam === true) {
  //     userPermissions.push('HAS_EXAM');
  //   }
  // }

  // const isAllowed = (permissions, item) => {
  //   if (!item.permission) {
  //     return true;
  //   }
  //   return permissions.includes(item.permission_key);
  // };

  return (
    <div className="container">
      <ul>
        {list &&
          list.length > 0 &&
          list.map((item, index) => {
            if (item.permission === true && !isAllowed()) return null;
            return (
              <ContainerItems
                key={index}
                item={item}
                // options={opts}
                handleDrawerToggle={handleDrawerToggle}
                mobileOpen={mobileOpen}
              />
            );
          })}

        {/* {list &&
          list.length > 0 &&
          list.map((item, index) => {
            if (isAllowed(userPermissions, item)) {
              let opts = null;
              if (item.id === 200) {
                opts = {
                  ...opts,
                  label: `${(informations && informations.points) || '0'} Pts`,
                  badge: false,
                };
              }
              return (
                <ContainerItems
                  key={index}
                  item={item}
                  options={opts}
                  handleDrawerToggle={handleDrawerToggle}
                  mobileOpen={mobileOpen}
                />
              );
            }
          })} */}
        {/* {list &&
          list.length > 0 &&
          Object.keys(permissions).length !== 0 &&
          list.map((item, index) => {
            switch (index) {
              case 6:
                if (permissions.forum) {
                  return <ContainerItems key={index} item={item} />;
                }
                break;
              case 4:
                if (permissions.exam) {
                  return <ContainerItems key={index} item={item} />;
                }
                break;
              default:
                return <ContainerItems key={index} item={item} />;
            }
          })} */}
      </ul>
    </div>
  );
};

Container.propTypes = {};

export default Container;
