import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import TelegramIcon from '@material-ui/icons/Telegram';
import * as dayjs from 'dayjs';

const TaskEvent = ({ id, open, handleClose, data }) => {
  let content;

  if (data) {
    const { task } = data;
    content = (
      <>
        <div className="badges">
          {task?.event?.active === true ? (
            <Chip label="Séance directe publiée" className="purple" />
          ) : (
            <Chip label="Séance directe non publiée" className="danger" />
          )}
          {dayjs(task?.event?.content_events?.start_date).isBefore(dayjs()) && (
            <Chip label="Séance directe terminée" className="primary" />
          )}
        </div>
        <span className="title">{task?.name}</span>
        <p className="description">{task?.description}</p>
        <Divider />
        <div className="split-childs">
          <div className="list">
            {task?.event?.content_events?.subject_events?.length > 0 && (
              <>
                <span className="label">Matières :</span>
                {task?.event?.content_events?.subject_events.map((item, index) => {
                  return (
                    <span key={index}>
                      <FiberManualRecordIcon />{' '}
                      {`${item.subject.division.name} - ${item.subject.name}`}
                    </span>
                  );
                })}
              </>
            )}
            {task?.event?.content_student_levels?.length > 0 && (
              <>
                <span className="label" style={{ marginTop: 6 }}>
                  Niveaux :
                </span>
                {task?.event?.content_student_levels.map((item, index) => {
                  return (
                    <span key={index}>
                      <FiberManualRecordIcon /> {item.student_level?.name}
                    </span>
                  );
                })}
              </>
            )}
          </div>
          <div className="list">
            <span className="label">Dates de la séance :</span>
            <span>
              <FiberManualRecordIcon /> Date de début:
              <span className="text-muted" style={{ marginLeft: 4 }}>
                {dayjs(task?.event?.content_events?.start_date).format('DD/MM/YYYY - HH:mm')}
              </span>
            </span>
            <span>
              <FiberManualRecordIcon /> Date de fin:
              <span className="text-muted" style={{ marginLeft: 4 }}>
                {dayjs(task?.event?.content_events?.end_date).format('DD/MM/YYYY - HH:mm')}
              </span>
            </span>
          </div>
        </div>
        <Divider />
        <TextField
          id="outlined-helperText"
          defaultValue={
            task?.event?.content_events?.url.includes('zoom')
              ? task?.event?.content_events?.url
              : 'https://global.gotowebinar.com/j_spring_security_logout'
          }
          variant="outlined"
          size="small"
          margin="dense"
          fullWidth
          disabled
          InputProps={{
            endAdornment: (
              <a
                className="link primary"
                href={
                  task?.event?.content_events?.url.includes('zoom')
                    ? task?.event?.content_events?.url
                    : 'https://global.gotowebinar.com/j_spring_security_logout'
                }
                target="_blank"
                rel="noreferrer"
              >
                <InputAdornment position="end">
                  <TelegramIcon />
                </InputAdornment>
              </a>
            ),
          }}
        />
        {!task?.event?.content_events?.url.includes('zoom') && (
          <>
            <span className="text-muted">
              <span className="strong">Email :</span> {task?.webinar_account?.email}
            </span>
            <span className="text-muted">
              <span className="strong">Mot de passe :</span> {task?.webinar_account?.password}
            </span>
          </>
        )}

        {/* <Divider />
        <span className="label">Travail à faire :</span>
        <div className="split-childs">
          <div className="list">
            <span className="strong">Ajouter des fichiers avant de la séance</span>
          </div>
          <div className="list">
            <span className="strong">Ajouter des fichiers après de la séance</span>
          </div>
        </div> */}
      </>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(id)}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="ta-modal add-video-modal"
    >
      <DialogTitle id="alert-dialog-title">
        <span className="label">Détails d'évènement</span>
        <span className="close-btn" onClick={() => handleClose(id)}>
          Fermer
        </span>
      </DialogTitle>
      <DialogContent className="ta-modal-content-scroll task-event-modal-container">
        {content}
      </DialogContent>
    </Dialog>
  );
};

TaskEvent.propTypes = {};

export default TaskEvent;
