import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../utilities/axios';
import { enqueueSnackbar } from './notifier';
import { closeModal } from './modals';
import Slide from '@material-ui/core/Slide';

const initialState = {
  classes: {
    data: [],
    status: 'idle',
    error: null,
  },
  user: {
    data: {},
    status: 'idle',
    error: null,
  },
};

export const fetchClasses = createAsyncThunk('switch/fetchClasses', async () => {
  let data;
  try {
    const response = await axios.get(`/instructor/users/to-login-as`);
    data = await response.data;
    if ((response.status = 200)) {
      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const fetchUser = createAsyncThunk('switch/fetchUser', async (userId) => {
  let data;
  try {
    const response = await axios.get(`/instructor/users/login-as/${userId}`);
    data = await response.data;
    if ((response.status = 200)) {
      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

const slice = createSlice({
  name: 'switch',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchClasses.pending]: (state, action) => {
      state.classes.status = 'loading';
    },
    [fetchClasses.fulfilled]: (state, action) => {
      state.classes.status = 'succeeded';
      state.classes.data = action.payload;
    },
    [fetchClasses.rejected]: (state, action) => {
      state.classes.status = 'failed';
      state.classes.error = action.payload;
    },
    [fetchUser.pending]: (state, action) => {
      state.user.status = 'loading';
    },
    [fetchUser.fulfilled]: (state, action) => {
      state.user.status = 'succeeded';
      state.user.data = action.payload;
    },
    [fetchUser.rejected]: (state, action) => {
      state.user.status = 'failed';
      state.user.error = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export default slice;
