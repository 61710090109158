import React, { useEffect, useState } from 'react';
import {
  VideoTitle,
  VideoPath,
  VideoContainer,
  ControlButton,
  ButtonGroupComponent,
  VideoInformation,
} from '../..';
import Skeleton from '@material-ui/lab/Skeleton';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import getDuration from '../../../utilities/getLessonsDuration';
import { fetchOneContent } from '../../../slices/contentsLikePupil';

const Index = (props) => {
  const dispatch = useDispatch();
  const { courses, isLoading, subjectSlug, chapterSlug, contentSlug, lessonId } = props;
  const lessons = courses && courses.contents;
  const { contentLikePupil } = useSelector((state) => state.contentsLikePupil);
  const currentLesson = contentLikePupil && contentLikePupil.data;
  const courseError = contentLikePupil && contentLikePupil.error;
  const coursesNumber =
    courses &&
    (courses.contents || []).filter(
      (lesson) => lesson?.data?.type?.id === 2 || lesson?.data?.type?.id === 1
    ).length;
  const magNumber =
    courses && (courses.contents || []).filter((lesson) => lesson?.data?.type?.id === 3).length;
  const firstLesson = (courses?.contents || []).find((lesson) => lesson?.data?.slug == lessonId);
  const chapterId = chapterSlug.split('-')[0];
  const [update, setUpdate] = useState(false);
  const duration = getDuration(lessons);
  const lessonData = { 'chapterSlug': chapterSlug, 'contentSlug': contentSlug };
  useEffect(() => {
    //if (contentLikePupil.status === 'idle') {
    dispatch(fetchOneContent(lessonData));
    setUpdate(false);
    //}
  }, [contentSlug, update]);
  return (
    <div className="video-view">
      {isLoading !== true ? (
        <div>
          <Grid className="container1" wrap="nowrap" spacing={2}>
            <Grid item className="div1">
              <VideoTitle lesson={firstLesson} />
              <VideoPath
                subject={courses.subject}
                lesson={courses.chapter}
                subjectSlug={subjectSlug}
              />
              <VideoContainer
                currentLesson={currentLesson}
                courseError={courseError}
                firstLesson={firstLesson}
                chapterId={chapterId}
                chapter={courses.chapter}
                lessons={lessons}
                subjectSlug={subjectSlug}
                chapterSlug={chapterSlug}
                lessonSlug={contentSlug}
                update={setUpdate}
              />
              <ControlButton
                  chapter={courses?.chapter}
                  data={currentLesson}
                  lessons={lessons}
                  subjectSlug={subjectSlug}
                  chapterSlug={chapterSlug}
                  lessonSlug={contentSlug}
                  update={setUpdate}
              />
            </Grid>
            <Grid item className="div2">
              <ButtonGroupComponent
                firstLesson={firstLesson}
                courseTabs={courses.tabs}
                courses={courses}
                subjectSlug={subjectSlug}
                chapterSlug={chapterSlug}
                lessonSlug={contentSlug}
              />
            </Grid>
          </Grid>
          <div>
            <VideoInformation
              //lesson={firstLesson}
              lesson={currentLesson}
              coursesNumber={coursesNumber}
              magNumber={magNumber}
              duration={duration}
            />
          </div>
        </div>
      ) : (
        <div>
          <Skeleton variant="rect" width={'100%'} height={500} />
        </div>
      )}
    </div>
  );
};

Index.propTypes = {
  courses: PropTypes.object,
  subjectSlug: PropTypes.any,
  chapterSlug: PropTypes.any,
  contentSlug: PropTypes.any,
  lessonId: PropTypes.any,
  isLoading: PropTypes.bool,
};

export default Index;
