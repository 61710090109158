import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import axios from '../utilities/axios';
import { enqueueSnackbar } from './notifier';
import Slide from '@material-ui/core/Slide';

const initialState = {
  tasks: [],
  status: 'idle',
  error: null,
};

export const fetchTasks = createAsyncThunk('tasks/fetchTasks', async () => {
  let data;
  try {
    const response = await axios.get(`/instructor/tasks`);
    data = await response.data;
    if ((response.status = 200)) {
      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const markTaskAsDone = createAsyncThunk(
  'tasks/markTaskAsDone',
  async ({ taskId }, thunkAPI) => {
    let data;
    try {
      const response = await axios.put(`/instructor/tasks/mark-as-done/${taskId}`);
      data = await response.data;
      if ((response.status = 200)) {
        // thunkAPI.dispatch(
        //   enqueueSnackbar({
        //     message: data.message,
        //     options: {
        //       key: new Date().getTime() + Math.random(),
        //       variant: 'success',
        //       anchorOrigin: {
        //         vertical: 'bottom',
        //         horizontal: 'center',
        //       },
        //       TransitionComponent: Slide,
        //     },
        //   })
        // );
        return data.payload;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const markTaskAsNotDone = createAsyncThunk(
  'tasks/markTaskAsNotDone',
  async ({ taskId }, thunkAPI) => {
    let data;
    try {
      const response = await axios.put(`/instructor/tasks/mark-as-not-done/${taskId}`);
      data = await response.data;
      if ((response.status = 200)) {
        // thunkAPI.dispatch(
        //   enqueueSnackbar({
        //     message: data.message,
        //     options: {
        //       key: new Date().getTime() + Math.random(),
        //       variant: 'success',
        //       anchorOrigin: {
        //         vertical: 'bottom',
        //         horizontal: 'center',
        //       },
        //       TransitionComponent: Slide,
        //     },
        //   })
        // );
        return data.payload;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const markTaskAsInProgress = createAsyncThunk(
  'tasks/markTaskAsInProgress',
  async ({ taskId }, thunkAPI) => {
    let data;
    try {
      const response = await axios.put(`/instructor/tasks/mark-as-in-progress/${taskId}`);
      data = await response.data;
      if ((response.status = 200)) {
        // thunkAPI.dispatch(
        //   enqueueSnackbar({
        //     message: data.message,
        //     options: {
        //       key: new Date().getTime() + Math.random(),
        //       variant: 'success',
        //       anchorOrigin: {
        //         vertical: 'bottom',
        //         horizontal: 'center',
        //       },
        //       TransitionComponent: Slide,
        //     },
        //   })
        // );
        return data.payload;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const markTaskAsSeen = createAsyncThunk(
  'tasks/markTaskAsSeen',
  async ({ taskId }, thunkAPI) => {
    let data;
    try {
      const response = await axios.put(`/instructor/tasks/mark-as-seen/${taskId}`);
      data = await response.data;
      if ((response.status = 200)) {
        // thunkAPI.dispatch(
        //   enqueueSnackbar({
        //     message: data.message,
        //     options: {
        //       key: new Date().getTime() + Math.random(),
        //       variant: 'success',
        //       anchorOrigin: {
        //         vertical: 'bottom',
        //         horizontal: 'center',
        //       },
        //       TransitionComponent: Slide,
        //     },
        //   })
        // );
        return data.payload;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchTasks.pending]: (state, action) => {
      state.status = 'loading';
    },
    [fetchTasks.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.tasks = action.payload;
    },
    [fetchTasks.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    [markTaskAsDone.fulfilled]: (state, action) => {
      let data = [];
      data = current(state).tasks.filter((item) => item.id !== action.payload.id);
      data.push(action.payload);
      state.tasks = data;
    },
    [markTaskAsNotDone.fulfilled]: (state, action) => {
      let data = [];
      data = current(state).tasks.filter((item) => item.id !== action.payload.id);
      data.push(action.payload);
      state.tasks = data;
    },
    [markTaskAsInProgress.fulfilled]: (state, action) => {
      let data = [];
      data = current(state).tasks.filter((item) => item.id !== action.payload.id);
      data.push(action.payload);
      state.tasks = data;
    },
    [markTaskAsSeen.fulfilled]: (state, action) => {
      let data = [];
      data = current(state).tasks.filter((item) => item.id !== action.payload.id);
      data.push(action.payload);
      state.tasks = data;
    },
  },
});

export const reducer = slice.reducer;

export default slice;
