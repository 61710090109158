import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../utilities/axios';

const initialState = {
  allDivisions: {
    data: [],
    status: 'idle',
    error: null,
  },
  allInstructors: {
    data: [],
    status: 'idle',
    error: null,
  },
  allContentTypes: {
    data: [],
    status: 'idle',
    error: null,
  },
  allChaptersTypes: {
    data: [],
    status: 'idle',
    error: null,
  },
  allContentScopes: {
    data: [],
    status: 'idle',
    error: null,
  },
  allStudentLevels: {
    data: [],
    status: 'idle',
    error: null,
  },
  chaptersByInstructor: {
    data: [],
    status: 'idle',
    error: null,
  },
  onglets: {
    data: [],
    status: 'idle',
    error: null,
  },
  instructorSubjects: {
    data: [],
    status: 'idle',
    error: null,
  },
};

export const fetchAllInstructors = createAsyncThunk('diverse/fetchAllInstructors', async () => {
  let data;
  try {
    const response = await axios.get('/instructor/all-instructors');
    data = await response.data;
    if ((response.status = 200)) {
      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const fetchAllContentTypes = createAsyncThunk('diverse/fetchAllContentTypes', async () => {
  let data;
  try {
    const response = await axios.get('/instructor/content-type');
    data = await response.data;
    if ((response.status = 200)) {
      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const fetchAllStudentLevels = createAsyncThunk('diverse/fetchAllStudentLevels', async () => {
  let data;
  try {
    const response = await axios.get('/instructor/student-levels');
    data = await response.data;
    if ((response.status = 200)) {
      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const fetchAllContentScopes = createAsyncThunk('diverse/fetchAllContentScopes', async () => {
  let data;
  try {
    const response = await axios.get('/instructor/content-scopes');
    data = await response.data;
    if ((response.status = 200)) {
      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const fetchChaptersByInstructor = createAsyncThunk(
  'diverse/fetchChaptersByInstructor',
  async ({ id }) => {
    let data;
    try {
      const response = await axios.get(`/instructor/${id}/subject-division-chapters`);
      data = await response.data;
      if ((response.status = 200)) {
        return data.payload;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const fetchOnglets = createAsyncThunk('diverse/fetchOnglets', async () => {
  let data;
  try {
    const response = await axios.get('/instructor/tabs');
    data = await response.data;
    if ((response.status = 200)) {
      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const fetchAllChaptersTypes = createAsyncThunk('diverse/fetchAllChaptersTypes', async () => {
  let data;
  try {
    const response = await axios.get('/instructor/chapters-type');
    data = await response.data;
    if ((response.status = 200)) {
      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const fetchInstructorSubjects = createAsyncThunk(
  'diverse/fetchInstructorSubjects',
  async () => {
    let data;
    try {
      const response = await axios.get('/instructor/subjects');
      data = await response.data;
      if ((response.status = 200)) {
        return data.payload;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: 'diverse',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAllInstructors.pending]: (state, action) => {
      state.allInstructors.status = 'loading';
    },
    [fetchAllInstructors.fulfilled]: (state, action) => {
      state.allInstructors.status = 'succeeded';
      state.allInstructors.data = action.payload
        .filter((item) => item.name !== null)
        .map((item) => {
          return { ...item, fullName: `${item.name} ${item.last_name}` };
        });
    },
    [fetchAllInstructors.rejected]: (state, action) => {
      state.allInstructors.status = 'failed';
      state.allInstructors.error = action.payload;
    },
    [fetchAllContentTypes.pending]: (state, action) => {
      state.allContentTypes.status = 'loading';
    },
    [fetchAllContentTypes.fulfilled]: (state, action) => {
      state.allContentTypes.status = 'succeeded';
      state.allContentTypes.data = action.payload.filter((item) => item.name !== null);
    },
    [fetchAllContentTypes.rejected]: (state, action) => {
      state.allContentTypes.status = 'failed';
      state.allContentTypes.error = action.payload;
    },
    [fetchAllStudentLevels.pending]: (state, action) => {
      state.allStudentLevels.status = 'loading';
    },
    [fetchAllStudentLevels.fulfilled]: (state, action) => {
      state.allStudentLevels.status = 'succeeded';
      state.allStudentLevels.data = action.payload.filter((item) => item.name !== null);
    },
    [fetchAllStudentLevels.rejected]: (state, action) => {
      state.allStudentLevels.status = 'failed';
      state.allStudentLevels.error = action.payload;
    },
    [fetchAllContentScopes.pending]: (state, action) => {
      state.allContentScopes.status = 'loading';
    },
    [fetchAllContentScopes.fulfilled]: (state, action) => {
      state.allContentScopes.status = 'succeeded';
      state.allContentScopes.data = action.payload.filter((item) => item.name !== null);
    },
    [fetchAllContentScopes.rejected]: (state, action) => {
      state.allContentScopes.status = 'failed';
      state.allContentScopes.error = action.payload;
    },
    [fetchChaptersByInstructor.pending]: (state, action) => {
      state.chaptersByInstructor.status = 'loading';
    },
    [fetchChaptersByInstructor.fulfilled]: (state, action) => {
      state.chaptersByInstructor.status = 'succeeded';
      state.chaptersByInstructor.data = action.payload;
    },
    [fetchChaptersByInstructor.rejected]: (state, action) => {
      state.chaptersByInstructor.status = 'failed';
      state.chaptersByInstructor.error = action.payload;
    },
    [fetchOnglets.pending]: (state, action) => {
      state.onglets.status = 'loading';
    },
    [fetchOnglets.fulfilled]: (state, action) => {
      state.onglets.status = 'succeeded';
      state.onglets.data = action.payload;
    },
    [fetchOnglets.rejected]: (state, action) => {
      state.onglets.status = 'failed';
      state.onglets.error = action.payload;
    },
    [fetchAllChaptersTypes.pending]: (state, action) => {
      state.allChaptersTypes.status = 'loading';
    },
    [fetchAllChaptersTypes.fulfilled]: (state, action) => {
      state.allChaptersTypes.status = 'succeeded';
      state.allChaptersTypes.data = action.payload;
    },
    [fetchAllChaptersTypes.rejected]: (state, action) => {
      state.allChaptersTypes.status = 'failed';
      state.allChaptersTypes.error = action.payload;
    },
    [fetchInstructorSubjects.pending]: (state, action) => {
      state.instructorSubjects.status = 'loading';
    },
    [fetchInstructorSubjects.fulfilled]: (state, action) => {
      state.instructorSubjects.status = 'succeeded';
      state.instructorSubjects.data = action.payload
        .map((item) => {
          return {
            id: item.id,
            name: `${item.name} | ${item.division.name}`,
            subject: {
              id: item.id,
              name: item.name,
              slug: item.slug,
              position: item.position,
            },
            division: {
              id: item.division.id,
              name: item.division.name,
              position: item.division.position,
            },
          };
        })
        .sort((a, b) => (a.division.position < b.division.position ? 1 : -1))
        .sort((a, b) => (a.position < b.position ? 1 : -1));
    },
    [fetchInstructorSubjects.rejected]: (state, action) => {
      state.instructorSubjects.status = 'failed';
      state.instructorSubjects.error = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export default slice;
