import { DialogTitle, Grid, Icon, Paper } from '@material-ui/core';
import React from 'react';
import ViewTitle from '../ViewTitle';
import useStyles from './style';
import Undo from '../../assets/img/icons/ArrowReply.svg';
import Close from '../../assets/img/icons/CloseX.svg';

const DialogTitleModal = ({ title, closeHandler, undoHandler, undo }) => {
  const classes = useStyles();
  return (
    <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
      <Grid container direction="row" justify="space-between" alignItems="center">
        <Paper elevation={0}>
          <ViewTitle title={title} />
        </Paper>
        <Paper elevation={0}>
          {undo && (
            <Icon onClick={undoHandler} className={classes.iconUndo}>
              <img src={Undo} alt="undo" className={classes.icon} />
            </Icon>
          )}
          <Icon onClick={closeHandler} className={classes.icon}>
            <img src={Close} alt="close" className={classes.icon} />
          </Icon>
        </Paper>
      </Grid>
    </DialogTitle>
  );
};

export default DialogTitleModal;
