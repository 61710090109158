import React, {useEffect, createRef} from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import {
  TextField,
  Box,
  FormHelperText,
  Typography,
  Switch,
  Select,
  MenuItem,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import {
  fetchAllInstructors,
  fetchAllContentTypes,
  fetchAllStudentLevels,
  fetchAllContentScopes,
  fetchChaptersByInstructor,
  fetchOnglets,
} from '../../slices/diverse';
import { addNewVideo, editVideo, deleteAttachmentsFromVideo } from '../../slices/videos';
import { deleteAttachmentsFromContent } from '../../slices/contents';
import { useSelector, useDispatch } from 'react-redux';
import VideocamIcon from '@material-ui/icons/Videocam';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteIcon from '@material-ui/icons/Delete';
import bytesToSize from '../../utilities/bytesToSize';
import { serialize } from 'object-to-formdata';
import ImPdf from '../../assets/img/icons/pdf.svg';
import ImVdo from '../../assets/img/icons/videofile.svg';
import { sliceLongStrings } from '../../utilities/methods';
import CircularProgress from '@material-ui/core/CircularProgress';

const dropzoneRef = createRef();
const dropzoneRef2 = createRef();

const videoInputRef = createRef();
const filesInputRef = createRef();

const openVideoInput = () => {
  // Note that the ref is set async,
  // so it might be null at some point
  if (videoInputRef.current) {
    videoInputRef.current.click();
  }
};

const openFilesInput = () => {
  // Note that the ref is set async,
  // so it might be null at some point
  if (filesInputRef.current) {
    filesInputRef.current.click();
  }
};

const formatChapterContents = (chapters, external, chapterSlug, selectedTab, chapterId, isVisible) => {
  if (chapters.length <= 0) {
    return undefined;
  }

  if (external == null) {

    return chapters.map((chapter) => {
      if (chapter.tab == null && chapter.section == null && chapter.position == null) {
        return {
          position: 0,
          chapter: chapter.id,
          tab: selectedTab,
          isVisible: chapterId === chapter.slug? isVisible : chapter?.isVisible,
        };
      }
      if (chapter.tab == null) {
        return {
          position: chapter.position,
          chapter: chapter.id,
          section: chapter.section.id,
          isVisible: chapterId === chapter.slug? isVisible : chapter?.isVisible,
        };
      }
      return {
        position: chapter.position,
        chapter: chapter.id,
        tab: chapter.tab.id,
        isVisible: chapterId === chapter.slug? isVisible : chapter?.isVisible,
      };
    });
  }

  let editedChapters = chapters.filter((chapter) => chapter.slug === chapterSlug);
  let restChapters = chapters.filter((chapter) => chapter.slug !== chapterSlug);

  editedChapters =
    editedChapters.length > 0
      ? editedChapters.map((chapter) => {
          if (chapter.tab == null && chapter.section.tab.id === selectedTab) {
            return {
              position: chapter.position,
              chapter: chapter.id,
              section: chapter.section.id,
              isVisible: chapterId === chapter.slug? isVisible : chapter?.isVisible,
            };
          }
          return {
            position: chapter.position,
            chapter: chapter.id,
            // tab: chapter.tab.id,
            tab: selectedTab,
            isVisible: chapterId === chapter.slug? isVisible : chapter?.isVisible,
          };
        })
      : [];

  restChapters =
    restChapters.length > 0
      ? restChapters.map((chapter) => {
          return {
            position: 0,
            chapter: chapter.id,
            tab: chapter?.tab?.id,
            section: chapter?.section?.id,
            isVisible: chapterId === chapter.slug? isVisible : chapter?.isVisible,
          };
        })
      : [];

  return [...editedChapters, ...restChapters];
};

const AddVideo = ({ id, open, handleClose, data, ...rest }) => {
  const {
    allInstructors,
    allContentTypes,
    allStudentLevels,
    allContentScopes,
    chaptersByInstructor,
    onglets,
  } = useSelector((state) => state.diverse);

  const { status,video } = useSelector(
    (state) => state.videos
  );
  const { isAdminTeacher } = useSelector(
      (state) => state.user
  );
  const dispatch = useDispatch();
  const isMountedRef = useIsMountedRef();
  const chapterIsVisible = data?.video?.chapters.filter((chapter) => chapter?.chapter?.slug === data?.chapterId)[0];
  useEffect(() => {
    if (allInstructors.status === 'idle' && open === true) {
      dispatch(fetchAllInstructors());
    }
    if (allContentTypes.status === 'idle' && open === true) {
      dispatch(fetchAllContentTypes());
    }
    if (allStudentLevels.status === 'idle' && open === true) {
      dispatch(fetchAllStudentLevels());
    }
    if (allContentScopes.status === 'idle' && open === true) {
      dispatch(fetchAllContentScopes());
    }
    if (onglets.status === 'idle' && open === true) {
      dispatch(fetchOnglets());
    }
  }, [
    open,
    allInstructors.status,
    allContentTypes.status,
    allStudentLevels.status,
    allContentScopes.status,
    onglets.status,
    dispatch,
  ]);
  return (
    <Dialog
      open={open}
      onClose={() => handleClose(id)}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="ta-modal add-video-modal"
    >
      <DialogTitle id="alert-dialog-title">
        <span className="label">
          {!data?.edit ? 'Ajouter une nouvelle vidéo' : 'Modifier la video'}
        </span>
        <span className="close-btn" onClick={() => handleClose(id)}>
          Annuler
        </span>
      </DialogTitle>
      <DialogContent className="ta-modal-content-scroll">
        <Formik
          initialValues={{
            title: !data?.edit ? '' : data?.video?.title,
            isPublic: !data?.edit ? false : data?.video?.is_active,
            isDraft: !data?.edit ? false : data?.video?.is_draft,
            description: !data?.edit ? '' : data?.video?.description || '',
            type: !data?.edit ? '' : data?.video?.type?.id,
            isVisible: !data?.edit ? false : chapterIsVisible?.is_visible,
            niveaux: !data?.edit
              ? []
              : data?.video?.levels.map((item) => {
                  return {
                    id: item?.student_level.id,
                    name: item?.student_level.name,
                  };
                }),
            scopes: !data?.edit ? null : data?.video?.scope,
            teachers: !data?.edit
              ? null
              : {
                  ...data?.video?.teacher,
                  fullName: `${data?.video?.teacher?.name} ${data?.video?.teacher?.last_name}`,
                },
            chapters: !data?.edit
              ? []
              : data?.video?.chapters.map((item) => {
                  return {
                    id: item?.chapter?.id,
                    name: `${item?.chapter?.subject?.name}|${item?.chapter?.subject?.division?.name}|${item?.chapter?.name}`,
                    tab: item?.tab,
                    section: item?.section,
                    slug: item?.chapter?.slug,
                    position: item?.position,
                    isVisible: item?.is_visible ? item.is_visible : false,

                  };
                }),
            onglet: !data?.edit ? '' : data?.video?.tab?.id || '',
            video: [],
            files: [],
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            title: Yup.string().max(255).required('Title is required'),
            isPublic: Yup.bool(),
            isDraft: Yup.bool(),
            description: Yup.string().max(255),
            type: Yup.string().max(255).required('Type is required'),
            niveaux: Yup.array(),
            scopes: Yup.object()
              .shape({ id: Yup.number() })
              .nullable()
              .required('Scope is required.'),
            teachers: Yup.object()
              .shape({ id: Yup.number() })
              .nullable()
              .required('Teacher is required.'),
            chapters: Yup.array().min(1, 'Chapters is required'),
            onglet: Yup.string().max(255).required('Onglet is required'),
            video: !data?.edit === true && Yup.array().min(1, 'A file is required'),
            files: Yup.array(),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              let dataToSend = {
                file:
                  values.video.length > 0
                    ? values.video.map((item) => {
                        return item;
                      })
                    : values.video,
                content: {
                  name: values.title,
                  description: values.description,
                  position: 0,
                  type: values.type,
                  scope: values.scopes.id,
                  instructor: values.teachers.id,
                  active: values.isPublic,
                  isDraft: values.isDraft,
                  isEventTitleApproved: false,
                  checkUserLevelRecording: false,
                  contentStudentLevels:
                    values.niveaux.length > 0
                      ? values.niveaux.map((item) => {
                          return { studentLevel: item?.id };
                        })
                      : values.niveaux,
                  chapterContents:
                    values.chapters.length > 0
                      ? values.chapters.map((item, index) => {
                          return {
                            position: 0,
                            chapter: item?.id,
                            tab: values.onglet,
                            isVisible: data?.chapterId === item.slug? values.isVisible : item?.is_visible
                          };
                        })
                      : values.chapters,
                  contentFiles:
                    values.files.length > 0
                      ? values.files.map((item, index) => {
                          let fileBinary = {
                            position: index,
                            file: {
                              name: item.name,
                              file: item,
                              attachment: true,
                              homework: false,
                              document: false,
                              solution: false,
                              marks: false,
                            },
                          };
                          return fileBinary;
                        })
                      : values.files,
                },
              };
              if (!data?.edit) {
                const formData = serialize(dataToSend, { indices: true });
                dispatch(addNewVideo({ values: formData }));
              } else {
                // get chapter here

                dataToSend = {
                  ...dataToSend,
                  file: values.video === [] ? undefined : dataToSend.file,
                  content: {
                    ...dataToSend.content,
                    chapterContents: formatChapterContents(
                      values.chapters,
                      data?.external,
                      data?.chapterId,
                      values.onglet,
                        data?.chapterId,
                        values.isVisible
                    ),
                    position: undefined,
                    contentFiles: values.files === [] ? undefined : dataToSend.content.contentFiles,
                  },
                };
                const formData = serialize(dataToSend, { indices: true });
                dispatch(
                  editVideo({
                    values: formData,
                    // videoId: data?.external
                    //   ? data?.video?.content_videos[0]?.id
                    //   : data?.video?.video[0]?.id,
                    videoId: data?.video?.video[0]?.id,
                    chapterSlug: data?.chapterId,
                    external: data?.external,
                  })
                );
              }

              if (isMountedRef.current) {
                setStatus({ success: true });
                setSubmitting(false);
              }
            } catch (err) {
              console.error(err);
              if (isMountedRef.current) {
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            resetForm,
            setFieldValue,
            setFieldTouched,
            setFieldError,
            setTouched,
          }) => (
            <form noValidate onSubmit={handleSubmit} className="ta-form" {...rest}>
              <Typography variant="body2" color="textSecondary" className="label">
                Titre*
              </Typography>
              <TextField
                className="input"
                error={Boolean(touched.title && errors.title)}
                fullWidth
                autoFocus
                helperText={touched.title && errors.title}
                // label="Title"
                margin="normal"
                name="title"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.title}
                variant="outlined"
                size="small"
              />
              <Typography variant="body2" color="textSecondary">
                Publié
              </Typography>
              <Switch
                checked={values.isPublic}
                color="primary"
                edge="start"
                name="isPublic"
                onChange={handleChange}
                value={values.isPublic}
              />
              <Typography variant="body2" color="textSecondary">
                Brouillon
              </Typography>
              <Switch
                checked={values.isDraft}
                color="primary"
                edge="start"
                name="isDraft"
                onChange={(e, value)=>{
                  setFieldValue("isDraft",value);
                  if (value===true){
                    setFieldValue("isPublic",!value);
                  }

              }
                 }
                value={values.isDraft}
              />
              <Typography variant="body2" color="textSecondary">
                Visible pour cette classe ?
              </Typography>
              <Switch
                  checked={values?.isVisible}
                  color="primary"
                  edge="start"
                  name="isVisible"
                  onChange={handleChange}
                  value={values.isVisible}
              />
              <Typography variant="body2" color="textSecondary">
                Description
              </Typography>
              <TextField
                className="input"
                error={Boolean(touched.description && errors.description)}
                fullWidth
                multiline
                rows={4}
                helperText={touched.description && errors.description}
                margin="normal"
                name="description"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.description}
                variant="outlined"
                size="small"
              />
              <Typography variant="body2" color="textSecondary">
                Type*
              </Typography>
              <Select
                className={touched.type && errors.type ? 'input size-sm' : 'input size-sm mb'}
                error={Boolean(touched.type && errors.type)}
                fullWidth
                margin="normal"
                name="type"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.type}
                variant="outlined"
              >
                {/* <MenuItem value="">
                  <em>None</em>
                </MenuItem> */}
                {allContentTypes.data &&
                  allContentTypes.data.length > 0 &&
                  allContentTypes.data.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
              {touched.type && errors.type && (
                <span className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense">
                  {errors.type}
                </span>
              )}
              <Typography variant="body2" color="textSecondary">
                Niveaux
              </Typography>
              <Autocomplete
                className={touched.niveaux && errors.niveaux ? 'input' : 'input mb'}
                name="niveaux"
                onBlur={() => setTouched({ niveaux: true })}
                multiple
                fullWidth
                size="small"
                onChange={(e, value) => setFieldValue('niveaux', value)}
                getOptionSelected={(option, value) => option.name === value.name}
                options={allStudentLevels.data}
                value={values.niveaux}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    helperText={touched.niveaux && errors.niveaux}
                    error={Boolean(touched.niveaux && errors.niveaux)}
                  />
                )}
              />
              <Typography variant="body2" color="textSecondary">
                Scope*
              </Typography>
              <Autocomplete
                className={touched.scopes && errors.scopes ? 'input' : 'input mb'}
                name="scopes"
                onBlur={() => setTouched({ scopes: true })}
                // multiple
                fullWidth
                size="small"
                onChange={(e, value) => setFieldValue('scopes', value)}
                getOptionSelected={(option, value) => option.name === value.name}
                options={allContentScopes.data}
                value={values.scopes}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    helperText={touched.scopes && errors.scopes}
                    error={Boolean(touched.scopes && errors.scopes)}
                  />
                )}
              />
              <Typography variant="body2" color="textSecondary">
                Liste des enseignants*
              </Typography>
              <Autocomplete
                className={touched.teachers && errors.teachers ? 'input' : 'input mb'}
                name="teachers"
                onBlur={() => setTouched({ teachers: true })}
                // multiple
                disabled={data?.external != null && !isAdminTeacher}
                fullWidth
                size="small"
                onChange={(e, value) => {
                  setFieldValue('teachers', value);
                  if (value !== null) {
                    dispatch(fetchChaptersByInstructor({ id: value.id }));
                  } else {
                    setFieldValue('chapters', []);
                  }
                }}
                getOptionSelected={(option, value) => option.fullName === value.fullName}
                options={allInstructors.data}
                value={values.teachers}
                getOptionLabel={(option) => option.fullName}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    helperText={touched.teachers && errors.teachers}
                    error={Boolean(touched.teachers && errors.teachers)}
                  />
                )}
              />
              <Typography variant="body2" color="textSecondary">
                Chapitres sélectionnés
              </Typography>
              <Autocomplete
                className={touched.chapters && errors.chapters ? 'input' : 'input mb'}
                name="chapters"
                onBlur={() => setTouched({ chapters: true })}
                multiple
                fullWidth
                size="small"
                onFocus={() => {
                  if (data?.edit && chaptersByInstructor.status === 'idle') {
                    dispatch(fetchChaptersByInstructor({ id: values?.teachers?.id }));
                  }
                }}
                onChange={(e, value) => setFieldValue('chapters', value)}
                getOptionSelected={(option, value) => option.name === value.name}
                options={chaptersByInstructor.data}
                value={values.chapters}
                getOptionLabel={(option) => option.name}
                disabled={(values.teachers === null || data?.external != null) && !isAdminTeacher }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    helperText={touched.chapters && errors.chapters}
                    error={Boolean(touched.chapters && errors.chapters)}
                  />
                )}
              />
              <Typography variant="body2" color="textSecondary">
                Choisir un onglet*
              </Typography>
              <Select
                className={touched.onglet && errors.onglet ? 'input size-sm' : 'input size-sm mb'}
                error={Boolean(touched.onglet && errors.onglet)}
                fullWidth
                margin="normal"
                name="onglet"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.onglet}
                variant="outlined"
              >
                {/* <MenuItem value="">
                  <em>None</em>
                </MenuItem> */}
                {onglets.data &&
                  onglets.data.length > 0 &&
                  onglets.data.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
              {touched.onglet && errors.onglet && (
                <span className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense">
                  {errors.onglet}
                </span>
              )}
              <Typography variant="body2" color="textSecondary">
                Fichier video
              </Typography>
              <section className={touched.video && errors.video ? 'dropzone error' : 'dropzone'}>
                <Button
                  className="ta-btn dark btn-rounded "
                  startIcon={<VideocamIcon />}
                  type="button"
                  onClick={openVideoInput}
                >
                  Choisir un fichier
                </Button>
                <div className="files-list">
                  {values.video && values.video.length > 0
                    ? `${values.video.length} ${
                        values.video.length > 1 ? `fichiers sélectionnés` : `fichier sélectionné`
                      } - ${bytesToSize(
                        values.video.reduce((accumulator, currentValue) => {
                          return accumulator + currentValue.size;
                        }, 0)
                      )}`
                    : `aucun fichier sélectionné`}
                </div>
                <input
                  type="file"
                  ref={videoInputRef}
                  style={{ display: 'none' }}
                  name="video"
                  onClick={() => {
                    setFieldTouched('video', true);
                  }}
                  onChange={(e) => {
                    setFieldValue('video', [...e.target.files]);
                  }}
                  accept="video/*"
                />
              </section>
              {touched.video && errors.video && (
                <span className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense">
                  {errors.video}
                </span>
              )}

              {data?.edit && data?.video?.video?.length > 0 && (
                <div className="files-container last-with-margin">
                  {data?.video?.video
                    .slice()
                    .sort((a, b) => (a.created_at < b.created_at ? 1 : -1))
                    .map((file, index) => {
                      return (
                        <div key={index} className="file">
                          <span className="title rounded">
                            <img alt="img" src={ImVdo} />
                            <span>{sliceLongStrings(file?.url, 42)}</span>
                          </span>
                        </div>
                      );
                    })}
                </div>
              )}

              <Typography variant="body2" color="textSecondary">
                Nouvelles pièces joints
              </Typography>
              <section className={touched.files && errors.files ? 'dropzone error' : 'dropzone'}>
                <Button
                  className="ta-btn dark btn-rounded "
                  startIcon={<AttachFileIcon />}
                  type="button"
                  onClick={openFilesInput}
                >
                  Choisir des fichiers
                </Button>
                <div className="files-list">
                  {values.files && values.files.length > 0
                    ? `${values.files.length} ${
                        values.files.length > 1 ? `fichiers sélectionnés` : `fichier sélectionné`
                      } - ${bytesToSize(
                        values.files.reduce((accumulator, currentValue) => {
                          return accumulator + currentValue.size;
                        }, 0)
                      )}`
                    : `aucun fichier sélectionné`}
                </div>
                <input
                  type="file"
                  ref={filesInputRef}
                  style={{ display: 'none' }}
                  name="files"
                  onClick={() => {
                    setFieldTouched('files', true);
                  }}
                  onChange={(e) => {
                    setFieldValue('files', [...e.target.files]);
                  }}
                  accept="application/pdf"
                  multiple
                />
              </section>
              {touched.files && errors.files && (
                <span className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense">
                  {errors.files}
                </span>
              )}

              {data?.edit && data?.video?.attachments?.length > 0 && (
                <div className="files-container">
                  {data?.video?.attachments
                    .slice()
                    .filter((file) => file?.file?.attachment === true)
                    .sort((a, b) => (a.created_at < b.created_at ? 1 : -1))
                    .map((file, index) => {
                      return (
                        <div key={index} className="file">
                          <a
                            className="title"
                            href={file?.file?.url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img alt="img" src={ImPdf} />
                            <span>{sliceLongStrings(file?.file?.name, 42)}</span>
                          </a>
                          <Button
                            startIcon={<DeleteIcon />}
                            className="ta-btn primary btn-rounded"
                            onClick={() => {
                              if (data?.external) {
                                dispatch(
                                  deleteAttachmentsFromContent({
                                    contentFileId: file.id,
                                    chapterSlug: data.chapterId,
                                    subjectSlug: data.video.chapters.find(
                                      (chapter) => chapter.chapter.slug === data.chapterId
                                    ).chapter.subject.slug,
                                    isMagazine: data?.magazine != null,
                                  })
                                );
                              } else {
                                dispatch(
                                  deleteAttachmentsFromVideo({
                                    contentFileId: file.id,
                                  })
                                );
                              }
                            }}
                          >
                            Supprimer
                          </Button>
                        </div>
                      );
                    })}
                </div>
              )}

              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
              <Box mt={2} className="ta-btns-group">
                <Button
                   disabled={isSubmitting || video.status === 'loading'}
                  type="submit"
                  className="ta-btn primary btn-rounded btn-lg"
                >
                 {video.status === 'loading' && <CircularProgress size={20} style={{ margin: 'auto auto' }} />}
                  {!data?.edit ? 'Ajouter' : 'Modifier'}
                </Button>
                <Button
                  disabled={isSubmitting}
                  type="reset"
                  className="ta-btn btn-rounded btn-lg"
                  onClick={resetForm}
                >
                  Vider les champs
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

AddVideo.propTypes = {};

export default AddVideo;
