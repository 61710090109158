import React from 'react';
import Fab from '@material-ui/core/Fab';
import LockRoundedIcon from '@material-ui/icons/LockRounded';
import { Link } from 'react-router-dom';

const Index = () => {
  return (
    <div className="locked-video">
      <Fab className="fabStyle" onClick={(e) => (window.location = '/offres')}>
        <LockRoundedIcon className="iconStyle" />
      </Fab>
      <p className="locked-text">Prêt à commencer à apprendre? Débloquez ce cours aujourd'hui.</p>
      <Link to="/offres" className="locked-warning">
        Déverrouillez l'accès complet
      </Link>
    </div>
  );
};

export default Index;
