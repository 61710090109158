import React, { useState, useRef, useLayoutEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';

const ResponsiveTable = ({ children }) => {
  const respoContEl = useRef(null);
  const tableContEl = useRef(null);
  const paginContEl = useRef(null);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tableContHeight, setTableContHeight] = useState(0);
  const [paginContHeight, setPaginContHeight] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const updateTableDemonsions = (taH, PaH) => {
    respoContEl.current.style.height = `${taH + PaH - 16}px`;
    // paginContEl.current.style.marginTop = `${taH}px`;
  };

  useLayoutEffect(() => {
    setTableContHeight(tableContEl.current.clientHeight);
    // setPaginContHeight(paginContEl.current.clientHeight);
    updateTableDemonsions(tableContHeight, paginContHeight);
  });

  return (
    <div className="table-responsive" ref={respoContEl}>
      <TableContainer className="table-container" ref={tableContEl} component={Paper}>
        <Table stickyHeader aria-label="sticky table">
          {children}
        </Table>
      </TableContainer>
      {/* <TablePagination
        ref={paginContEl}
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      /> */}
    </div>
  );
};

export default ResponsiveTable;
