/* 
    we create this function to fix data's structure returned by API 
    extract classes by subjects names. */
const extractClassesBySubjectsNames = (initialArray) => {
  // let res = subjects.reduce((acc, obj) => {
  //   const exist = acc.findIndex((item) => item.name === obj.name);
  //   if (exist >= 0) {
  //     acc[exist] = {
  //       id: exist,
  //       name: obj.name,
  //       thumbnail: obj.thumbnail,
  //       position: obj.position,
  //       classes: [
  //         ...acc[exist].classes,
  //         { id: obj.id, name: obj.division.name, position: obj.division.position },
  //       ],
  //     };
  //   } else {
  //     acc.push({
  //       id: acc.length,
  //       name: obj.name,
  //       thumbnail: obj.thumbnail,
  //       position: obj.position,
  //       classes: [{ id: obj.id, name: obj.division.name, position: obj.division.position }],
  //     });
  //   }
  //   return acc;
  // }, []);

  // return res;

  let resultArray = initialArray.reduce((cum, x) => {
    let foundIndex = cum.findIndex((a) => {
      return a.name.toUpperCase() == x.name.toUpperCase();
    });
    if (foundIndex != -1) {
      cum[foundIndex].classes.push({
        id: x.id,
        slug: x.slug,
        name: x.division.name,
        divisionPosition: x.division?.position,
        position: x.position,
        subject: `${x.name.charAt(0).toUpperCase()}${x.name.slice(1)}`,
      });
    } else {
      cum.push({
        id: cum.length + 1,
        name: `${x.name.charAt(0).toUpperCase()}${x.name.slice(1)}`,
        thumbnail: x.thumbnail,
        position: x.position,
        classes: [
          {
            id: x.id,
            slug: x.slug,
            name: x.division.name,
            divisionPosition: x.division?.position,
            position: x.position,
            subject: `${x.name.charAt(0).toUpperCase()}${x.name.slice(1)}`,
          },
        ],
      });
    }
    return cum;
  }, []);

  return resultArray;
};

export default extractClassesBySubjectsNames;
