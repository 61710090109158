import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Paper } from '@material-ui/core';

const useStyles = makeStyles({
  header: {
    backgroundColor: '#F3F3F3',
    color: '#8F8F8F',
    padding: '10px 0px 10px 10px',
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '16px',
    width: (param) => (param.fitContent ? 'fit-content' : `${100 / param.columns}%`),
  },
  table: {
    width: '100%',
    minWidth: (param) => param.minWidth,
  },
  container: {
    display: 'flex',
    flex: '1 1 auto',
    overflowX: 'auto',
  },
});

const TableParams = ({ headersRow, children, fitContent, minWidth }) => {
  const classes = useStyles({ columns: headersRow.length, fitContent, minWidth });

  return (
    <TableContainer component={Paper} className={classes.container} elevation={0}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {headersRow.map((cell, index) => (
              <TableCell key={index} align={cell.align} className={classes.header}>
                {cell.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableParams;
