import { combineReducers } from '@reduxjs/toolkit';
import { reducer as notifierReducer } from '../slices/notifier';
import { reducer as notificationReducer } from '../slices/notification';
import { reducer as modalsReducer } from '../slices/modals';
import { reducer as subjectsReducer } from '../slices/subjects';
import { reducer as chaptersReducer } from '../slices/chapters';
import { reducer as contentsReducer } from '../slices/contents';
import { reducer as videosReducer } from '../slices/videos';
import { reducer as magazinesReducer } from '../slices/magazines';
import { reducer as qcmReducer } from '../slices/qcm';
import { reducer as tasksReducer } from '../slices/tasks';
import { reducer as userReducer } from '../slices/user';
import { reducer as diverseReducer } from '../slices/diverse';
import { reducer as examsReducer } from '../slices/exams';
import { reducer as contentsLikePupilReducer } from '../slices/contentsLikePupil';
import { reducer as switchReducer } from '../slices/switch';
import { reducer as eventsReducer } from '../slices/events';
import { reducer as codeCouponReducer } from '../slices/codeCoupon';
import { reducer as searchReducer } from '../slices/search';
import { reducer as deleteLoadingReducer } from '../slices/deleteLoading';
import { reducer as incomesReducer } from '../slices/incomes';
import { reducer as authReducer } from '../slices/auth';
import { reducer as customContentsReducer } from '../slices/customContents';
import { reducer as sectionChapterReducer } from '../slices/sectionChapter';

const combinedReducer = combineReducers({
  notifier: notifierReducer,
  notifications: notificationReducer,
  modals: modalsReducer,
  subjects: subjectsReducer,
  chapters: chaptersReducer,
  contents: contentsReducer,
  videos: videosReducer,
  magazines: magazinesReducer,
  qcm: qcmReducer,
  tasks: tasksReducer,
  user: userReducer,
  diverse: diverseReducer,
  exams: examsReducer,
  contentsLikePupil: contentsLikePupilReducer,
  switch: switchReducer,
  events: eventsReducer,
  codeCoupon: codeCouponReducer,
  search: searchReducer,
  deleteLoading: deleteLoadingReducer,
  incomes: incomesReducer,
  customContents: customContentsReducer,
  auth: authReducer,
  sectionChapter: sectionChapterReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    // check for action type
    state = undefined;
  }
  return combinedReducer(state, action);
};

// const rootReducer = combineReducers({
//   notifier: notifierReducer,
//   notifications: notificationReducer,
//   modals: modalsReducer,
//   subjects: subjectsReducer,
//   chapters: chaptersReducer,
//   contents: contentsReducer,
//   videos: videosReducer,
//   magazines: magazinesReducer,
//   tasks: tasksReducer,
//   user: userReducer,
//   diverse: diverseReducer,
//   exams: examsReducer,
// });

export default rootReducer;
