import React, { useEffect, createRef } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { serialize } from 'object-to-formdata';
import { useSelector, useDispatch } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import DescriptionIcon from '@material-ui/icons/Description';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import DeleteIcon from '@material-ui/icons/Delete';
import ImPdf from '../../assets/img/icons/pdf.svg';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import { openModal } from '../../slices/modals';
import { sliceLongStrings } from '../../utilities/methods';
import bytesToSize from '../../utilities/bytesToSize';
import { fetchUser } from '../../slices/user';
import { addCorrectionToStudent, deleteCorrectionFromStudent } from '../../slices/exams';

const DOCUMENTS = [1, 2, 3, 4, 5, 6];

const filesInputRef = createRef();
const openFilesInput = () => {
  if (filesInputRef.current) {
    filesInputRef.current.click();
  }
};

const ExamTest = ({ id, open, handleClose, data, ...rest }) => {
  // console.log('data: ', data);
  const isMountedRef = useIsMountedRef();
  const dispatch = useDispatch();
  const { user, status, error } = useSelector((state) => state.user);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchUser());
    }
  }, [status, dispatch]);

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(id)}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="ta-modal add-video-modal"
    >
      <DialogTitle id="alert-dialog-title">
        <span className="label">{`Étudiant ${data?.user?.name} ${data?.user?.last_name}`}</span>
        <span
          className="close-btn"
          onClick={() => {
            handleClose(id);
            dispatch(
              openModal(data?.modal_id, {
                name: data?.modal_name,
                users: data.users,
                homeworks: data.homeworks,
                homeworkCorrections: data.homeworkCorrections,
                examId: data?.examId,
              })
            );
          }}
        >
          Fermer
        </span>
      </DialogTitle>
      <DialogContent className="ta-modal-content-scroll test-container">
        <span className="label">Essai de l'étudiant :</span>
        <div className="docs-container">
          {data?.user?.homeworks && data?.user?.homeworks?.length > 0 ? (
            data?.user?.homeworks.map((homework, index) => (
              <a
                href={homework?.file?.url}
                target="_blank"
                rel="noreferrer"
                key={index}
                className="doc"
              >
                {sliceLongStrings(homework?.file?.name, 42)} <SaveAltIcon />
              </a>
            ))
          ) : (
            <span className="description">Pas d'essais</span>
          )}
        </div>
        <span className="label">Correction de l'étudiant :</span>
        {/* <div className="docs-container">
          {data?.user?.corrections && data?.user?.corrections?.length > 0 ? (
            data?.user?.corrections.map((correction, index) => (
              <a
                href={correction?.file?.url}
                target="_blank"
                rel="noreferrer"
                key={index}
                className="doc"
              >
                {sliceLongStrings(correction?.file?.name, 42)} <SaveAltIcon />
              </a>
            ))
          ) : (
            <span className="description">Pas de corrections</span>
          )}
        </div> */}
        <div className="files-container exams">
          {data?.user?.corrections && data?.user?.corrections?.length > 0 ? (
            data?.user?.corrections.map((correction, index) => (
              <div key={index} className="file">
                <a className="title" href={correction?.file?.url} target="_blank" rel="noreferrer">
                  <img alt="img" src={ImPdf} />
                  <span>{sliceLongStrings(correction?.file?.name, 42)}</span>
                </a>
                <Button
                  startIcon={<DeleteIcon />}
                  className="ta-btn primary btn-rounded"
                  onClick={() =>
                    dispatch(
                      deleteCorrectionFromStudent({
                        contentFileId: correction.id,
                        examId: data?.examId,
                      })
                    )
                  }
                >
                  Supprimer
                </Button>
              </div>
            ))
          ) : (
            <span className="description">Pas de corrections</span>
          )}
        </div>
        <Formik
          initialValues={{
            files: [],
          }}
          validationSchema={Yup.object().shape({
            files: Yup.array(),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              const dataToSend = {
                contentFiles:
                  values.files.length > 0
                    ? values.files.map((item, index) => {
                        let fileBinary = {
                          position: index,
                          file: {
                            name: item.name,
                            file: item,
                            attachment: true,
                            homework: false,
                            document: false,
                            solution: false,
                            marks: false,
                            teacher: user?.id,
                            user: data?.user?.id,
                          },
                        };
                        return fileBinary;
                      })
                    : values.files,
              };
              const formData = serialize(dataToSend, { indices: true });
              // console.log(data);
              dispatch(
                addCorrectionToStudent({
                  values: formData,
                  examId: data?.examId,
                  studentId: data?.user?.id,
                })
              );

              if (isMountedRef.current) {
                setStatus({ success: true });
                setSubmitting(false);
              }
            } catch (err) {
              console.error(err);
              if (isMountedRef.current) {
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            resetForm,
            setFieldValue,
            setFieldTouched,
            setFieldError,
            setTouched,
          }) => (
            <form noValidate onSubmit={handleSubmit} className="ta-form" {...rest}>
              <Typography variant="body2" color="textSecondary">
                Ajouter corrections
              </Typography>
              <section
                className={
                  touched.files && errors.files ? 'dropzone event error' : 'dropzone event'
                }
              >
                <div>
                  <Button
                    className="ta-btn dark btn-rounded "
                    startIcon={<AttachFileIcon />}
                    type="button"
                    onClick={openFilesInput}
                  >
                    Choisir des fichiers
                  </Button>
                  <div className="files-list">
                    {values.files && values.files.length > 0
                      ? `${values.files.length} ${
                          values.files.length > 1 ? `fichiers sélectionnés` : `fichier sélectionné`
                        } - ${bytesToSize(
                          values.files.reduce((accumulator, currentValue) => {
                            return accumulator + currentValue.size;
                          }, 0)
                        )}`
                      : `aucun fichier sélectionné`}
                  </div>
                </div>
                {values.files.length > 0 && (
                  <Box className="ta-btns-group">
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      className="ta-btn primary btn-rounded only-text"
                    >
                      {/* <UploadFileIcon /> */}
                      Ajouter
                    </Button>
                    <Button
                      disabled={isSubmitting}
                      type="reset"
                      className="ta-btn btn-rounded only-icon"
                      onClick={resetForm}
                    >
                      <CloseIcon />
                    </Button>
                  </Box>
                )}
                <input
                  type="file"
                  ref={filesInputRef}
                  style={{ display: 'none' }}
                  name="files"
                  onClick={() => {
                    setFieldTouched('files', true);
                  }}
                  onChange={(e) => {
                    setFieldValue('files', [...e.target.files]);
                  }}
                  accept="application/pdf"
                  multiple
                />
              </section>
              {touched.files && errors.files && (
                <span className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense">
                  {errors.files}
                </span>
              )}

              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
            </form>
          )}
        </Formik>
        {/* <Button
          className="ta-btn dark btn-rounded btn-lg"
          startIcon={<DescriptionIcon fontSize="small" />}
        >
          Ajouter un fichier
        </Button>
        <hr />
        <Button
          className="ta-btn primary btn-rounded btn-lg"
          startIcon={<SaveIcon fontSize="small" />}
        >
          Enregistrer
        </Button> */}
      </DialogContent>
    </Dialog>
  );
};

ExamTest.propTypes = {};

export default ExamTest;
