import React, { useEffect, createRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { serialize } from 'object-to-formdata';
import Button from '@material-ui/core/Button';
import {
  TextField,
  Box,
  FormHelperText,
  Typography,
  Switch,
  Select,
  MenuItem,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteIcon from '@material-ui/icons/Delete';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import {
  fetchAllInstructors,
  fetchAllContentScopes,
  fetchChaptersByInstructor,
  fetchOnglets,
} from '../../slices/diverse';
import {
  addNewMagazine,
  editMagazine,
  deleteAttachmentsFromMagazine,
} from '../../slices/magazines';
import { deleteAttachmentsFromContent } from '../../slices/contents';
import bytesToSize from '../../utilities/bytesToSize';
import ImPdf from '../../assets/img/icons/pdf.svg';
import { sliceLongStrings } from '../../utilities/methods';
import CircularProgress from '@material-ui/core/CircularProgress';

const filesInputRef = createRef();

const openFilesInput = () => {
  // Note that the ref is set async,
  // so it might be null at some point
  if (filesInputRef.current) {
    filesInputRef.current.click();
  }
};

const AddMagazine = ({ id, open, handleClose, data, ...rest }) => {
  const { allInstructors, allContentScopes, chaptersByInstructor, onglets } = useSelector(
    (state) => state.diverse
  );
  const { status,magazine } = useSelector(
    (state) => state.magazines
  );

  const dispatch = useDispatch();
  const isMountedRef = useIsMountedRef();
  const chapterIsVisible = data?.magazine?.chapters.filter((chapter) => chapter?.chapter?.slug === data?.chapterId)[0];
  useEffect(() => {
    if (allInstructors.status === 'idle' && open === true) {
      dispatch(fetchAllInstructors());
    }
    if (allContentScopes.status === 'idle' && open === true) {
      dispatch(fetchAllContentScopes());
    }
    if (onglets.status === 'idle' && open === true) {
      dispatch(fetchOnglets());
    }
  }, [open, allInstructors.status, allContentScopes.status, onglets.status, dispatch]);

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(id)}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="ta-modal add-video-modal"
    >
      <DialogTitle id="alert-dialog-title">
        <span className="label">
          {!data?.edit ? 'Ajouter un nouveau magazine' : 'Modifier le magazine'}
        </span>
        <span className="close-btn" onClick={() => handleClose(id)}>
          Annuler
        </span>
      </DialogTitle>
      <DialogContent className="ta-modal-content-scroll">
        <Formik
          initialValues={{
            title: !data?.edit ? '' : data?.magazine?.title,
            isPublic: !data?.edit ? false : data?.magazine?.is_active,
            isDraft: !data?.edit ? false : data?.magazine?.is_draft,
            isRTL: !data?.edit ? false : data?.magazine?.is_rtl,
            isDownloadable: !data?.edit ? false : data?.magazine?.is_downloadable,
            description: !data?.edit ? '' : data?.magazine?.description || '',
            isVisible: !data?.edit ? false : chapterIsVisible?.is_visible,
            scopes: !data?.edit ? null : data?.magazine?.scope,
            teachers: !data?.edit
              ? null
              : {
                  ...data?.magazine?.teacher,
                  fullName: `${data?.magazine?.teacher?.name} ${data?.magazine?.teacher?.last_name}`,
                },
            chapters: !data?.edit
              ? []
              : data?.magazine?.chapters.map((item) => {
                  return {
                    id: item?.chapter?.id,
                    name: `${item?.chapter?.subject?.name}|${item?.chapter?.subject?.division?.name}|${item?.chapter?.name}`,
                    tab: item?.tab,
                    section: item?.section,
                    slug: item?.chapter?.slug,
                    position: item?.position,
                    isVisible: item?.is_visible ? item.is_visible : false,
                  };
                }),
            onglet: !data?.edit ? '' : data?.magazine?.tab?.id || '',
            files: [],
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            title: Yup.string().max(255).required('Title is required'),
            isPublic: Yup.bool(),
            isDraft: Yup.bool(),
            isRTL: Yup.bool(),
            isDownloadable: Yup.bool(),
            description: Yup.string().max(255),
            scopes: Yup.object()
              .shape({ id: Yup.number() })
              .nullable()
              .required('Scope is required.'),
            teachers: Yup.object()
              .shape({ id: Yup.number() })
              .nullable()
              .required('Teacher is required.'),
            chapters: Yup.array(),
            onglet: Yup.string().max(255),
            files: !data?.edit === true && Yup.array().min(1, 'A file is required'),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              let dataToSend = {
                name: values.title,
                description: values.description,
                active: values.isPublic,
                isDraft: values.isDraft,
                type: values.type,
                scope: values.scopes.id,
                instructor: values.teachers.id,
                position: 0,
                rtl: values.isRTL,
                downloadable: values.isDownloadable,
                chapterContents:
                  values.chapters.length > 0
                    ? values.chapters.map((item, index) => {
                        return {
                          isVisible: values.isVisible,
                          position:
                              data?.magazine?.chapters?.find(
                                  (chapter) => chapter?.chapter?.id === item?.id
                              )?.position || 0,
                          chapter: item?.id,
                          tab: values.onglet,
                          section: item?.section?.id
                        };
                      })
                    : values.chapters,
                contentFiles:
                  values.files.length > 0
                    ? values.files.map((file, index) => {
                        return {
                          position: index,
                          file: {
                            name: file.name,
                            file,
                            attachment: false,
                            homework: false,
                            document: false,
                            solution: false,
                            marks: false,
                          },
                        };
                      })
                    : values.files,
              };
              if (!data?.edit) {
                const formData = serialize(dataToSend, { indices: true });
                dispatch(addNewMagazine({ values: formData }));
              } else {
                // get chapter here
                dataToSend = {
                  ...dataToSend,
                  // chapterContents: formatChapterContents(
                  //   values.chapters,
                  //   data?.external,
                  //   data?.chapterId,
                  //   values.onglet
                  // ),
                  // position: data?.magazine?.position,
                  position: undefined,
                  contentFiles: values.files === [] ? undefined : dataToSend.contentFiles,
                };
                const formData = serialize(dataToSend, { indices: true });
                dispatch(
                  editMagazine({
                    values: formData,
                    magazineId: data?.magazine?.id,
                    chapterSlug: data?.chapterId,
                    chapterId: data?.chapterIdd,
                    external: data?.external,
                  })
                );
              }

              if (isMountedRef.current) {
                setStatus({ success: true });
                setSubmitting(false);
              }
            } catch (err) {
              console.error(err);
              if (isMountedRef.current) {
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            isValidating,
            touched,
            values,
            resetForm,
            setFieldValue,
            setFieldTouched,
            setTouched,
          }) => (
            <form noValidate onSubmit={handleSubmit} className="ta-form" {...rest}>
              <Typography variant="body2" color="textSecondary" className="label">
                Titre*
              </Typography>
              <TextField
                className="input"
                error={Boolean(touched.title && errors.title)}
                fullWidth
                autoFocus
                helperText={touched.title && errors.title}
                // label="Title"
                margin="normal"
                name="title"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.title}
                variant="outlined"
                size="small"
              />
              <Typography variant="body2" color="textSecondary">
                Publié*
              </Typography>
              <Switch
                checked={values.isPublic}
                color="primary"
                edge="start"
                name="isPublic"
                onChange={handleChange}
                value={values.isPublic}
              />
              <Typography variant="body2" color="textSecondary">
                Brouillon
              </Typography>
              <Switch
                checked={values.isDraft}
                color="primary"
                edge="start"
                name="isDraft"
                onChange={(e, value)=>{
                  setFieldValue("isDraft",value);
                  if (value===true){
                    setFieldValue("isPublic",!value);
                  } 

              }
                 }
                value={values.isDraft}
              />
              <Typography variant="body2" color="textSecondary">
                Arabe*
              </Typography>
              <Switch
                checked={values.isRTL}
                color="primary"
                edge="start"
                name="isRTL"
                onChange={handleChange}
                value={values.isRTL}
              />
              <Typography variant="body2" color="textSecondary">
                Téléchargeable*
              </Typography>
              <Switch
                checked={values.isDownloadable}
                color="primary"
                edge="start"
                name="isDownloadable"
                onChange={handleChange}
                value={values.isDownloadable}
              />
              <Typography variant="body2" color="textSecondary">
                Visible pour cette classe ?
              </Typography>
              <Switch
                  checked={values?.isVisible}
                  color="primary"
                  edge="start"
                  name="isVisible"
                  onChange={handleChange}
                  value={values.isVisible}
              />
              <Typography variant="body2" color="textSecondary">
                Description
              </Typography>
              <TextField
                className="input"
                error={Boolean(touched.description && errors.description)}
                fullWidth
                multiline
                rows={4}
                helperText={touched.description && errors.description}
                margin="normal"
                name="description"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.description}
                variant="outlined"
                size="small"
              />
              <Typography variant="body2" color="textSecondary">
                Scope*
              </Typography>
              <Autocomplete
                className={touched.scopes && errors.scopes ? 'input' : 'input mb'}
                name="scopes"
                onBlur={() => setTouched({ scopes: true })}
                // multiple
                fullWidth
                size="small"
                onChange={(e, value) => setFieldValue('scopes', value)}
                getOptionSelected={(option, value) => option.name === value.name}
                options={allContentScopes.data}
                value={values.scopes}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    helperText={touched.scopes && errors.scopes}
                    error={Boolean(touched.scopes && errors.scopes)}
                  />
                )}
              />
              <Typography variant="body2" color="textSecondary">
                Liste des enseignants*
              </Typography>
              <Autocomplete
                className={touched.teachers && errors.teachers ? 'input' : 'input mb'}
                name="teachers"
                onBlur={() => setTouched({ teachers: true })}
                // multiple
                disabled={data?.external != null}
                fullWidth
                size="small"
                onChange={(e, value) => {
                  setFieldValue('teachers', value);
                  if (value !== null) {
                    dispatch(fetchChaptersByInstructor({ id: value.id }));
                  } else {
                    setFieldValue('chapters', []);
                  }
                }}
                getOptionSelected={(option, value) => option.fullName === value.fullName}
                options={allInstructors.data}
                value={values.teachers}
                getOptionLabel={(option) => option.fullName}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    helperText={touched.teachers && errors.teachers}
                    error={Boolean(touched.teachers && errors.teachers)}
                  />
                )}
              />
              <Typography variant="body2" color="textSecondary">
                Chapitres sélectionnés
              </Typography>
              <Autocomplete
                className={touched.chapters && errors.chapters ? 'input' : 'input mb'}
                name="chapters"
                onBlur={() => setTouched({ chapters: true })}
                multiple
                fullWidth
                size="small"
                onFocus={() => {
                  if (data?.edit && chaptersByInstructor.status === 'idle') {
                    dispatch(fetchChaptersByInstructor({ id: values?.teachers?.id }));
                  }
                }}
                onChange={(e, value) => setFieldValue('chapters', value)}
                getOptionSelected={(option, value) => option.name === value.name}
                options={chaptersByInstructor.data}
                value={values.chapters}
                getOptionLabel={(option) => option.name}
                disabled={values.teachers === null || data?.external != null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    helperText={touched.chapters && errors.chapters}
                    error={Boolean(touched.chapters && errors.chapters)}
                  />
                )}
              />
              <Typography variant="body2" color="textSecondary">
                Choisir un onglet*
              </Typography>
              <Select
                className={touched.onglet && errors.onglet ? 'input size-sm' : 'input size-sm mb'}
                error={Boolean(touched.onglet && errors.onglet)}
                fullWidth
                margin="normal"
                name="onglet"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.onglet}
                variant="outlined"
              >
                {/* <MenuItem value="">
                  <em>None</em>
                </MenuItem> */}
                {onglets.data &&
                  onglets.data.length > 0 &&
                  onglets.data.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
              {touched.onglet && errors.onglet && (
                <span className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense">
                  {errors.onglet}
                </span>
              )}
              <Typography variant="body2" color="textSecondary">
                Nouvelles pièces jointes
              </Typography>
              <section className={touched.files && errors.files ? 'dropzone error' : 'dropzone'}>
                <Button
                  className="ta-btn dark btn-rounded "
                  startIcon={<AttachFileIcon />}
                  type="button"
                  onClick={openFilesInput}
                >
                  Choisir des fichiers
                </Button>
                <div className="files-list">
                  {values.files && values.files.length > 0
                    ? `${values.files.length} ${
                        values.files.length > 1 ? `fichiers sélectionnés` : `fichier sélectionné`
                      } - ${bytesToSize(
                        values.files.reduce((accumulator, currentValue) => {
                          return accumulator + currentValue.size;
                        }, 0)
                      )}`
                    : `aucun fichier sélectionné`}
                </div>
                <input
                  type="file"
                  ref={filesInputRef}
                  style={{ display: 'none' }}
                  name="files"
                  onClick={() => {
                    setFieldTouched('files', true);
                  }}
                  onChange={(e) => {
                    setFieldValue('files', [...e.target.files]);
                  }}
                  accept="application/pdf"
                  multiple
                />
              </section>
              {touched.files && errors.files && (
                <span className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense">
                  {errors.files}
                </span>
              )}

              {data?.edit && data?.magazine?.magazine?.length > 0 && (
                <div className="files-container">
                  {data?.magazine?.magazine
                    .slice()
                    .filter((file) => file?.file?.attachment === true)
                    .sort((a, b) => (a.created_at < b.created_at ? 1 : -1))
                    .map((file, index) => {
                      return (
                        <div key={index} className="file">
                          <a
                            className="title"
                            href={file?.file?.url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img alt="img" src={ImPdf} />
                            <span>{sliceLongStrings(file?.file?.name, 42)}</span>
                          </a>
                          <Button
                            startIcon={<DeleteIcon />}
                            className="ta-btn primary btn-rounded"
                            onClick={() => {
                              if (data?.external) {
                                dispatch(
                                  deleteAttachmentsFromContent({
                                    contentFileId: file.id,
                                    chapterSlug: data.chapterId,
                                    subjectSlug: data.magazine.chapters.find(
                                      (chapter) => chapter.chapter.id === data.chapterIdd
                                    ).chapter.subject.slug,
                                    isMagazine: data?.magazine != null,
                                  })
                                );
                              } else {
                                dispatch(
                                  deleteAttachmentsFromMagazine({
                                    contentFileId: file.id,
                                  })
                                );
                              }
                            }}
                          >
                            Supprimer
                          </Button>
                        </div>
                      );
                    })}
                </div>
              )}

              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
              <Box mt={2} className="ta-btns-group">
                <Button
                  disabled={isSubmitting || magazine.status === 'loading'}
                  type="submit"
                  className="ta-btn primary btn-rounded btn-lg"
                 >  
                   {magazine.status === 'loading' && <CircularProgress size={20} style={{ margin: '0 auto' }} />}
                   {!data?.edit ? 'Ajouter' : 'Modifier'}
                </Button>                   
                <Button
                  disabled={isSubmitting}
                  type="reset"
                  className="ta-btn btn-rounded btn-lg"
                  onClick={resetForm}
                >
                  Vider les champs
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

AddMagazine.propTypes = {};

export default AddMagazine;
