import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../utilities/axios';

const initialState = {
  search: [],
  status: 'idle',
  error: null,
};

export const getSearch = createAsyncThunk('search/getSearch', async (term) => {
  let data;
  try {
    const response = await axios.post(`/instructor/search/autocomplete`, { term });
    data = await response.data;
    if ((response.status = 200)) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

const slice = createSlice({
  name: 'search',
  initialState,
  reducers: {},
  extraReducers: {
    [getSearch.pending]: (state, action) => {
      state.status = 'loading';
    },
    [getSearch.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.search = action.payload;
    },
    [getSearch.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export default slice;
