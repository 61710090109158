import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { ContentTable } from '../..';
import PropTypes from 'prop-types';
import { getRecordingContents } from "../../../slices/customContents";

const Index = (props) => {
  const { courseTabs, courses, subjectSlug, chapterSlug, lessonSlug, firstLesson } = props;
  const initialTab = (courseTabs || []).find((element, index) => index === 0);
  const [firstTab, setFirstTab] = useState(initialTab)
  const currentTab = firstLesson && firstLesson.tab_id;
  const tab = (courseTabs || []).filter((tab) => tab?.id?.toString() === currentTab?.toString())[0];
  const name = tab && tab.name;
  const isActive = {
    isactive: name ? name : 'Cours',
    tab: firstTab,
  };
  const [isactive, setIsActive] = useState(isActive || {});
  const handleClick = (index, item) => {
    setIsActive({ isactive: item.name, tab: item });
  };
  useEffect(() => {
    const currentTab = (courseTabs || []).find((element, index) => index === 0);
    setFirstTab(tab);
    const isActive = {
      isactive: tab?.name,
      tab: tab,
    };
    setIsActive(isActive);

  }, [courseTabs]);

  return (
    <div className="root">
      <ButtonGroup className="group">
        {(courseTabs || []).slice()
          .sort((a, b) => (parseInt(a.position) > parseInt(b.position) ? 1 : -1))
          .map((tab, index) => (
            <Button
              className="title"
              onClick={() => handleClick(index, tab)}
              style={
                isactive?.isactive === tab.name ? { background: '#2ba7df', color: '#ffffff' } : {}
              }
            >
              {tab.name}
            </Button>
          ))}
      </ButtonGroup>
      <div style={{ width: '100%', marginTop: 5 }}>
        {
          firstTab &&
          <ContentTable
            courses={courses}
            subjectSlug={subjectSlug}
            chapterSlug={chapterSlug}
            lessonSlug={lessonSlug}
            tab={isactive ? isactive.tab : firstTab}
          />
        }

      </div>
    </div>
  );
};

Index.propTypes = {
  courseTabs: PropTypes.object,
  courses: PropTypes.object,
  subjectSlug: PropTypes.any,
  chapterSlug: PropTypes.any,
  lessonSlug: PropTypes.any,
};

export default Index;
