import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  modals: [
    {
      id: 'add-content-modal',
      open: false,
    },
    {
      id: 'add-video-modal',
      open: false,
    },
    {
      id: 'add-magazine-modal',
      open: false,
    },
    {
      id: 'add-qcm-modal',
      open: false,
    },
    {
      id: 'add-exam-modal',
      open: false,
    },
    {
      id: 'add-chapter-modal',
      open: false,
    },
    {
      id: 'exam-tests-modal',
      open: false,
    },
    {
      id: 'exam-test-modal',
      open: false,
    },
    {
      id: 'exam-add-file-modal',
      open: false,
    },
    {
      id: 'switch-classes-modal',
      open: false,
    },
    {
      id: 'switch-users-modal',
      open: false,
    },
    {
      id: 'suggestion-modal',
      open: false,
    },
    {
      id: 'task-modal',
      open: false,
    },
    {
      id: 'task-event-modal',
      open: false,
    },
    {
      id: 'edit-email-modal',
      open: false,
    },
    {
      id: 'edit-password-modal',
      open: false,
    },
    {
      id: 'add-section-modal',
      open: false,
    },
    {
      id: 'event-modal',
      open: false,
    },
    {
      id: 'delete-modal',
      open: false,
    },
    {
      id: 'month-incomes-modal',
      open: false,
    },
    {
      id: 'month-details-direct-modal',
      open: false,
    },
    {
      id: 'month-details-defaults-modal',
      open: false,
    },
    {
      id: 'benefits-history-modal',
      open: false,
    },
    {
      id: 'add-chapter-section-modal',
      open: false,
    },
  ],
};

const slice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    openModal: (state, action) => {
      const { id, data } = action.payload;
      const index = state.modals.findIndex((modal) => modal.id === id);
      state.modals[index].open = true;
      state.modals[index].data = data;
    },
    closeModal: (state, action) => {
      const id = action.payload;
      const index = state.modals.findIndex((modal) => modal.id === id);
      state.modals[index].open = false;
    },
  },
});

export const reducer = slice.reducer;

export const openModal = (id, data) => (dispatch) => {
  dispatch(slice.actions.openModal({ id, data }));
};

export const closeModal = (id) => (dispatch) => {
  dispatch(slice.actions.closeModal(id));
};

export default slice;
