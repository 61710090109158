import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../utilities/axios';
import { enqueueSnackbar } from './notifier';
import { closeModal } from './modals';
import { addChapterSection, removeChapterSection, fetchChapters } from './chapters';
import Slide from '@material-ui/core/Slide';
import { toggleLoadingTrue, toggleLoadingFalse } from './deleteLoading';

const initialState = {
  chapterSections: [],
  status: 'idle',
  error: null,
};

export const addNewChapterSection = createAsyncThunk('sectionChapter/addNewChapterSection', async (query, thunkAPI) => {
  const { values, subjectSlug } = query;
  let data;
  try {
    const response = await axios.post(`/instructor/chapter-sections`, values);
    data = await response.data;
    if ((response.status = 200)) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: data.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      //thunkAPI.dispatch(addChapterSection(data.payload, false));
      thunkAPI.dispatch(closeModal('add-chapter-section-modal'));
      thunkAPI.dispatch(fetchChapters(subjectSlug));
      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const editChapterSection = createAsyncThunk('sectionChapter/editChapterSection', async (query, thunkAPI) => {
  const { values, section_id, subjectSlug } = query;
  let data;
  try {
    const response = await axios.put(`/instructor/chapter-sections/${section_id}`, values);
    data = await response.data;
    if ((response.status = 200)) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: data.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
     // thunkAPI.dispatch(addChapterSection(data.payload, true));
      thunkAPI.dispatch(closeModal('add-chapter-section-modal'));
      //to verify after with method addChapterSection
      thunkAPI.dispatch(fetchChapters(subjectSlug));

      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const deleteChapterSection = createAsyncThunk('sectionChapter/deleteChapterSection', async (query, thunkAPI) => {
  const { chapterId, sectionId, sectionPosition, chapterSlug } = query;
  let data;
  try {
    thunkAPI.dispatch(toggleLoadingTrue());
    const response = await axios.delete(`/instructor/chapter-sections/${sectionId}`);
    data = await response.data;
    if (response.status === 200) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: data.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      thunkAPI.dispatch(removeChapterSection(sectionId, sectionPosition));
      thunkAPI.dispatch(closeModal('delete-modal'));
      thunkAPI.dispatch(toggleLoadingFalse());
      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    thunkAPI.dispatch(toggleLoadingFalse());
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

const slice = createSlice({
  name: 'sectionChapter',
  initialState,
  reducers: {},
  extraReducers: {
    [deleteChapterSection.fulfilled]: (state, action) => {
      const data = action.payload;   
    },
    [deleteChapterSection.pending]: (state, action) => {
      state.status = 'loading';  
    },
    [deleteChapterSection.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export default slice;
