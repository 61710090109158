import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  AddContentModal,
  AddVideoModal,
  AddMagazineModal,
  AddQcmModal,
  AddExamModal,
  AddChapterModal,
  ExamTestsModal,
  ExamTestModal,
  ExamAddFileModal,
  SwitchClassesModal,
  SwitchUsersModal,
  SuggestionModal,
  TaskModal,
  TaskEventModal,
  EditEmailModal,
  EditPasswordModal,
  AddSectionModal,
  EventModal,
  DeleteConfiramtionModal,
  MonthIncomesModal,
  MonthDetailsDirectModal,
  MonthDetailsDefaultsModal,
  BenefitsHistoryModal,
    AddChapterSectionModal,

} from '../Modals';
import { closeModal } from '../../slices/modals';
import useAuth from '../../hooks/useAuth';

const ModalsProvider = (props) => {
  const { isAuthenticated } = useAuth();
  const { modals } = useSelector((state) => state.modals);
  const dispatch = useDispatch();
  const modalState = (id, key) => {
    const res = modals.find((modal) => modal.id === id);
    return res[key];
  };
  const handleClose = (id) => {
    dispatch(closeModal(id));
  };
  return isAuthenticated ? (
    <>
      <AddContentModal
        id="add-content-modal"
        open={modalState('add-content-modal', 'open')}
        handleClose={handleClose}
      />
      <AddVideoModal
        id="add-video-modal"
        open={modalState('add-video-modal', 'open')}
        data={modalState('add-video-modal', 'data')}
        handleClose={handleClose}
      />
      <AddMagazineModal
        id="add-magazine-modal"
        open={modalState('add-magazine-modal', 'open')}
        data={modalState('add-magazine-modal', 'data')}
        handleClose={handleClose}
      />
      <AddQcmModal
        id="add-qcm-modal"
        open={modalState('add-qcm-modal', 'open')}
        data={modalState('add-qcm-modal', 'data')}
        handleClose={handleClose}
      />
      <AddExamModal
        id="add-exam-modal"
        open={modalState('add-exam-modal', 'open')}
        handleClose={handleClose}
      />
      <AddChapterModal
        id="add-chapter-modal"
        open={modalState('add-chapter-modal', 'open')}
        data={modalState('add-chapter-modal', 'data')}
        handleClose={handleClose}
      />
      <ExamTestsModal
        id="exam-tests-modal"
        open={modalState('exam-tests-modal', 'open')}
        data={modalState('exam-tests-modal', 'data')}
        handleClose={handleClose}
      />
      <ExamTestModal
        id="exam-test-modal"
        open={modalState('exam-test-modal', 'open')}
        data={modalState('exam-test-modal', 'data')}
        handleClose={handleClose}
      />
      <ExamAddFileModal
        id="exam-add-file-modal"
        open={modalState('exam-add-file-modal', 'open')}
        handleClose={handleClose}
      />
      <SwitchClassesModal
        id="switch-classes-modal"
        open={modalState('switch-classes-modal', 'open')}
        handleClose={handleClose}
      />
      <SwitchUsersModal
        id="switch-users-modal"
        open={modalState('switch-users-modal', 'open')}
        data={modalState('switch-users-modal', 'data')}
        handleClose={handleClose}
      />
      <SuggestionModal
        id="suggestion-modal"
        open={modalState('suggestion-modal', 'open')}
        data={modalState('suggestion-modal', 'data')}
        handleClose={handleClose}
      />
      <TaskModal
        id="task-modal"
        open={modalState('task-modal', 'open')}
        data={modalState('task-modal', 'data')}
        handleClose={handleClose}
      />
      <TaskEventModal
        id="task-event-modal"
        open={modalState('task-event-modal', 'open')}
        data={modalState('task-event-modal', 'data')}
        handleClose={handleClose}
      />
      <EditEmailModal
        id="edit-email-modal"
        open={modalState('edit-email-modal', 'open')}
        handleClose={handleClose}
      />
      <EditPasswordModal
        id="edit-password-modal"
        open={modalState('edit-password-modal', 'open')}
        handleClose={handleClose}
      />
      <AddSectionModal
        id="add-section-modal"
        open={modalState('add-section-modal', 'open')}
        data={modalState('add-section-modal', 'data')}
        handleClose={handleClose}
      />
        <AddChapterSectionModal
            id="add-chapter-section-modal"
            open={modalState('add-chapter-section-modal', 'open')}
            data={modalState('add-chapter-section-modal', 'data')}
            handleClose={handleClose}
        />
      <EventModal
        id="event-modal"
        open={modalState('event-modal', 'open')}
        data={modalState('event-modal', 'data')}
        handleClose={handleClose}
      />
      <DeleteConfiramtionModal
        id="delete-modal"
        open={modalState('delete-modal', 'open')}
        data={modalState('delete-modal', 'data')}
        handleClose={handleClose}
      />

      <MonthIncomesModal
        id="month-incomes-modal"
        open={modalState('month-incomes-modal', 'open')}
        data={modalState('month-incomes-modal', 'data')}
        handleClose={handleClose}
      />
      <MonthDetailsDirectModal
        id="month-details-direct-modal"
        open={modalState('month-details-direct-modal', 'open')}
        data={modalState('month-details-direct-modal', 'data')}
        handleClose={handleClose}
      />
      <MonthDetailsDefaultsModal
        id="month-details-defaults-modal"
        open={modalState('month-details-defaults-modal', 'open')}
        data={modalState('month-details-defaults-modal', 'data')}
        handleClose={handleClose}
      />
         <BenefitsHistoryModal
        id="benefits-history-modal"
        open={modalState('benefits-history-modal', 'open')}
        data={modalState('benefits-history-modal', 'data')}
        handleClose={handleClose}
      />
    </>
  ) : null;
};

ModalsProvider.propTypes = {};

export default ModalsProvider;
