import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Paper, Grid, Icon, TableRow, TableCell, Tooltip } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Badge from '../Badge';
import ViewSubtitle from '../ViewSubtitle';
import Chevron from '../../assets/img/icons/ArrowChevronForward.svg';
import TableParams from '../TableParams';
import useCell from '../TableParams/style';
import LoadingScreen from '../LoadingScreen';
import { openModal } from '../../slices/modals';
import { fetchMonthDetailsDirect } from '../../slices/incomes';
import { headersRowMonthIncomes as headersRow } from '../../utilities/constants';
import useStyles from './ModalStyles/MonthIncomesStyle';
import DialogTitleModal from '../DialogTitleModal';
import { formatNumber } from '../../utilities/formatNumber';

export const classBadge = (value) => {
  if (value === 0) return 'disable';
  if (value > 0) return 'balance';
  if (value < 0) return 'fault';
};

const MonthIncomes = ({ id, open, handleClose }) => {
  const cellClasses = useCell();
  const classes = useStyles();
  const { data, status } = useSelector((state) => state.incomes.monthIncomes);
  const { month, year } = useSelector((state) => state.incomes.currentDataDate);
  const dispatch = useDispatch();
  const tableData = data?.data;

  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={(e, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          handleClose(id);
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.dialog}
    >
      {status === 'failed' && (
        <>
          <DialogTitleModal
            title={`${data?.info?.month} ${data?.info?.year}`}
            closeHandler={() => handleClose(id)}
            undoHandler={() => {}}
            undo={false}
          />
          <DialogContent className={classes.dialogContent}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              className={classes.dialogContentHeader}
            >
              <Paper elevation={0}>
                <Alert severity="error">
                  Un error est servenu: Merci de contacter administrateur
                </Alert>
              </Paper>
            </Grid>
          </DialogContent>
        </>
      )}
      {status === 'idle' && <div>Pas de contenu </div>}
      {status === 'loading' && <LoadingScreen />}
      {status === 'succeeded' && (
        <>
          <DialogTitleModal
            title={`${data?.info?.month} ${data?.info?.year}`}
            closeHandler={() => handleClose(id)}
            undoHandler={() => {}}
            undo={false}
          />
          <DialogContent className={classes.dialogContent}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              className={classes.dialogContentHeader}
            >
              <Paper elevation={0}>
                <ViewSubtitle title="Les revenus" className="no-space" />
              </Paper>
              <Paper elevation={0}>
                <Badge
                  text={`${formatNumber(data?.info?.totalBalance)} pts`}
                  color={classBadge(data?.info?.totalBalance)}
                />
              </Paper>
            </Grid>

            <TableParams headersRow={headersRow} minWidth="840px" fitContent>
              {tableData &&
                tableData?.length > 0 &&
                tableData.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell align="left" className={cellClasses.cell}>
                      {row.articleName}
                    </TableCell>
                    <TableCell align="left" className={cellClasses.cell}>
                      {formatNumber(row.quantity)}
                    </TableCell>
                    <TableCell align="left" className={cellClasses.cell}>
                      {row.unitPrices.length > 0 ? (
                        <Tooltip
                          title={row.unitPrices.map((t) => (
                            <h3 key={t.title}>{`${t.title}: ${t.unitPrice} pts`} </h3>
                          ))}
                          placement="right"
                          style={{
                            padding: 0,
                            margin: 0,
                            cursor: 'pointer',
                            display: 'inline-block',
                          }}
                        >
                          <h2>...</h2>
                        </Tooltip>
                      ) : (
                        '0'
                      )}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={`${cellClasses.cell} ${cellClasses.redCell}`}
                    >
                      {row.default != undefined ? `${formatNumber(row.default)} pts` : ''}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={`${cellClasses.cell} ${cellClasses.greenCell}`}
                    >
                      {`${formatNumber(row.totalPrice)} pts`}
                    </TableCell>
                    {row.articleName === 'Séan. en direct' ? (
                      <TableCell
                        align="right"
                        className={cellClasses.link}
                        onClick={() => {
                          dispatch(
                            fetchMonthDetailsDirect({
                              month,
                              year,
                            })
                          );
                          dispatch(openModal('month-details-direct-modal', null));
                          handleClose(id);
                        }}
                      >
                        Voir détails
                        <Icon className={cellClasses.icon}>
                          <img src={Chevron} alt="Chevron" />
                        </Icon>
                      </TableCell>
                    ) : (
                      <TableCell align="left" />
                    )}
                  </TableRow>
                ))}
            </TableParams>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

export default MonthIncomes;
