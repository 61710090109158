import {createSlice, createAsyncThunk, current} from '@reduxjs/toolkit';
import axios from '../utilities/axios';
import { enqueueSnackbar } from './notifier';
import { closeModal } from './modals';
import Slide from '@material-ui/core/Slide';


const initialState = {
  chapters: [],
  status: 'idle',
  error: null,
  selectedTab: 'course',
};

export const fetchChapters = createAsyncThunk('chapters/fetchChapters', async (subject_slug) => {
  let data;
  try {
    const response = await axios.get(`/instructor/${subject_slug}/chapters`);
    data = await response.data;
    if ((response.status = 200)) {
      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const addNewChapter = createAsyncThunk('chapters/addNewChapter', async (query, thunkAPI) => {
  const { subject_slug, values } = query;
  let data;
  try {
    const response = await axios.post(`/instructor/${subject_slug}/chapters`, values);
    data = await response.data;
    if ((response.status = 200)) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: data.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      thunkAPI.dispatch(closeModal('add-chapter-modal'));
      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const editChapter = createAsyncThunk('chapters/editChapter', async (query, thunkAPI) => {
  const { chapter_slug, values } = query;
  let data;
  try {
    const response = await axios.put(`/instructor/chapters/${chapter_slug}`, values);
    data = await response.data;
    if ((response.status = 200)) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: data.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      thunkAPI.dispatch(closeModal('add-chapter-modal'));
      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const updateChaptersPositions = createAsyncThunk(
  'chapters/updateChaptersPositions',
  async (query, thunkAPI) => {
    const { values } = query;
    let data;
    try {
      const response = await axios.put(`/instructor/update-position/chapters`, values);
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
        return data.payload;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);




const slice = createSlice({
  name: 'chapters',
  initialState,
  reducers: {
    setSelectedTab: (state, action: PayloadAction<any>) => {
      state.selectedTab = action.payload;
    },
    updatePosition: (state, action) => {
      const { id, position, tab } = action.payload;

      let newArray = state.chapters.chapters
        .slice()
        .filter((item) => item.type === tab)
        .sort((a, b) => (a.name < b.name ? 1 : -1))
        .sort((a, b) => (a.position > b.position ? 1 : -1))
        .map((item, index) => {
          return { ...item, position: index };
        });

      const index = newArray.findIndex((chapter) => chapter.id.toString() === id.toString());

      for (let i = 0; i < newArray.length; i++) {
        if (index < position) {
          if (i <= position && i > 0) {
            newArray[i].position--;
          }
        } else if (index > position) {
          if (i >= position && i < newArray.length) {
            newArray[i].position++;
          }
        }
      }

      newArray[index].position = position;

      state.chapters.chapters = [
        ...newArray,
        ...state.chapters.chapters.filter((item) => item.type !== tab),
      ];
    },
    moveToSection: (state, action) => {
      const { id, section, position } = action.payload;

      const selectedItem = state.chapters.chapters.find((item) => item.id === parseInt(id, 10));

      const selectedSection = current(state).chapters.chapters.find(
          (item) => item.id === parseInt(section, 10)
      );

      selectedItem.chapter_sections = {
        id: selectedSection.id,
        name: selectedSection.name,
        position: selectedSection.position,
        type: selectedSection.type,
        isSection: true,
      };
      selectedItem.content_level = 2;

      state.chapters.chapters = [
        selectedItem,
        ...state.chapters.chapters.filter((item) => item.id !== parseInt(id, 10)),
      ];

      let newArray = state.chapters.chapters
          .slice()
          .filter((item) => item.chapter_sections?.id === parseInt(section, 10))
          .sort((a, b) => (a.title < b.title ? 1 : -1))
          .sort((a, b) => (a.position > b.position ? 1 : -1))
          .map((item, index) => {
            return { ...item, position: index };
          });

      const index = newArray.findIndex((content) => content.id === parseInt(id, 10));

      for (let i = 0; i < newArray.length; i++) {
        if (index < position) {
          if (i <= position && i > 0) {
            newArray[i].position--;
          }
        } else if (index > position) {
          if (i >= position && i < newArray.length) {
            newArray[i].position++;
          }
        }
      }

      newArray[index].position = position;

      state.chapters.chapters = [
        ...newArray,
        ...state.chapters.chapters.filter((item) => item.chapter_sections?.id !== parseInt(section, 10)),
      ];
    },
    updateSectionItemPosition: (state, action) => {
      const { id, section, position } = action.payload;

      let newArray = state.chapters.chapters
          .slice()
          .filter((item) => item.chapter_sections?.id === parseInt(section, 10))
          .sort((a, b) => (a.title < b.title ? 1 : -1))
          .sort((a, b) => (a.position > b.position ? 1 : -1))
          .map((item, index) => {
            return { ...item, position: index };
          });

      const index = newArray.findIndex((content) => content.id === parseInt(id, 10));

      for (let i = 0; i < newArray.length; i++) {
        if (index < position) {
          if (i <= position && i > 0) {
            newArray[i].position--;
          }
        } else if (index > position) {
          if (i >= position && i < newArray.length) {
            newArray[i].position++;
          }
        }
      }

      newArray[index].position = position;

      state.chapters.chapters = [
        ...newArray,
        ...state.chapters.chapters.filter((item) => item.chapter_sections?.id !== parseInt(section, 10)),
      ];
    },
    moveToMain: (state, action) => {
      const { id, section, position, type } = action.payload;

      const selectedItem = state.chapters?.chapters.find((item) => item.id === parseInt(id, 10));

      const selectedType = current(state).chapters?.chaptersType?.find((item) => item.name === type);
      selectedItem.type = selectedType?.name;
      selectedItem.chapter_sections = null;
      selectedItem.content_level = 1;

      state.chapters.chapters = [
        selectedItem,
        ...state.chapters.chapters.filter((item) => item.id !== parseInt(id, 10)),
      ];


      let newArray = state.chapters.chapters
          .slice()
          .filter((item) => item.type === type && item.content_level === 1)
          .sort((a, b) => (a.title < b.title ? 1 : -1))
          .sort((a, b) => (a.position > b.position ? 1 : -1))
          .map((item, index) => {
            return { ...item, position: index };
          });
      
      const element = newArray.find((content) => {
       return  content.id === parseInt(id, 10)
      });

      const index = newArray.findIndex((content) => content.id === parseInt(id, 10));
      for (let i = 0; i < newArray.length; i++) {
        if (index < position) {
          if (i <= position && i > 0) {
            newArray[i].position--;
          }
        } else if (index > position) {
          if (i >= position && i < newArray.length) {
            newArray[i].position++;
          }
        }
      }
      newArray[index].position = position;

      state.chapters.chapters = [
        ...newArray,
        ...state.chapters.chapters.filter(
            (item) => item.type === type && item.content_level === 2
        ),
        ...state.chapters.chapters.filter((item) => item.type !== type),
      ];
    },

    addChapterSection: (state, action) => {
      const { section, edit } = action.payload;

      let newArray = [];

      if (edit) {
        newArray = state.chapters.chapters.filter((item) => item.id !== section.id);
      } else {
        newArray = state.chapters.chapters;
      }

      state.chapters.chapters = [
        ...newArray,
        {
          id: section.id,
          title: section.name,
          type: section.type,
          is_draft: section?.is_draft,
          position: parseInt(section.position, 10),
          content_type: 'chapter_section',
          isSection: true,
          content_level: 1,
        },
      ];
    },
    removeChapterSection: (state, action) => {
      const { sectionId, sectionPosition } = action.payload;

      let newArray = [];

      newArray = state.chapters.chapters.filter((item) => item.id !== sectionId);

      state.chapters.chapters = newArray.map((item) => {
        if (item.content_type === 'chapter') {
          if (item.chapter_sections != null) {
            if (item.chapter_sections?.id === sectionId) {
              return { ...item, position: sectionPosition, content_level: 1, chapter_sections: null };
            }
          }
        }
        return item;
      });
    },
  },
  extraReducers: {
    [fetchChapters.pending]: (state, action) => {
      state.status = 'loading';
    },
    [fetchChapters.fulfilled]: (state, action) => {
      state.status = 'succeeded';

      const chapters = action.payload.chapters.map((chapter) => {
        return {
          ...chapter,
          type: chapter.type === '' ? 'course' : chapter.type,
          description: chapter.description === null ? '' : chapter.description,
          position: parseInt(chapter.position, 10),
          content_type: 'chapter',
          content_level: chapter.chapter_sections == null ? 1 : 2
        };
      });
      const chapterSections = action.payload.chapterSections.map((section) => {
        return {
          id: section.id,
          name: section.name,
          is_draft: section.is_draft,
          type: section.type,
          position: parseInt(section.position, 10),
          content_type: 'chapter_section',
          isSection: true,
          content_level: 1,
        };
      });
      let data = {
        ...action.payload,
        chapters : chapters
             .concat(chapterSections)
             .slice()
            .sort((a, b) => (a.name < b.name ? 1 : -1))
             .sort((a, b) => (a.position > b.position ? 1 : -1))
      };

      state.chapters = data
    },
    [fetchChapters.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    [addNewChapter.fulfilled]: (state, action) => {
      state.chapters.chapters.push({
        ...action.payload,
        type: action.payload.type === '' ? 'course' : action.payload.type,
        description: action.payload.description === null ? '' : action.payload.description,
      });
    },
    [editChapter.fulfilled]: (state, action) => {
      const index = state.chapters.chapters.findIndex((item) => item.id === action.payload.id);
      state.chapters.chapters[index] = {
        ...action.payload,
        type: action.payload.type === '' ? 'course' : action.payload.type,
        description: action.payload.description === null ? '' : action.payload.description,
      };
      // state.chapters.chapters.push({
      //   ...action.payload,
      //   type: action.payload.type === '' ? 'course' : action.payload.type,
      //   description: action.payload.description === null ? '' : action.payload.description,
      // });
    },
  },
});

export const { setSelectedTab } = slice.actions;

export const reducer = slice.reducer;

export const updatePosition = (id, position, tab) => (dispatch) => {
  dispatch(slice.actions.updatePosition({ id, position, tab }));
};

export const moveToSection = (id, section, position) => (dispatch) => {
  dispatch(slice.actions.moveToSection({ id, section, position }));
};

export const updateSectionItemPosition = (id, section, position) => (dispatch) => {
  dispatch(slice.actions.updateSectionItemPosition({ id, section, position }));
};
export const moveToMain = (id, section, position, type) => (dispatch) => {
  dispatch(slice.actions.moveToMain({ id, section, position, type }));
};

export const addChapterSection = (section, edit) => (dispatch) => {
  dispatch(slice.actions.addChapterSection({ section, edit }));
};

export const removeChapterSection = (sectionId, sectionPosition) => (dispatch) => {
  dispatch(slice.actions.removeChapterSection({ sectionId, sectionPosition }));
};
export default slice;
