import { Slide } from '@material-ui/core';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import axios from '../utilities/axios';
import { enqueueSnackbar } from './notifier';
import { headers } from '../services/api';

const initialState = {
  authResult: null,
  status: 'idle',
  error: null,
  confirmCodeMessage: null,
};

export const createResetPasswordCode = createAsyncThunk(
  'auth/createResetPasswordCode ',
  async (body, thunkAPI) => {
    const { credential, t, ...recaptcha } = body;

    let data;
    try {
      const response = await axios.get(
        `/auth/request-password?credentiel=${credential}`,
        recaptcha,
        headers
      );
      data = await response?.data;
      if (response?.status === 200) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: t(data.message),

            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },

              TransitionComponent: Slide,
            },
          })
        );
        setTimeout(() => {
          window.location = '/reset-password';
        }, 1000);

        return data.payload;
      }
      throw new Error(response?.statusText);
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: t(err?.message) || t('Invalid credentials'),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      return Promise.reject('Invalid credentials');
    }
  }
);
export const resetPassword = createAsyncThunk('auth/resetPassword ', async (query, thunkAPI) => {
  const { t, ...values } = query;

  let data;
  try {
    const response = await axios.post(`/auth/reset-password`, values);

    data = await response?.data;

    if (response?.status === 200) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: t(data?.message),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      window.location = '/login';
    } else {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: t(data?.message) || t('Bad Resetting Token'),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      return Promise.reject('Bad Resetting Token');
    }
    return data?.payload;
  } catch (err) {
    thunkAPI.dispatch(
      enqueueSnackbar({
        message: t(err?.message) || t('Bad Resetting Token'),
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          TransitionComponent: Slide,
        },
      })
    );
    return Promise.reject('Bad Resetting Token');
  }
});

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: {
    [createResetPasswordCode.pending]: (state, action) => {
      state.status = 'loading';
    },
    [createResetPasswordCode.fullfiled]: (state, action) => {
      state.status = 'succeeded';
      state.authResult = action.payload.message;
    },
    [createResetPasswordCode.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action?.error?.message;
    },
    [resetPassword.pending]: (state, action) => {
      state.status = 'loading';
    },
    [resetPassword.fullfiled]: (state, action) => {
      state.status = 'succeeded';
      state.authResult = action.payload.message;
    },
    [resetPassword.rejected]: (state, action) => {
      state.status = 'failed';

      state.error = action?.error?.message;
    },
  },
});

export const reducer = slice.reducer;

export default slice;
