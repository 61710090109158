import * as dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
dayjs.extend(customParseFormat);
dayjs.extend(duration);

const getDuration = (lessons) => {
  let DMs = 0;
  if (lessons) {
    lessons.map((lesson) => {
      if ([1, 2, 6].includes(lesson?.data?.type?.id)) {
        if (!lesson?.data?.content_videos) return;

        if (lesson?.data?.content_videos[0]?.duration !== null && lesson?.data?.content_videos[0]?.duration !== undefined) {
          DMs +=
            (dayjs(lesson?.data?.content_videos[0]?.duration, 'H:mm:ss').hour() * 60 * 60 +
              dayjs(lesson?.data?.content_videos[0]?.duration, 'H:mm:ss').minute() * 60 +
              dayjs(lesson?.data?.content_videos[0]?.duration, 'H:mm:ss').second()) *
            1000;
        }
      }
      return DMs;
    });

    return `${Math.trunc(dayjs.duration(DMs).asHours())} Heures ${dayjs
      .duration(DMs)
      .minutes()} Min`;
  }
};
export default getDuration;
