import React from 'react';
import PropTypes from 'prop-types';

const SwitchGuard = ({ children }) => {
  return <>{children}</>;
};

SwitchGuard.propTypes = {};

export default SwitchGuard;
