export const getSum = (obj) => {
  let sum = 0;
  Object.values(obj).forEach((value) => {
    if (typeof value === 'object') {
      sum += getSum(value);
    } else {
      sum += value;
    }
  });
  return sum;
};
