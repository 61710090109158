import React , {useEffect} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { useSelector, useDispatch } from 'react-redux';
import CircularProgress from "@material-ui/core/CircularProgress";



const DeleteConfiramtion=({id, open, handleClose, data})=>{
  const dispatch=useDispatch() 
  const isLoading = useSelector((state) => state.deleteLoading);

    return(
        <Dialog
      open={open}
      onClose={() => handleClose(id)}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="ta-modal "
    >
      <DialogTitle id="alert-dialog-title">
        <span className="label"></span>
        <span className="close-btn" onClick={() => handleClose(id)}>
          Fermer
        </span>
      </DialogTitle>
      <DialogContent className="ta-modal-content-scroll delete-confirmation-modal-container">
      <ErrorOutlineIcon className='delete-Icon' /> 
       <span className='delete-title'> Vous êtes sûrs ?</span>
       <span className='delete-description'>Voulez-vous vraiment supprimer ce contenu ? <br/> Ce processus ne peut pas être annulé.</span>
        <div className='delete-buttons'>
        <Button
                disabled = {isLoading === true} 
                onClick={(e)=>{
                        e.preventDefault();
                        dispatch(data.Click); }} 
                className="ta-btn danger btn-rounded btn-lg mt-12 same width">Confirmer
                {isLoading === true && <CircularProgress size={20} style={{ marginLeft: '10px'}} />}
        </Button>
        <Button onClick={()=>handleClose(id)} className="ta-btn primary btn-rounded btn-lg mt-12 same width">Annuler
        </Button>
        </div>
      </DialogContent>
    </Dialog>
    )
}
export default DeleteConfiramtion