import React from 'react';

const ViewSubtitle = ({ title, className }) => {
  return (
    <div className={className ? `view-subtitle ${className}` : 'view-subtitle'}>
      {title != null && <span>{title}</span>}
    </div>
  );
};

export default ViewSubtitle;
