import React from 'react';
import QcmFrame from '../players/QcmFrame';
import MagPlayer from '../players/MagPlayer';
import FreePlayer from '../players/FreePlayer';
import PaidPlayer from '../players/PaidPlayer';
import { LockedVideo } from '../..';
import PropTypes from 'prop-types';

const Index = (props) => {
  const { currentLesson, chapterId, courseError } = props;
  const data = currentLesson && currentLesson?.content;
  const type = currentLesson && currentLesson?.content?.type?.id;
  const locked = currentLesson && currentLesson?.locked;
  return (
    <div className="video-container">
      {
      courseError === true ? (
        <LockedVideo />
      ) : type == 1 ? (
        <FreePlayer data={data} chapterId={chapterId} />
       ) : type == 2 || type == 6 ? (
            <PaidPlayer data={data} currentLesson={currentLesson} chapterId={chapterId} />
       ) : type == 3 ? (
         <MagPlayer data={currentLesson} />
       ) : type == 4 ? (
        <QcmFrame data={data} />
      ) : (
        <LockedVideo />
      )}
    </div>
  );
};

Index.propTypes = {
  currentLesson: PropTypes.object,
  firstLesson: PropTypes.object,
  update: PropTypes.bool,
};

export default Index;
