import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Paper, Grid, TableRow, TableCell } from '@material-ui/core';
import ArrowForwardSharpIcon from '@material-ui/icons/ArrowForwardSharp';
import Alert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Badge from '../Badge';
import ViewSubtitle from '../ViewSubtitle';
import TableParams from '../TableParams';
import useCell from '../TableParams/style';
import { openModal } from '../../slices/modals';
import useStyles from './ModalStyles/MonthDetailsDefaultsStyle';
import DialogTitleModal from '../DialogTitleModal';
import LoadingScreen from '../LoadingScreen';

const TableTitle = ({ title, value }) => {
  const classes = useStyles();

  return (
    <Grid container direction="row" alignItems="center" className={classes.dialogContentHeader}>
      <Paper elevation={0}>
        <ViewSubtitle title={title} />
      </Paper>
      <Paper elevation={0}>
        <Badge
          text={value === 0 ? `${value} pts` : `${-value} pts`}
          color={value === 0 ? 'responsive disable' : 'responsive fault'}
          className="small"
        />
      </Paper>
    </Grid>
  );
};

const MonthDetailsDefaults = ({ id, open, handleClose, handlePrev, ...rest }) => {
  const classes = useStyles();
  const cellClasses = useCell();
  const dispatch = useDispatch();
  const { title, status, defaults, data } = useSelector(
    (state) => state.incomes.MonthDetailsDefaults
  );
  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={(e, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          handleClose(id);
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.dialog}
    >
      {status === 'idle' && <div>Pas de contenu </div>}
      {status === 'loading' && <LoadingScreen />}

      {status === 'failed' && (
        <>
          <DialogTitleModal
            title={title}
            closeHandler={() => handleClose(id)}
            undoHandler={() => {
              dispatch(openModal('month-details-direct-modal', null));
              handleClose(id);
            }}
            undo
          />
          <DialogContent className={classes.dialogContent}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              className={classes.dialogContentHeader}
            >
              <Paper elevation={0}>
                <Alert severity="error">
                  Un error est servenu: Merci de contacter administrateur
                </Alert>
              </Paper>
            </Grid>
          </DialogContent>
        </>
      )}
      {status === 'succeeded' && (
        <>
          <DialogTitleModal
            title={title}
            closeHandler={() => handleClose(id)}
            undoHandler={() => {
              dispatch(openModal('month-details-direct-modal', null));
              handleClose(id);
            }}
            undo
          />

          <DialogContent className={classes.dialogContent}>
            <Grid container spacing={3}>
              {data.map((defaultTable, index) => (
                <Grid item md={6} xs={12} key={`default${index}`}>
                  <TableTitle title={defaultTable.title} value={defaultTable.totalDefaults} />
                  <TableParams
                    headersRow={
                      ['None', null].includes(defaultTable?.type)
                        ? [
                            { title: 'Pénalité', align: 'left' },
                            { title: 'Points Réduits', align: 'right' },
                          ]
                        : [
                            { title: 'Pénalité', align: 'left' },
                            { title: `Nombre(${defaultTable?.type})`, align: 'left' },
                            { title: 'Points Réduits', align: 'right' },
                          ]
                    }
                    fitContent={['None', null].includes(defaultTable?.type)}
                    minWidth
                  >
                    {defaultTable &&
                      defaultTable?.details?.map((row, index) => (
                        <TableRow key={index}>
                          {row.penalty && (
                            <TableCell
                              align="left"
                              className={
                                row.active
                                  ? `${cellClasses.cell} ${cellClasses.redBg}`
                                  : cellClasses.cell
                              }
                            >
                              {row.penalty}
                            </TableCell>
                          )}

                          {row?.number &&
                            !['None', null].includes(defaultTable?.type) &&
                            !defaultTable.isInterval && (
                              <TableCell
                                align="left"
                                className={
                                  row?.active
                                    ? `${cellClasses.cell} ${cellClasses.redBg}`
                                    : cellClasses.cell
                                }
                              >
                                {row?.number}
                              </TableCell>
                            )}
                          {row?.number &&
                            !['None', null].includes(defaultTable?.type) &&
                            defaultTable.isInterval && (
                              <TableCell
                                align="left"
                                className={
                                  row?.active
                                    ? `${cellClasses.cell} ${cellClasses.redBg}`
                                    : cellClasses.cell
                                }
                              >
                                <div className={classes.intervaleContainer}>
                                  <span>
                                    {index === 0 ? 5 : defaultTable?.details?.[index - 1]?.number}
                                  </span>
                                  <ArrowForwardSharpIcon />
                                  <span>{defaultTable?.details?.[index]?.number}</span>
                                </div>
                              </TableCell>
                            )}
                          {row?.points && (
                            <TableCell
                              align="right"
                              className={
                                row?.active
                                  ? `${cellClasses.cell} ${cellClasses.redBg}`
                                  : cellClasses.cell
                              }
                            >
                              {`${row?.points} pts`}
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                  </TableParams>
                </Grid>
              ))}
            </Grid>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

export default MonthDetailsDefaults;
