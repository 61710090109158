import React, { useState, useEffect, createRef } from 'react';
import PropTypes from 'prop-types';
import { openModal } from '../../slices/modals';
import { useSelector, useDispatch } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { fetchUser } from '../../slices/switch';

const linkRef = createRef();

const openLink = () => {
  if (linkRef.current) {
    linkRef.current.click();
  }
};

const SwitchUsers = ({ id, open, handleClose, data, ...rest }) => {
  const { user } = useSelector((state) => state.switch);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (user.status === 'succeeded') {
      setIsLoading(false);
      openLink();
    }
  }, [user.data, user.status, dispatch]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose(id);
        dispatch(openModal('switch-classes-modal'));
      }}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="ta-modal add-video-modal"
    >
      <DialogTitle id="alert-dialog-title">
        <span className="label">Voir en tant qu'étudiant</span>
        <span
          className="close-btn"
          onClick={() => {
            handleClose(id);
            dispatch(openModal('switch-classes-modal'));
          }}
        >
          Fermer
        </span>
      </DialogTitle>
      <DialogContent className="ta-modal-content-scroll switch-classes-container">
        <span className="title">Veuillez choisir une section</span>
        <div className="switch-users-group">
          <div className="switch-classe-item">
            <div className="texts">
              <div className="group">
                <span className="number">{data?.class?.class}</span>
                <span className="sup">{data?.class?.key}</span>
                {isLoading && <CircularProgress size={20} style={{ margin: '0 auto' }} />}
              </div>
              <span className="label">{data?.class?.slug}</span>
            </div>
            <div className="box"></div>
          </div>
          <div className="btns-group">
            {data?.class?.sections &&
              data?.class?.sections.length > 0 &&
              data?.class?.sections.map((item, index) => {
                return (
                  <Button
                    key={index}
                    className="ta-btn dark btn-rounded"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsLoading(true);
                      dispatch(fetchUser(item.userId));
                    }}
                  >
                    {`${data?.class?.name} ${item.name}`}
                  </Button>
                );
              })}
          </div>
        </div>
        <a
          href={`${process.env.REACT_APP_STUDENT_URL}/switch?token=${user.data?.token}&userId=${user.data?.userId}&adminId=${user.data?.instructorUser}`}
          target="_blank"
          rel="noreferrer noopener"
          ref={linkRef}
          style={{ display: 'none' }}
        >
          hidden link
        </a>
      </DialogContent>
    </Dialog>
  );
};

SwitchUsers.propTypes = {};

export default SwitchUsers;
