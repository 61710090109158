import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../utilities/axios';

const initialState = {
  contentsLikePupil: {
    data: [],
    status: 'idle',
    error: null,
  },
  contentLikePupil: {
    data: [],
    status: 'idle',
    error: null,
  },
};

export const fetchContents = createAsyncThunk(
  'contentsLikePupil/fetchContents',
  async (chapterSlug) => {
    let data;
    try {
      const response = await axios.get(`/instructor/${chapterSlug}/content`);
      data = await response.data;
      if ((response.status = 200)) {
        return data.payload;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);


export const fetchOneContent = createAsyncThunk(
  'contentsLikePupil/fetchOneContent',
  async (lessonData) => {
    let data;
    try {
      const response = await axios.get(`/instructor/${lessonData.chapterSlug}/content/${lessonData.contentSlug}`);
      data = await response.data;
      if ((response.status = 200)) {
        return data.payload;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: 'contentsLikePupil',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchContents.pending]: (state, action) => {
      state.contentsLikePupil.status = 'loading';
    },
    [fetchContents.fulfilled]: (state, action) => {
      state.contentsLikePupil.status = 'succeeded';

      const newContents = action.payload.contents.map((content) => {
        return {
          id: content.contentId,
          name: content.contentName,
          tab_id: content.tabId === null ? '1' : content.tabId,
          section_id: content.sectionId,
          position: parseInt(content.position, 10),
          type: 'content',
          level: content.sectionId === null ? 1 : 2,
          data: content.content,
        };
      });

      const newSections = action.payload.sections.map((section) => {
        return {
          id: section.id,
          name: section.name,
          tab_id: section.tab.id.toString(),
          position: parseInt(section.position, 10),
          type: 'section',
          level: 1,
        };
      });

      state.contentsLikePupil.data = {
        subject: action.payload.subject,
        division: action.payload.division,
        chapter: action.payload.chapter,
        tabs: action.payload.tabs,
        contents: newContents
          .concat(newSections)
          .slice()
          .sort((a, b) => (a.name < b.name ? 1 : -1))
          .sort((a, b) => (a.position > b.position ? 1 : -1)),
      };
    },
    [fetchContents.rejected]: (state, action) => {
      state.contentsLikePupil.status = 'failed';
      state.contentsLikePupil.error = action.payload;
    },

    [fetchOneContent.pending]: (state, action) => {
      state.contentLikePupil.status = 'loading';
    },
    [fetchOneContent.fulfilled]: (state, action) => {
      state.contentLikePupil.status = 'succeeded';
      state.contentLikePupil.data = action.payload;
    },
    [fetchOneContent.rejected]: (state, action) => {
      state.contentLikePupil.status = 'failed';
      state.contentLikePupil.error = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export default slice;
