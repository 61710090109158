import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { addNewChapter, editChapter } from '../../slices/chapters';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import {
  TextField,
  Box,
  FormHelperText,
  Typography,
  Switch,
  Select,
  MenuItem,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import { fetchAllChaptersTypes } from '../../slices/diverse';

const AddChapter = ({ id, open, handleClose, data, ...rest }) => {
  const isMountedRef = useIsMountedRef();
  const dispatch = useDispatch();
  const { allChaptersTypes } = useSelector((state) => state.diverse);

  useEffect(() => {
    if (allChaptersTypes.status === 'idle' && open === true) {
      dispatch(fetchAllChaptersTypes());
    }
  }, [open, allChaptersTypes.status, dispatch]);

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(id)}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="ta-modal add-video-modal"
    >
      <DialogTitle id="alert-dialog-title">
        <span className="label">
          {!data?.edit ? 'Ajouter un nouveau chapitre' : 'Modifier le chapitre'}
        </span>
        <span className="close-btn" onClick={() => handleClose(id)}>
          Fermer
        </span>
      </DialogTitle>
      <DialogContent className="ta-modal-content-scroll">
        <Formik
          initialValues={{
            name: !data?.edit ? '' : data?.chapter?.name,
            visible: !data?.edit ? false : data?.chapter?.visible,
            description: !data?.edit ? '' : data?.chapter?.description,
            position: !data?.edit ? 0 : data?.chapter?.position,
            type: !data?.edit ? data?.selected_tab : data?.chapter?.type,
            subject: !data?.edit ? data?.subject_id : data?.chapter?.subject?.id,
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().max(255).required('Title is required'),
            visible: Yup.bool(),
            description: Yup.string().max(255),
            type: Yup.string().max(255).required('Onglet is required'),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              if (!data?.edit) {
                dispatch(
                  addNewChapter({
                    subject_slug: data.subject_slug,
                    values,
                  })
                );
              } else {
                dispatch(
                  editChapter({
                    chapter_slug: data?.chapter?.slug,
                    values,
                  })
                );
              }

              if (isMountedRef.current) {
                setStatus({ success: true });
                setSubmitting(false);
              }
            } catch (err) {
              console.error(err);
              if (isMountedRef.current) {
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            resetForm,
            setFieldValue,
            setTouched,
          }) => (
            <form noValidate onSubmit={handleSubmit} className="ta-form" {...rest}>
              <Typography variant="body2" color="textSecondary" className="label">
                Titre*
              </Typography>
              <TextField
                className="input"
                error={Boolean(touched.name && errors.name)}
                fullWidth
                autoFocus
                helperText={touched.name && errors.name}
                // label="Title"
                margin="normal"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.name}
                variant="outlined"
                size="small"
              />
              <Typography variant="body2" color="textSecondary">
                Publié*
              </Typography>
              <Switch
                checked={values.visible}
                color="primary"
                edge="start"
                name="visible"
                onChange={handleChange}
                value={values.visible}
              />
              <Typography variant="body2" color="textSecondary">
                Description
              </Typography>
              <TextField
                className="input"
                error={Boolean(touched.description && errors.description)}
                fullWidth
                multiline
                rows={4}
                helperText={touched.description && errors.description}
                margin="normal"
                name="description"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.description}
                variant="outlined"
                size="small"
              />
              <Typography variant="body2" color="textSecondary">
                Choisir un onglet*
              </Typography>
              <Select
                className={touched.type && errors.type ? 'input size-sm' : 'input size-sm mb'}
                error={Boolean(touched.type && errors.type)}
                fullWidth
                margin="normal"
                name="type"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.type}
                variant="outlined"
              >
                {allChaptersTypes.data &&
                  allChaptersTypes.data.length > 0 &&
                  allChaptersTypes.data.map((item, index) => (
                    <MenuItem key={index} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
              {touched.type && errors.type && (
                <span className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense">
                  {errors.type}
                </span>
              )}
              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
              <Box mt={2} className="ta-btns-group">
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  className="ta-btn primary btn-rounded btn-lg"
                >
                  {!data?.edit ? 'Ajouter' : 'Modifier'}
                </Button>
                <Button
                  disabled={isSubmitting}
                  type="reset"
                  className="ta-btn btn-rounded btn-lg"
                  onClick={resetForm}
                >
                  Annuler
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

AddChapter.propTypes = {};

export default AddChapter;
