import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {  TableRow, TableCell, DialogTitle, TableHead, TableBody } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ResponsiveTable from '../ResponsiveTable';
import dayjs from 'dayjs';

const headers = [
    { accessor: 'payed_at', label: 'Date de paiement' },
    { accessor: 'amount', label: 'Montant reçu' },
  ];
const BenefitsHistory = ({ id, open, handleClose }) => {
    const { benefitsHistory, status, error } = useSelector((state) => state.codeCoupon);

  const dispatch = useDispatch();
 

  return (
    <Dialog
    open={open}
    onClose={() => handleClose(id)}
    disableBackdropClick={true}
    disableEscapeKeyDown={true}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    className="ta-modal add-video-modal"
    maxWidth="md"

  >
    <DialogTitle id="alert-dialog-title">
      <span className="label">Historique de paiement</span>
      <span className="close-btn" onClick={() => handleClose(id)}>
        Fermer
      </span>
    </DialogTitle>
    <DialogContent className="ta-modal-content-scroll benefits-history-content">
    <ResponsiveTable>
              <TableHead>
                <TableRow>
                  {headers &&
                    headers.length > 0 &&
                    headers.map((col, index) => {
                      return <TableCell key={index}>{col.label}</TableCell>;
                    })}
                </TableRow>
              </TableHead>
              <TableBody>
                {benefitsHistory && benefitsHistory.length > 0 ? (
                  benefitsHistory
                    .slice()
                    .sort((a, b) =>
                      a?.discountCode?.expired_at < b?.discountCode?.expired_at ? 1 : -1
                    )
                    .map((row, index) => {
                      const newRow = {
                        payed_at: dayjs(row.payed_at).format('DD/MM/YYYY'),
                        amount: `${row.amount} dt`,
                      };
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                          {headers &&
                            headers.length > 0 &&
                            headers.map((col, index) => {
                              return (
                                <TableCell key={index} align="center">
                                  {newRow[col.accessor]}
                                </TableCell>
                              );
                            })}
                        </TableRow>
                      );
                    })
                ) : (
                  <TableRow hover role="checkbox" tabIndex={-1}>
                    <TableCell colSpan={headers && headers.length} style={{ textAlign: 'center' }}>
                      <span style={{ color: '#a2a2a7' }}>Aucun code coupon</span>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </ResponsiveTable>
    </DialogContent>
  </Dialog>
  );
};

export default BenefitsHistory;
