import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Im_magazine from '../../assets/img/teacher/add-magazine.png';
import Im_video from '../../assets/img/teacher/add-video.png';
import Im_correction from '../../assets/img/teacher/add-correction.png';

const AddContent = ({ id, open, handleClose }) => {
  return (
    <Dialog
      open={open}
      onClose={() => handleClose(id)}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="ta-modal add-content-modal"
    >
      <DialogTitle id="alert-dialog-title">
        <span className="label">Quel contenu voulez-vous ajouter?</span>
        <span className="close-btn" onClick={() => handleClose(id)}>
          Fermer
        </span>
      </DialogTitle>
      <DialogContent>
        <Link className="items-group" to="/magazines" onClick={() => handleClose(id)}>
          <img alt="img" src={Im_magazine} width={100} />
          <Button className="ta-btn primary btn-rounded">Ajouter une magazine</Button>
        </Link>
        <Link className="items-group" to="/videos" onClick={() => handleClose(id)}>
          <img alt="img" src={Im_video} width={116} />
          <Button className="ta-btn primary btn-rounded">Ajouter une video</Button>
        </Link>
        <Link className="items-group" to="/examens" onClick={() => handleClose(id)}>
          <img alt="img" src={Im_correction} width={100} />
          <Button className="ta-btn primary btn-rounded">Ajouter une correction</Button>
        </Link>
      </DialogContent>
      {/* <DialogActions>
        <Button onClick={() => handleClose(id)} color="primary">
          Disagree
        </Button>
        <Button onClick={() => handleClose(id)} color="primary" autoFocus>
          Fermer
        </Button>
      </DialogActions> */}
    </Dialog>
  );
};

AddContent.propTypes = {};

export default AddContent;
