import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  dialog: {
    padding: '0px',
    margin: '0px',
  },
  dialogTitle: {
    padding: '0px 20px',
    margin: '0px',
  },
  dialogContent: {
    padding: '0 20px 20px 20px',
    margin: '0px',
  },
  icon: {
    width: '24px',
    height: '24px',
    cursor: 'pointer',
    color: '#2BA7DF',
  },
  dialogContentHeader: {
    marginBottom: '20px',
    justifyContent: 'space-between',
  },
  iconUndo: {
    width: '24px',
    height: '24px',
    cursor: 'pointer',
    color: '#2BA7DF',
    marginRight: '12px',
  },
}));

export default useStyles;
