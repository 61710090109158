export default function examsSearchQuery(
  subjects,
  dueDateStartDate,
  dueDateEndDate,
  appearanceDateStartDate,
  appearanceDateEndDate
) {
  let searchQuery = '/instructor/content-exams';
  if (
    subjects ||
    (dueDateStartDate && dueDateEndDate) ||
    (appearanceDateStartDate && appearanceDateEndDate)
  ) {
    searchQuery += '?search=';
  }
  if (subjects) {
    searchQuery += `subjectExams.subject.id:${subjects};`;
  }

  if (dueDateStartDate && dueDateEndDate) {
    searchQuery += `dueDate:${dueDateStartDate},${dueDateEndDate};`;
  }

  if (appearanceDateStartDate && appearanceDateEndDate) {
    searchQuery += `appearanceDate:${appearanceDateStartDate},${appearanceDateEndDate};`;
  }

  if (searchQuery.endsWith(';')) {
    searchQuery = searchQuery.slice(0, -1);
  }

  if (
    subjects ||
    (dueDateStartDate && dueDateEndDate) ||
    (appearanceDateStartDate && appearanceDateEndDate)
  ) {
    searchQuery += '&orderBy=id&sortedBy=desc&searchJoin=and';
  }

  return searchQuery;
}
