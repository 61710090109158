import React, { createContext, useEffect, useReducer } from 'react';
import jwtDecode from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import { Slide } from '@material-ui/core';
import LoadingScreen from '../components/LoadingScreen';
import axios from '../utilities/axios';
import { fetchUser } from '../slices/user';
import { enqueueSnackbar } from '../slices/notifier';

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
  error: null,
};

const isValidToken = (token) => {
  if (!token) {
    return false;
  }

  const decoded = jwtDecode(token);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (token) => {
  if (token) {
    localStorage.setItem('token', token);
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    localStorage.removeItem('token');
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }
    case 'LOGIN': {
      // const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        // user,
        error: null,
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        error: null,
      };
    }
    case 'ERROR': {
      const error = action.payload;
      return {
        ...state,
        error,
      };
    }

    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => {},
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const dispatcher = useDispatch();

  const login = async (data) => {
    const { t, ...body } = data;
    try {
      const response = await axios.post('/instructor/auth/login_check', body);
      const { token } = response?.data?.payload;

      if (response?.data?.code === 200) {
        setSession(token);

        dispatcher(
          enqueueSnackbar({
            message: t(response.data.message),
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );

        dispatch({
          type: 'LOGIN',
          payload: {
            //  user,
          },
        });
      }
    } catch (error) {
      dispatch({ type: 'ERROR', payload: 'Invalid credentials' });

      dispatcher(
        enqueueSnackbar({
          message: t('Invalid credentials'),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
    }
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    const initialise = async () => {
      try {
        const token = window.localStorage.getItem('token');

        if (token && isValidToken(token)) {
          setSession(token);

          dispatcher(fetchUser());

          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              user,
              error: null,
            },
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <LoadingScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
