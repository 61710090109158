import React from 'react';
import PropTypes from 'prop-types';

const CardTitle = ({ title, className }) => {
  return (
    <div className={`card-title${className != null ? ` ${className}` : ''}`}>
      <span>{title}</span>
    </div>
  );
};

CardTitle.propTypes = {};

export default CardTitle;
