import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../utilities/axios';
import extractClassesBySubjectsNames from '../pages/Subjects/helpers/extractClassesBySubjectsNames';

const initialState = {
  subjects: [],
  status: 'idle',
  error: null,
};

export const fetchSubjects = createAsyncThunk('subjects/fetchSubjects', async () => {
  let data;
  try {
    const response = await axios.get('/instructor/subjects');
    data = await response.data;
    if ((response.status = 200)) {
      return extractClassesBySubjectsNames(data.payload);
    }
    throw new Error(response.statusText);
  } catch (err) {
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

const slice = createSlice({
  name: 'subjects',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchSubjects.pending]: (state, action) => {
      state.status = 'loading';
    },
    [fetchSubjects.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.subjects = state.subjects.concat(action.payload);
    },
    [fetchSubjects.rejected]: (state, action) => {
      // console.log('sub: ', action);
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export default slice;

// export const selectSubjectBySlug = (state, subjectSlug) => {
//   const data = state?.subjects?.subjects;
//   const l = data.length;
//   if (l <= 0) {
//     return null;
//   }
//   let i = 0;
//   let found = false;
//   let res = {};

//   do {
//     if (data[i]?.classes.filter((item) => item.slug === subjectSlug && item).length > 0) {
//       found = true;
//       res = data[i]?.classes.filter((item) => item.slug === subjectSlug && item)[0];
//     } else {
//       i++;
//     }
//   } while (!found || i >= l);

//   return res;
// };
