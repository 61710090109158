import React from 'react';
import PropTypes from 'prop-types';
import { fetchUser, editPassword } from '../../slices/user';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import { TextField, Box, FormHelperText, Typography, Switch } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useIsMountedRef from '../../hooks/useIsMountedRef';

const EditPassword = ({ id, open, handleClose, ...rest }) => {
  const dispatch = useDispatch();
  const isMountedRef = useIsMountedRef();

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(id)}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="ta-modal add-video-modal"
    >
      <DialogTitle id="alert-dialog-title">
        <span className="label">Modifier mot de passe</span>
        <span className="close-btn" onClick={() => handleClose(id)}>
          Fermer
        </span>
      </DialogTitle>
      <DialogContent className="ta-modal-content-scroll">
        <Formik
          initialValues={{
            old_password: '',
            password: '',
            password_confirmation: '',
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            old_password: Yup.string().required('Old password is required'),
            password: Yup.string()
              .required('Password is required')
              .matches(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
              ),
            password_confirmation: Yup.string()
              .required('Passwords must match')
              .oneOf([Yup.ref('password'), null], 'Passwords must match'),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              dispatch(
                editPassword({
                  oldPassword: values.old_password,
                  newPassword: values.password,
                })
              );

              if (isMountedRef.current) {
                setStatus({ success: true });
                setSubmitting(false);
              }
            } catch (err) {
              console.error(err);
              if (isMountedRef.current) {
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            resetForm,
            setFieldValue,
            setTouched,
          }) => (
            <form noValidate onSubmit={handleSubmit} className="ta-form" {...rest}>
              <Typography variant="body2" color="textSecondary" className="label">
                Mot de passe actuel*
              </Typography>
              <TextField
                className="input"
                error={Boolean(touched.old_password && errors.old_password)}
                fullWidth
                autoFocus
                helperText={touched.old_password && errors.old_password}
                // label="Title"
                margin="normal"
                name="old_password"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.old_password}
                variant="outlined"
                size="small"
              />
              <Typography variant="body2" color="textSecondary" className="label">
                Nouveau mot de passe*
              </Typography>
              <TextField
                className="input"
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                // label="Title"
                margin="normal"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.password}
                variant="outlined"
                size="small"
              />
              <Typography variant="body2" color="textSecondary" className="label">
                Confirmation de mot de passe*
              </Typography>
              <TextField
                className="input"
                error={Boolean(touched.password_confirmation && errors.password_confirmation)}
                fullWidth
                helperText={touched.password_confirmation && errors.password_confirmation}
                // label="Title"
                margin="normal"
                name="password_confirmation"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.password_confirmation}
                variant="outlined"
                size="small"
              />

              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
              <Box mt={2} className="ta-btns-group">
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  className="ta-btn primary btn-rounded btn-lg"
                >
                  Modifier
                </Button>
                <Button
                  disabled={isSubmitting}
                  type="reset"
                  className="ta-btn btn-rounded btn-lg"
                  onClick={resetForm}
                >
                  Annuler
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

EditPassword.propTypes = {};

export default EditPassword;
