import React, { useState } from 'react';
import Fab from '@material-ui/core/Fab';
import SkipPreviousRoundedIcon from '@material-ui/icons/SkipPreviousRounded';
import SkipNextRoundedIcon from '@material-ui/icons/SkipNextRounded';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const Index = (props) => {
  const { lessons, subjectSlug, chapterSlug, lessonSlug, chapter } = props;
  const [prev_click_playlist, setPrevPlayList] = useState(false);
  const [next_click_playlist, setNextPlayList] = useState(false);


  let counter = 0;
  let position = 0;
  const playlist = lessons && Object.values(lessons);
  playlist &&
    playlist.forEach((item) => {
      if (item?.data?.slug === lessonSlug) {
        position = counter;
      }
      counter += 1;
    });

  let previous_playlist = playlist && playlist[position - 1];
  let next_playlist = playlist && playlist[position + 1];

  if (prev_click_playlist === true) {
    if (position > 0) {
      previous_playlist = playlist && playlist[position - 1];
    } else {
      previous_playlist = playlist && playlist[0];
    }
  }
  if (next_click_playlist === true) {
    if (position < playlist?.length - 1) {
      next_playlist = playlist && playlist[position + 1];
    } else {
      next_playlist = playlist && playlist[playlist?.length - 1];
    }
  }

  const previous_slug = previous_playlist && previous_playlist?.data?.slug;
  const next_slug = next_playlist && next_playlist?.data?.slug;

  const handlePrevChange = () => {
    setPrevPlayList(true);
  };

  const handleNextChange = () => {
    setNextPlayList(true);
  };

  return (
    <div className="control-button" style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div>
        <Link
          to={chapter?.type !== 'recording' ?
            '/matieres/' +
            subjectSlug +
            '/chapitres/' +
            chapterSlug +
            '/cours/' +
            previous_slug +
            '/contenu' :
              '/matieres/' +
              subjectSlug +
              '/chapitres/' +
              chapterSlug +
              '/enregistrements/' +
              previous_slug +
              '/contenu'
          }
          key={previous_playlist && previous_playlist.id}
        >
          <Fab
            size="small"
            className="fabStyle"
            onClick={handlePrevChange}
            disabled={position === 0 ? true : false}
          >
            <SkipPreviousRoundedIcon className="iconStyle" />
          </Fab>
        </Link>
        <Link
          to={chapter?.type !== 'recording' ?
            '/matieres/' +
            subjectSlug +
            '/chapitres/' +
            chapterSlug +
            '/cours/' +
            next_slug +
            '/contenu' :
              '/matieres/' +
              subjectSlug +
              '/chapitres/' +
              chapterSlug +
              '/enregistrements/' +
              next_slug +
              '/contenu'
          }
          key={next_playlist && next_playlist.id}
        >
          <Fab
            size="small"
            className="fabStyle"
            onClick={handleNextChange}
            disabled={position === (playlist && playlist.length - 1) ? true : false}
          >
            <SkipNextRoundedIcon className="iconStyle" />
          </Fab>
        </Link>
      </div>
    </div>
  );
};

Index.propTypes = {
  data: PropTypes.object,
  lessons: PropTypes.object,
  subjectSlug: PropTypes.any,
  chapterSlug: PropTypes.any,
  lessonSlug: PropTypes.any,
};
export default Index;
