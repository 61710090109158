import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from './layouts/DashboardLayout';
// import MainLayout from './layouts/MainLayout';
import LoadingScreen from './components/LoadingScreen';
import AuthGuard from './components/AuthGuard';
import GuestGuard from './components/GuestGuard';
import SwitchGuard from './components/SwitchGuard';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? renderRoutes(route.routes) : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: () => <Redirect to="/" />,
  },

  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('./pages/Auth/LoginView')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/create/reset-password',
    component: lazy(() => import('./pages/Auth/ResetPassword/CreateResetCode')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/reset-password',
    component: lazy(() => import('./pages/Auth/ResetPassword/ResetPassword')),
  },
  {
    exact: true,
    guard: SwitchGuard,
    path: '/switch',
    component: lazy(() => import('./pages/Auth/Switch')),
  },
  {
    path: '/',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      // {
      //   exact: true,
      //   path: '/',
      //   component: lazy(() => import('./pages/Home')),
      //   component: lazy(() => import('./pages/Test')),
      // },
      {
        exact: true,
        path: '/mon-profil',
        component: lazy(() => import('./pages/Profile')),
      },
      {
        exact: true,
        // path: '/matieres',
        path: '/',
        component: lazy(() => import('./pages/Subjects')),
      },
      {
        exact: true,
        path: '/matieres/:matiere_id/chapitres',
        component: lazy(() => import('./pages/Chapters')),
      },
      {
        exact: true,
        path: '/matieres/:matiere_id/chapitres/:chapitre_id/contenu',
        component: lazy(() => import('./pages/Contents')),
      },
      {
        exact: true,
        path: '/matieres/:subject_id/chapitres/:chapter_id/cours/:lesson_id/contenu',
        component: lazy(() => import('./pages/ContentsLikePupil')),
      },
      {
        exact: true,
        path: '/matieres/:subject_id/chapitres/:chapter_id/enregistrements/:lesson_id/contenu',
        component: lazy(() => import('./pages/Recordings')),
      },
      {
        exact: true,
        path: '/videos',
        component: lazy(() => import('./pages/Videos')),
      },
      {
        exact: true,
        path: '/magazines',
        component: lazy(() => import('./pages/Magazines')),
      },
      {
        exact: true,
        path: '/qcm',
        component: lazy(() => import('./pages/Qcm')),
      },
      {
        exact: true,
        path: '/videos-signales',
        component: lazy(() => import('./pages/ReportedVideos')),
      },
      {
        exact: true,
        path: '/seances-en-direct',
        component: lazy(() => import('./pages/Events')),
      },
      {
        exact: true,
        path: '/taches',
        component: lazy(() => import('./pages/Tasks')),
      },
      {
        exact: true,
        path: '/examens',
        component: lazy(() => import('./pages/Exams')),
      },
      {
        exact: true,
        path: '/examens/:id',
        component: lazy(() => import('./pages/Exams/ExamDetails')),
      },
      {
        exact: true,
        path: '/suggestions',
        component: lazy(() => import('./pages/Suggestions')),
      },
      {
        exact: true,
        path: '/mes-suggestions',
        component: lazy(() => import('./pages/MySuggestions')),
      },
      {
        exact: true,
        path: '/code-coupon',
        component: lazy(() => import('./pages/CodeCoupon')),
      },
      {
        exact: true,
        path: '/revenu',
        component: lazy(() => import('./pages/Incomes')),
      },
      // {
      //   exact: true,
      //   path: '/test2',
      //   component: lazy(() => import('./pages/Auth/Switch')),
      // },
      {
        exact: true,
        path: '/test',
        component: lazy(() => import('./pages/Test')),
      },
    ],
  },
];

export default routes;
