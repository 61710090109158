import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Slide from '@material-ui/core/Slide';
import axios from '../utilities/axios';
import { enqueueSnackbar } from './notifier';
import { closeModal } from './modals';
import { updateContent } from './contents';
import { toggleLoadingTrue, toggleLoadingFalse } from './deleteLoading';

const initialState = {
  qcm: [],
  meta: {},
  status: 'idle',
  error: null,
};

export const fetchQcm = createAsyncThunk('qcm/fetchQcm', async ({ page, instructorId, search }) => {
  let data;
  try {
    const response = await axios.get(
      `/instructor/content-qcms?page=${page}&search=instructor.id:${instructorId};name:${search}&searchJoin=and&orderBy=id&sortedBy=desc`
    );
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const addNewQcm = createAsyncThunk('qcm/addNewQcm', async (query, thunkAPI) => {
  const { values } = query;
  let data;
  try {
    const response = await axios.post(`/instructor/content-qcm`, values);
    data = await response.data;
    if (response.status === 200) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: data.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      thunkAPI.dispatch(closeModal('add-qcm-modal'));
      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const deleteQcm = createAsyncThunk('qcm/deleteQcm', async (query, thunkAPI) => {
  const { qcmId } = query;
  let data;
  try {
    thunkAPI.dispatch(toggleLoadingTrue());
    const response = await axios.delete(`/instructor/content-qcm/${qcmId}`);
    data = await response.data;
    if ((response.status = 200)) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: data.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      thunkAPI.dispatch(closeModal('delete-modal'));
      thunkAPI.dispatch(toggleLoadingFalse());
       window.location = '/qcm';
      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    thunkAPI.dispatch(toggleLoadingFalse());
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const editQcm = createAsyncThunk('qcm/editQcm', async (query, thunkAPI) => {
  const { values, qcmId, chapterSlug, external } = query;
  let data;
  try {
    const response = await axios.post(`/instructor/content-qcm/${qcmId}`, values);
    data = await response.data;
    if (response.status === 200) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: data.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      thunkAPI.dispatch(closeModal('add-qcm-modal'));
      if (external) {
        thunkAPI.dispatch(
          updateContent('qcm', { ...data.payload, content: data.payload }, chapterSlug)
        );
      }
      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

// const getTab = (video) => {
//   if (video.content.chapter_contents.length === 0) {
//     return null;
//   }
//   // if (video.content.chapter_contents[0].tab === null) {
//   //   return '1';
//   // }
//   return video.content.chapter_contents[0].tab;
// };

export const getQcmTab = (chapters, chapterSlug) => {
  if (chapters == null) {
    return null;
  }
  if (chapters.length <= 0) {
    return null;
  }
  const selectedChapter = chapters.find((item) => item.chapter.slug === chapterSlug);
  if (selectedChapter == null) {
    return null;
  }
  if (selectedChapter.tab != null) {
    return selectedChapter.tab;
  }
  if (selectedChapter.section != null) {
    return selectedChapter.section.tab;
  }
  return null;
};

export const getQcmSection = (chapters, chapterSlug) => {
  if (chapters == null) {
    return null;
  }
  if (chapters.length <= 0) {
    return null;
  }
  const selectedChapter = chapters.find((item) => item.chapter.slug === chapterSlug);
  if (selectedChapter == null) {
    return null;
  }
  if (selectedChapter.section != null) {
    return selectedChapter.section;
  }
  return null;
};

export const getQcmInformations = (data) => {
  //   if (data.content_videos.length > 0) {
  //     return data.content_videos;
  //   }
  return [
    {
      id: data.id,
      video_id: data.video_id,
      url: data.url,
      duration: data.duration,
      created_at: data.created_at,
    },
  ];
};

export const formatQcm = (data, chapterSlug) => {
  const selectedChapter = data.chapter_contents.find(
    (chapter) => chapter?.chapter?.slug === chapterSlug
  );
  const newData = {
    id: data.id,
    qcm_id: data.content_qcm_id,
    slug: data.slug,
    created_at: data.created_at,
    title: data.name,
    description: data.description,
    is_active: data.active,
    is_draft: data.is_draft,
    position: chapterSlug != null ? selectedChapter.position : data.position,
    type: data.type,
    tab: getQcmTab(data.chapter_contents, chapterSlug),
    section: getQcmSection(data.chapter_contents, chapterSlug),
    teacher: data.instructor,
    chapters: data.chapter_contents,
    levels: data.content_student_levels,
    scope: data.scope,
    video: getQcmInformations(data),
    attachments: data.content_files,
  };
  return newData;
};

const slice = createSlice({
  name: 'qcm',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchQcm.pending]: (state, action) => {
      state.status = 'loading';
    },
    [fetchQcm.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      const newQcm = action.payload.payload.map((qcm) => {
        return formatQcm(qcm);
      });
      state.qcm = newQcm;
      state.meta = action.payload.meta;
    },
    [fetchQcm.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    [addNewQcm.pending]: (state, action) => {
      state.status = 'loading';
    },
    [addNewQcm.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      const data = action.payload;
      state.qcm.push(formatQcm(data));
    },
    [addNewQcm.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    [editQcm.pending]: (state, action) => {
      state.status = 'loading';},
    [editQcm.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      if (action.meta.arg.external) {
        // console.log(action.meta.arg.external);
        return;
      }
      const data = action.payload;
      const index = state.qcm.findIndex((item) => item.id === action.payload.id);
      state.qcm[index] = formatQcm(data);
      state.status = 'succeeded'
    },
    [editQcm.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    [deleteQcm.fulfilled]: (state, action) => {
      const data = action.payload;   
    },
    [deleteQcm.pending]: (state, action) => {
      state.status = 'loading';  
    },
    [deleteQcm.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export default slice;
