import React from 'react';
import {NavLink} from "react-router-dom";
import LockRoundedIcon from "@material-ui/icons/LockRounded";
import PlayCircleOutlineRoundedIcon from "@material-ui/icons/PlayCircleOutlineRounded";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import {sliceLongStrings} from "../../../utilities/methods";
import getLessonDuration from "../../../utilities/getLessonDuration";

const Index = (props) => {
    const { lesson, type, subjectSlug, chapterSlug, lessonSlug, lessonKey} = props;
  return (
    <>
      <NavLink
          to={type!=='recording' ?
              `/matieres/${subjectSlug}/chapitres/${chapterSlug}/cours/${lesson?.data?.slug}/contenu`:
              `/matieres/${subjectSlug}/chapitres/${chapterSlug}/enregistrements/${lesson?.data?.slug}/contenu`
          }
          key={lesson.id}
          id={'lesson-' + lesson.id}
      >
          <div key={`item-${lessonKey}`} className="liStyle">
                      <span className="li-icon">
                        {lesson.lockedVideo ? (
                            <LockRoundedIcon className="iconStyle" />
                        ) : (
                            <>
                                {lesson?.data?.slug === lessonSlug && (
                                    <PlayCircleOutlineRoundedIcon className="play" />
                                )}
                                {lesson?.data?.slug !== lessonSlug && <LockOpenIcon className="iconStyle" />}
                            </>
                        )}
                      </span>
              <div
                  className="li-texts"
                  style={
                      lesson?.data?.slug !== lessonSlug
                          ? { color: '#ffffff' }
                          : { color: '#2ba7df', fontWeight: 'bold' }
                  }
              >
                  <span title={lesson.name}>{sliceLongStrings(lesson.name, 56)}</span>
                  <span>
                          {lesson?.data?.type.id == '1' ||
                          lesson?.data?.type.id == '2' ||
                          lesson?.data?.type.id == '6'
                              ? lesson.data?.content_videos
                                  ? getLessonDuration(lesson?.data?.content_videos[0].duration)
                                  : 0
                              : null}
                        </span>
              </div>
          </div>
      </NavLink>
    </>
  );
};

export default Index;
